import React from 'react';
import { SkeletonGap, SkeletonLine } from '../../components/ui/skeleton';

export default function BookingLoading() {
    return(
        <div className='stat booking'>
            <div className='logo'>
                <SkeletonLine width={"55px"} height={'55px'}/>
                <div className='stat_info'>
                    <span className='points'><SkeletonLine width={"80px"} height={'30px'}/></span>
                    <SkeletonGap width={"80px"} height={'5px'}/>
                    <span className='date'><SkeletonLine width={"150px"} height={'20px'}/> </span>
                </div>
            </div>
            <div className='stat_results'>
                <span className='new_points'><SkeletonLine width={"80px"} height={'30px'}/></span>
                <div className='new_info'>
                    <SkeletonLine width={"100px"} height={'20px'} />
                </div>
            </div>
        </div>
    )
}