import React from 'react';
import PageLayout from '../components/layout/pageLayout';
import FrequentTools from './components/frequentTools';
import ToolsAndResources from './components/toolsAndResources';
import '../styles/tools.scss';

const BannerImg = '/assets/banners/tools_banner.jpg';

function Tools() {
    return (
        <PageLayout windowTitle="Tools & Resources" title="Tools & Resources" image={BannerImg} filter="0.2">
            <FrequentTools />
            <ToolsAndResources />
        </PageLayout>
    );
} export default Tools;