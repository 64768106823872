import React from 'react';
import ScreenSizeService from '../../services/screenSizeService';
import { Skeleton, SkeletonLine } from "../../components/ui/skeleton";

export default function SASBookingPopupLoading() {
    const mobile = new ScreenSizeService().isMobile();

    return (
        <div className="popup">
            <div className="popup_title">
                <Skeleton>
                    <Skeleton flex="column">
                        <SkeletonLine width={'100%'} height={'40px'} />
                    </Skeleton>
                </Skeleton>
            </div>
            <div className="popup_body">
                <Skeleton flex="column">
                    <SkeletonLine width={'100%'} height={'50px'} />
                    <SkeletonLine width={'85%'} height={'50px'} />
                    <SkeletonLine width={'100%'} height={'50px'} />
                    <SkeletonLine width={'90%'} height={'50px'} />
                    <SkeletonLine width={'50%'} height={'10px'} />
                    <Skeleton flex="row">
                    <SkeletonLine width={'25%'} height={'50px'} />
                    <SkeletonLine width={'25%'} height={'50px'} />
                    </Skeleton>
                </Skeleton>
            </div>
        </div>
    )
}