import React from 'react';
import ScreenSizeService from '../../services/screenSizeService';
import { Skeleton, SkeletonCircle, SkeletonGap, SkeletonLine } from "../../components/ui/skeleton";

export default function DashboardBenefitsLoading() {
    const mobile = new ScreenSizeService().isMobile();

    return (
        <div className="card_content">
            <div className="header">
                <Skeleton>
                    <Skeleton flex="column">
                        <SkeletonLine width={'200px'} height={'40px'} />
                    </Skeleton>
                </Skeleton>
            </div>
            <div className="body">
                <Skeleton flex="column">
                    <SkeletonLine width={'100px'} height={'20px'} />
                    <SkeletonLine width={'280px'} height={'20px'} />
                    <SkeletonLine width={'120px'} height={'20px'} />
                    <SkeletonLine width={'300px'} height={'20px'} />
                </Skeleton>
            </div>
        </div>
    )
}