import './../../styles/InactivityPopup.scss'
import { jwtDecode } from 'jwt-decode';
import { useMsal } from "@azure/msal-react";
import React, { useState } from 'react';
import AdfsLoginService from '../../services/AdfsLoginService';

const InactivityPopup = ({ onClose }) => {
    const { instance } = useMsal();
    const [refreshing, setRefreshing] = useState(false);
    const [error, setError] = useState(false);

    const refreshSession = async () => {
        try {
            const adfsCookie = document.cookie.split('; ').find(row => row.startsWith('adfs_token='));
            const adfsToken = adfsCookie.split('=')[1];
            const expirationToken = jwtDecode(adfsToken);
            if ((expirationToken.exp * 1000) <= Date.now()) {
                const adfsLoginService = new AdfsLoginService();
                await adfsLoginService.create();
            }
            onClose(false);
        } catch {
            setError(true);
            setRefreshing(true);
        }
    }

    const logout = () => {
        const domain = window.location;
        const COOKIE_NAME = 'adfs_token'

        sessionStorage.clear();
        document.cookie = COOKIE_NAME + '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';

        instance.logoutRedirect({
            postLogoutRedirectUri: domain,
        });
    }

    return (
        <div className="popup-overlay">
            <div className="popup">
                <div className="popup_title">
                    <p className="title"> Inactivity Warning</p>
                </div>
                <div className="popup_body">
                    {error ? (
                        <p className="body">Error: It is not possible to refresh your session.
                            <br />
                            Please, log out in order to log back in.
                            <br /><br />
                            Thanks!
                        </p>
                    ) : (
                        <p className="body">Hi!
                            <br />
                            To keep your session secure, click on 'Refresh Session' to prevent your session from expiring.
                            <br /><br />
                            Thanks!
                        </p>
                    )}
                    <div className="popup-buttons">
                        <button onClick={refreshSession} disabled={refreshing}>Refresh Session</button>
                        <button onClick={logout}>Log out</button>
                    </div>
                </div>

            </div>
        </div>
    );
};

export default InactivityPopup;
