import React, { useEffect, useState } from "react";
import { TooltipHost, DirectionalHint } from '@fluentui/react';
import { formatedDate } from "../../utils";
import { PasswordService } from '../dashboardService';
import InfoIcon from "../../components/icons/info";
import PasswordLoading from "./passwordLoading";
import PasswordError from "./passwordError";

function Password() {
    const [password, setPassword] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        (async () => {
            const passwordService = new PasswordService();
            const data = await passwordService.get();
            if(data.status && (data.status === 404 || data.status === 500)) {
                setPassword(null); 
                setLoading(false); 
            } else {
                setPassword(data); 
                setLoading(false);
            }
        })();
    }, []);

    const porcentage = password ? (password.daysRemaining / password.daysLimit) * 100 : 0;
    const chartValue = `${100 - porcentage} ${porcentage}`;

    const tooltipContent = {
        onRenderContent: () => (
            <p className="password_info_tooltip">
                Due to our company security policy your password is inactived with every 3rd months. You can choose a new password before the expiration date.
            </p>
        )
    }

    return (
        <div className="dashboard_card">
            {!loading && password &&
                <div className="card_content">
                    <div className="title">
                        <div className="title_name">Password</div>
                    </div>
                    <div className="content">
                        <div className="password_chart">
                            <div className="chart">
                                <svg width="104px" height="104px" viewBox="0 0 40 40" className="donut">
                                    <circle className="donut-hole" cx="20" cy="20" r="15.91549430918954" fill="transparent"></circle>
                                    <circle className="donut-ring" cx="20" cy="20" r="15.91549430918954" fill="transparent" strokeWidth="6"></circle>
                                    <circle className="donut-segment" cx="20" cy="20" r="15.91549430918954" fill="transparent" 
                                        strokeWidth="6" strokeDasharray={chartValue} strokeDashoffset="25">
                                    </circle>
                                </svg>
                                <div className="days">
                                    <span className="days_left">{password.daysRemaining < 0 ? '0' : password.daysRemaining}</span>
                                    <span className="days_total">/{password.daysLimit}</span>
                                </div>
                            </div>
                            <span className="remaining">
                                <TooltipHost
                                    tooltipProps={tooltipContent}
                                    calloutProps={{isBeakVisible: false, gapSpace: 10}}
                                    className='fluent_tooltip_medium'
                                    directionalHint={DirectionalHint.bottomCenter}
                                >
                                    <div className="info_icon">
                                        <InfoIcon/>
                                    </div>
                                </TooltipHost>
                                Days Remaining
                            </span>
                        </div>
                        <div className="password_info">
                            <div className="date_item due">
                                <span className="date">{formatedDate(password.resetDate)}</span>
                                <span className="date_label">Reset date</span>
                            </div>
                            <div className="date_item last">
                                <span className="date">{formatedDate(password.lastChange)}</span>
                                <span className="date_label">Last change</span>
                            </div>
                            {password.enableResetLink &&                            
                                <a className="reset" href={password.resetLink} target="_blank" rel="noreferrer">
                                    Reset Password
                                </a>
                            }
                        </div>
                    </div>
                </div>
            }
            {!password && loading && <PasswordLoading />}
            {!password && !loading && <PasswordError />}
        </div>
    );
} export default Password;