import fetchData from '../components/hooks/getData';
import LoggerService from '../services/LoggerService';
import CachedService from '../services/CachedService';

function Service() {
    const logger = new LoggerService('DirectoryService');

    const get = async () => {
        logger.debug('Retrieving directory list...');
        const data = await fetchData(`/Directories/GetSharepointDirectories`, 'get', 'json');
        var directories = data && data.map(l => {
            l.owners = '' !== l.owners ? JSON.parse(l.owners).map(o => {
                return {
                    email: o.Email
                };
            }) : [];
            return l;
        });
        return directories;
    };

    return {
        get
    };
}

export default function DirectoryService(params) {
    return new CachedService({
        key: 'directory',
        service: new Service(params)
    });
};