import { adfsConfig } from '../auth/authConfig';
import AdfsTokenService from './AdfsTokenService';
import LoggerService from './LoggerService';

export default function AdfsLoginService() {

    const logger = new LoggerService('AdfsLoginService');

    // Helper function 
    const isAdfsEnabled = () => adfsConfig.authN.parameters.enabled === 'true'; 

    const that = {
        create: () => {
            return new Promise((resolve, reject) => {
                if (!isAdfsEnabled()) {
                    logger.info('ADFS is not enabled');
                    reject('ADFS is not enabled');
                    return;
                }

                // Clear session storage
                sessionStorage.clear();

                // Clear specified cookie by setting its expiration date to past
                const COOKIE_NAME = 'adfs_token'
                document.cookie = COOKIE_NAME + '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';

                // Log the session and cookie clearance
                logger.info('Session storage and cookies have been cleared');

                // Redirect to ADFS login page 
                const adfsUrl = adfsConfig.authN.url;
                const adfsQuery = Object.keys(adfsConfig.authN.parameters)
                    .map((key) => `${key}=${adfsConfig.authN.parameters[key]}`)
                    .join('&');

                logger.info('Redirecting to ADFS login page');
                window.location.replace(`${adfsUrl}?${adfsQuery}`);

                // We cannot really resolve or reject until we return 
            });
        },

        get: () => {
            const adfsIsEnabled = adfsConfig.authN.parameters.enabled === 'true';

            if(!adfsIsEnabled) {
                logger.info('ADFS is not enabled');
                return 'Not enabled';
            }

            // Check if there is a ADFS JWT token stored in session
            const adfsTokenService = new AdfsTokenService();
            const adfsJwtToken = adfsTokenService.get();

            return adfsJwtToken;
        },
    };

    return that;
}