import React, { useState, useEffect } from 'react';
import { NavLink, useNavigate } from "react-router-dom";
import ScreenSizeService from '../../services/screenSizeService';
import ChevronRightIcon from '../icons/chevronRight';
import FeedbackIcon from '../icons/feedback';
import HelpIcon from '../icons/help';

function NavMenu(props) {
    const { style, setStyles, departments, tools } = props;
    const permissions = sessionStorage.getItem('permissions');
    const [clickedIndex, setClickedIndex] = useState({});
    const mobile = new ScreenSizeService().isMobile();
    const navigate = useNavigate();

    const handleClick = (index) => () => {
        clickedIndex === (state => ({
            ...state,
            [index]: !state[index]
        })) ? setClickedIndex({}) : setClickedIndex(state => ({
            ...state,
            [index]: !state[index]
        }))
        let menuItems = document.getElementsByClassName('nav__item');
        for(var i = 0; i < menuItems.length; i++){
            if(i !== index){
                menuItems[i].classList.remove('open');
            }
        }
    };

    function handleOutsideClick(e) {
        const index0 = document.getElementById("0");
        const index1 = document.getElementById("1");
        const index2 = document.getElementById("2");
        const index3 = document.getElementById("3");
        const mobileMenu = document.getElementById("nav_menu");
        const mobileMenuButton = document.getElementById("openMenu");
        const mobileTools = document.getElementById("mobile_tools");
        let target = e.target;     
        do {
            if(target === index0 || target === index1 || target === index2 || target === index3 || target === mobileMenu || target === mobileMenuButton || target === mobileTools) {
                return;
            }
            target = target.parentNode;
        } while (target);
        setClickedIndex({});
        setStyles('');
    }

    const helpNavigation = () => {
        navigate('/help-guides');
        setStyles('');
    };

    useEffect(() => {
        document.addEventListener("mousedown", (e) => {
            handleOutsideClick(e);
            return (document.removeEventListener("mousedown", handleOutsideClick));
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    
    const menuItems = [
        {ref: 'home', title: 'Home', link: '/'},
        {ref: 'hotels', title: 'Hotels', fitcolumn: true, submenuItems: [
            { title: 'Hotel Outlets and Services', link: 'https://rezportal.rezidor.com/cs/Satellite?c=Page&cid=1307436224468&pagename=rezPortal%2FPage%2FrezRender'},
            { title: 'hotelkit', className: 'lowercase', link: 'https://rhg-global.hotelkit.net/'},
            { title: 'Repository', link: 'https://rezportal.rezidor.com/cs/Satellite?c=Page&cid=1150468121414&pagename=rezPortal%2FPage%2FrezRender'},
        ]},
        {ref: 'departments', title: 'Departments', fitcolumn: true, submenuItems: departments },
        {ref: permissions === 'corporate' ? 'documents' : 'people', title: permissions === 'corporate' ? 'Documents' : 'People', link: '/documents'},
        {ref: 'tools', title: 'Tools & Resources', subLink: '/tools-and-resources', fitcolumn: true, submenuItems: tools },
        {ref: 'request', title: 'My Radisson Request', link: '/radisson-request'}
    ];

    return(
        <nav className={`nav ${style}`}>
            <ul className='nav__menu' id="nav_menu">
                {menuItems.map((item, index) => {
                    return(
                        <li className={`${clickedIndex[index] ? 'nav__item open' : 'nav__item'} ${item.ref}`} onClick={mobile ? handleClick(index) : null} key={index} id={index}>
                            {item.link && 
                                <NavLink to={item.link} exact="true" className="nav__link">{item.title}</NavLink>
                            }{item.submenuItems &&
                                <>
                                    {item.subLink ?
                                        <div className="nav__title">
                                            <NavLink to={item.subLink} exact="true" className="nav__link">
                                                {item.title}
                                            </NavLink>
                                            <div className={`nav__submenu ${item.fitcolumn && 'fitcolum'} ${item.ref}`}>
                                                {item && item.submenuItems && item.submenuItems.map && item.submenuItems.map((subitem, j) => (
                                                    <a className="subitem" key={j} href={subitem.link ? subitem.link : subitem.url} target="_blank" rel="noreferrer">
                                                        <span className={`subitem_title ${subitem.className && subitem.className}`}>
                                                            {subitem.title ? subitem.title : subitem.name}
                                                        </span> 
                                                        <ChevronRightIcon />
                                                    </a>
                                                ))}
                                            </div>
                                        </div>
                                        :
                                        <div className="nav__title">
                                            <span className="nav__link">{item.title}</span>
                                            <div className={`nav__submenu ${item.fitcolumn ? 'fitcolum' : ''} ${item.ref}`}>
                                                {item && item.submenuItems && item.submenuItems.map && item.submenuItems.map((subitem, j) => {
                                                    return(
                                                        <div key={j}>
                                                            {(subitem.link) ?
                                                                <a className="subitem" href={subitem.link ? subitem.link : '/'} target="_blank" rel="noreferrer">
                                                                    <span className={`subitem_title ${subitem.className && subitem.className}`}>
                                                                        {subitem.title}
                                                                    </span> 
                                                                    <ChevronRightIcon />
                                                                </a>
                                                                :
                                                                <div className="subitem">
                                                                    <span className='subitem_title'>{subitem.name}</span> 
                                                                    {((subitem.linkEMEA || subitem.linkAPAC) && !subitem.linkGlobal) ?
                                                                        <div className={`subitem__submenu ${(!subitem.linkAPAC || !subitem.linkEMEA) ? 'short' : ''}`}>
                                                                            {subitem.linkEMEA &&
                                                                                <a href={subitem.linkEMEA} target="_blank" rel="noreferrer">EMEA</a>
                                                                            }
                                                                            {subitem.linkAPAC && subitem.linkEMEA && <span>|</span>}
                                                                            {subitem.linkAPAC &&
                                                                                <a href={subitem.linkAPAC} target="_blank" rel="noreferrer">APAC</a>
                                                                            }
                                                                        </div>
                                                                        :
                                                                        <div className='subitem__submenu short'>
                                                                            <a href={subitem.linkGlobal} target="_blank" rel="noreferrer">GLOBAL</a>
                                                                        </div>
                                                                    }
                                                                </div>
                                                            }
                                                        </div>
                                                    )
                                                })}
                                            </div>
                                        </div>
                                    }
                                </>
                            }
                        </li>
                    )
                })}
            </ul>
            <div className='mobile_tools' id="mobile_tools">
                <div className='tool chat_tool'>
                    <FeedbackIcon /><span>Send Feedback</span>
                </div>
                <div className='tool help_tool' onClick={() => helpNavigation()}>
                    <HelpIcon /><span>Help Guide</span>
                </div>
            </div>
        </nav>
    );
}

export default NavMenu;