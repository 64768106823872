import React, { useEffect, useState, useMemo } from 'react';
import ScreenSizeService from '../../services/screenSizeService';
import MosaicIcon from '../../components/icons/mosaic';
import ExpandIcon from '../../components/icons/expand';
import Filters from './filters';
import Export from './export';
import PeopleMediumCard from './peopleMediumCard';
import PeopleLongCard from './peopleLongCard';
import PeopleShortCard from './peopleShortCard';
import Pagination from '../../components/ui/pagination';

function People(props) {
    const { data, totalResults, query } = props;
    const [listStyle, setListStyle] = useState('mosaic');
    const [filteredData, setFilteredData] = useState(data);
    const [filters, setFilters] = useState(false);
    const mobile = new ScreenSizeService().isMobile();
    const tablet = new ScreenSizeService().isTablet();

    function changeMosaic() { setListStyle('mosaic') }
    function changeList() { setListStyle('list') }

    useEffect(() => {
        setFilteredData(data);
    }, [data])

    let PageSizeUsers = 6;
    const [currentPageUsers, setCurrentPageUsers] = useState(1);

    const currentResultsUsers = useMemo(() => {
        const firstPageIndexUsers = (currentPageUsers - 1) * PageSizeUsers;
        const lastPageIndexUsers = firstPageIndexUsers + PageSizeUsers;
        return filteredData.slice(firstPageIndexUsers, lastPageIndexUsers);
    }, [currentPageUsers, filteredData, PageSizeUsers]);

    const ShortResults = () => {
        return (
            <>
                {currentResultsUsers.map((item, i) => {
                    return (
                        <PeopleShortCard item={item} key={i} />
                    )
                })}
            </>
        )
    };

    const MediumResults = () => {
        return (
            <>
                {currentResultsUsers.map((item, i) => {
                    return (
                        <PeopleMediumCard item={item} key={i} />
                    )
                })}
            </>
        )
    }

    const LongResults = () => {
        return (
            <>
                {currentResultsUsers.map((item, i) => {
                    return (
                        <PeopleLongCard item={item} key={i} />
                    )
                })}
            </>
        )
    }

    useEffect(() => {
        var search_area = document.getElementById('search_area');

        if(search_area){
            search_area.scrollIntoView()
        }

    }, [currentPageUsers]);

    return (
        <div className='users'>
            <div className="styles_toolbar">
                <div className='wrapper'>
                    <div className={`icon mosaic ${listStyle === 'mosaic' ? 'active' : ''}`} onClick={() => changeMosaic()}><MosaicIcon /></div>
                    <div className={`icon list ${listStyle === 'list' ? 'active' : ''}`} onClick={() => changeList()}><ExpandIcon /></div>
                </div>
            </div>
            <div className='filters_wrapper'>
                <Filters results={data} filteredData={filteredData} setFilterdData={setFilteredData} setUserFilters={setFilters} setPage={setCurrentPageUsers}/>
            </div>
            <div className='export_toolbar'>
                <div className='total_results'>
                    {filters === true ?
                        <span>{currentPageUsers > 1 && `Page ${currentPageUsers} of `}{filteredData.length} {filteredData.length > 1 ? 'results' : 'result'}</span>
                        :
                        <span>{currentPageUsers > 1 && `Page ${currentPageUsers} of `}{totalResults} {totalResults > 1 ? 'results' : 'result'}</span>
                    }
                </div>
                {!mobile && !tablet &&
                    <Export filteredData={filteredData} query={query}/>
                }
            </div>
            <div className={`users_results ${listStyle} desktop`}>
                {listStyle === 'mosaic' ? <ShortResults /> : <LongResults />}
            </div>
            <div className={`users_results ${listStyle} laptop`}>
                {listStyle === 'mosaic' ? <ShortResults /> : <MediumResults />}
            </div>
            <Pagination
                currentPage={currentPageUsers}
                totalCount={filteredData.length}
                pageSize={PageSizeUsers}
                moreResults={false}
                onPageChange={page => {
                    setCurrentPageUsers(page);
                }}
            />
        </div>
    )

} export default People;