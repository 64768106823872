import React from "react";
import TopGuideItem from "./topGuideItem";
import LoadingTopGuides from "./loadingTopGuides";

export default function TopGuides(props) {
    const { guides, loading } = props;

    return (
        <div className='guides_area'>
            <div className='guides'>
                <div className='area_title'>
                    <span className='title'>Highlighted Resources</span>
                    <span className='sub_title'>
                        Quick access to your help guides.
                    </span>
                </div>
                {loading ? <LoadingTopGuides /> : 
                    <div className='guides_documents'>
                        {guides && guides.filter((g => {return g.showTopGuides === true})).map((guide, index) => {
                            return (
                                <TopGuideItem guide={guide} key={index} />
                            )
                        })}
                    </div>
                }
            </div>
        </div>
    )
}