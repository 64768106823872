export default async function fetchData(url, method, type, token) {
    const user_token_api = sessionStorage.getItem('accessTokenAPI') || token;

    try {
        const res = await fetch(url, {
            method: method,
            headers: new Headers({
                'Authorization': 'Bearer ' + user_token_api
            })
        });
        return await (
            res.ok && type === 'json' ? res.json() : res.ok && type === 'text' ? res.text() : res.status === 401 ? window.location.href = '/' : res
        );
    } catch (error) {
        return await error;
    }
};