import React from "react";
import PlayButton from "../../components/icons/play";

export default function VideoFrame(props) {
    const { video } = props;

    function triggerEvent( elem, event ) {
        var clickEvent = new Event( event );
        elem.dispatchEvent( clickEvent );
    }

    const onFrameClick = (e) => {
        let framevideo = e.target.closest(".video_frame");
        let overlay = framevideo.querySelector(".overlay");
        let title = framevideo.querySelector(".video_title");
        let playButton = framevideo.querySelector(".play_button");
        let iframe = framevideo.querySelector(".frame");
        framevideo.removeChild(overlay);
        framevideo.removeChild(title);
        framevideo.removeChild(playButton);
        iframe.setAttribute("autoplay", "true");
        triggerEvent(iframe, 'click');
    }

    return (
        <div className="video_frame" onClick={(e) => onFrameClick(e)}>
            <div className="overlay"></div>
            <div className="video_title">{video.title}</div>
            <div className="play_button"><PlayButton /></div> 
            <video autoPlay={false} controls className="frame">
                <source src={video.iFrame} type="video/mp4" title={`${video.title}.mp4`} />
            </video>
        </div>
    )
}