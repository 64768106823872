import React, { useReducer, useEffect, useState } from 'react';
import { useMsal } from "@azure/msal-react";
import { loginRequestAPI } from "../../auth/authConfig";
import AppRoutes from '../../AppRoutes';
import fetchData from '../hooks/getData';
import Layout from './layout';
import Login from "./login";
import ErrorPage from './errorPage';
import LoadingPage from '../ui/LoadingPage';
import AdfsLoginService from '../../services/AdfsLoginService';
import LinksService from '../../links/linksService';
import MenuToolsAndResourcesService from '../../toolsAndResources/menuToolsService';

function HomePage(){
  const [accessToken, setAccessToken] = useState(sessionStorage.getItem('accessTokenAPI'));
  const [permissions, setPermissions] = useState(sessionStorage.getItem('permissions'));
  const [firstUrl, setFirstUrl] = useState(null);
  const [error, setError] = useState(false);
  const [user, setUser] = useState(null);
  const [departments, setDepartments] = useState(null);
  const [tools, setTools] = useState(null);
  const { instance, accounts } = useMsal();
  const tasks = []
  const [getUserInfo, setGetUserInfo] = useState(null);

  const tenantId = process.env.REACT_APP_TENANT_ID;
  const INITIAL_STATE = 0;
  const NULL_STATE = 1;
  const VALUE_STATE = 2;
  const [adfsToken, setAdfsToken] = useReducer((state, action) => {
    switch(action.type) {
      case 'set':
        if(null === action.value || '' === action.value) {
          return NULL_STATE;
        }
        else {
          return VALUE_STATE;
        }
      default: {
        return INITIAL_STATE;
      }
    }
  }, INITIAL_STATE);

  useEffect(() => {
    const adfsLoginService = new AdfsLoginService();
    const userToken = adfsLoginService.get();

    setAdfsToken({
      type: 'set',
      value: userToken
    });
  }, []);

  useEffect(() => {
    if(permissions === 'hotel'){
      setGetUserInfo('/UserInfo/hotel/GetUserInfo');
      setAccessToken(document.cookie.split('; ').find(row => row.startsWith('adfs_token')).split('=')[1]);
    }
    else
      setGetUserInfo('/UserInfo/GetUserInfo');
  }, [permissions, accessToken]);

  useEffect(() => {
    if(NULL_STATE === adfsToken) {
      setFirstUrl(window.location.pathname);
      sessionStorage.setItem('firstUrl', window.location.pathname);
      const adfsLoginService = new AdfsLoginService();
      adfsLoginService.create();
    }
  }, [adfsToken]);

  useEffect(() => {
    if(permissions && accessToken){
      Promise.all([
        // Getting a saving User Info //
        fetchData(getUserInfo, 'get', 'json', accessToken).then(data => {
          setUser(data);
          sessionStorage.setItem('userData', JSON.stringify(data));
        }),

        // Getting and saving Links or Departments //
        (async () => {
          const linksService = new LinksService({
            permissions: permissions,
            token: accessToken
          });
          const links = await linksService.get();
          if(links){ setDepartments(links); }
          else{ setError(true); }
        })(),

        // Getting and saving Tools And Resources //
        (async () => {
          const menuToolsAndResourcesService = new MenuToolsAndResourcesService();
          const tools = await menuToolsAndResourcesService.get();
          if(tools){ setTools(tools); }
          else{ setError(true); }
        })()
      ]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [permissions, accessToken, getUserInfo]);

  // Refreshing Token when expired //
  useEffect(() => {
    const fetchData = async () => {
      try {
        if(permissions === 'corporate'){
          const ssoRequest = {
            domainHint: tenantId,
            scopes: loginRequestAPI.scopes,
            account: accounts.length > 0 ? accounts[0] : {}
          };
          const response = await instance.acquireTokenSilent(ssoRequest);
          setAccessToken(response.accessToken);
          sessionStorage.setItem('accessTokenAPI', response.accessToken);
          const timeout = response.expiresOn.getTime() - Date.now() - 60000;
          setTimeout(fetchData, timeout);
        }
      } catch (error) {
        console.log(error);
      }
    };
    if(accessToken && permissions === 'corporate'){
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [instance, accounts]);

  const allIsOk = accessToken && permissions && VALUE_STATE === adfsToken && !error;
  const fetchDataIsOk = departments && user && tools && tasks;

  const errorObj = {
    title: 'Something Went Worng while login into Azure AD',
    description: 'We are sorry, there was an error while login into Azure AD with your account.',
    message: 'Make sure you are login into Azure AD with the right account. If the error persist, please contact the IT department, and try again later.'
  }

  return (
    <>
      { allIsOk &&
        <>
          { fetchDataIsOk &&
            <Layout userData={user} departments={departments} tools={tools} tasks={tasks} firstUrl={firstUrl}>
              <AppRoutes permissions={permissions} userData={user}/>
            </Layout>
          }
          { !fetchDataIsOk && <LoadingPage /> }
        </>
      }
      { !allIsOk && <Login setToken={setAccessToken} setPermissions={setPermissions} setError={setError}/> }
      { error && <ErrorPage error={errorObj} /> }
    </>
  );
} export default HomePage;
