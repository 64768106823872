import React, { useState, useEffect } from "react";
import PageLayout from '../components/layout/pageLayout';
import ConsentFormText from './components/formText';
import LoadingText from './components/formTextLoading';
import ConsentService from './consentFormService';
import '../styles/consent.scss';

const BannerImg = '/assets/banners/consent_banner.jpg';

export default function ConsentFormPage() {
    const [consentText, setConsentText] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        (async () => {
            const consentService = new ConsentService();
            const data = await consentService.get();
            if(data) {
                setLoading(false);
                setConsentText(data);
            }
        })();
    }, []);

    return(
        <PageLayout windowTitle="IT Consent Form" title="IT Consent Form" image={BannerImg} filter="0.2"
            breadcrumSubLink="/radisson-request" breadcrumSubItem="My Radisson Request">
            {consentText && !loading &&
                <ConsentFormText data={consentText} />
            } 
            {!consentText && loading &&
                <LoadingText />
            }
        </PageLayout>
    );
}