import React from "react";
import { SkeletonLine } from '../../components/ui/skeleton';

export default function TopStoriesLoading() {
    return(
        <div className="carousel">
            <div className="main_title">
                <SkeletonLine width='200px' height='20px'/>
            </div>
            <div className="slides">
                <ul className="inner" id="carousel">
                    <li className="slide">
                        <div className="image">
                            <img src="" alt="Loading..." draggable="false"/>
                        </div>
                        <div className="content">
                            <h2 className="title_content"><SkeletonLine width='300px' height='50px'/></h2>
                            <SkeletonLine width='160px' height='20px'/>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    )
}