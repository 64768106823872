import React from "react";
import { Skeleton, SkeletonLine, SkeletonGap } from "../../components/ui/skeleton";

export default function LoadingText() {
    return(
        <div className="consent_wrapper">
            <section className="consent_text">
                {Array(2).fill().map((_, i) => (
                    <Skeleton flex="column" key={i}>
                        <SkeletonLine width={"800px"} height={'20px'}/>
                        <SkeletonGap height={'10px'}/>
                        <SkeletonLine width={"800px"} height={'20px'}/>
                        <SkeletonGap height={'10px'}/>
                        <SkeletonLine width={"800px"} height={'20px'}/>
                        <SkeletonGap height={'10px'}/>
                        <SkeletonLine width={"600px"} height={'20px'}/>
                        <SkeletonGap height={'50px'}/>
                    </Skeleton>
                ))}
            </section>
        </div>
    )
}