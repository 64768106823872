import React, { useEffect, useState } from "react";
import { ProfileService } from '../dashboardService';
import DashboardProfileLoading from './profileLoading';
import DashboardProfileError from './profileError';

const avatar = '../../assets/img/avatar.png';

export default function DashboardProfile() {
    const [userData, setUserData] = useState(null);
    const [loading, setLoading] = useState(true);
    const permissions = sessionStorage.getItem('permissions');

    useEffect(() => {
        (async () => {
            const profileService = new ProfileService();
            const data = await profileService.get();
            if (data && !('status' in data)) {
                setUserData(data);
            }
            setLoading(false);
        })();
    }, []);

    return (
        <div className="dashboard_card">
            {userData && !loading &&
                <div className="card_content">
                    <div className="header">
                        <div className='profile_avatar'>
                            <img src={userData.image ? `data:image;base64,${userData.image}` : avatar} alt={userData.displayName}/>
                        </div>
                        <div className="user_name">
                            <span className='name'>{userData.displayName}</span>
                            <span className='email'>{userData.email}</span>
                        </div>
                    </div>
                    {permissions === 'corporate' &&
                        <div className="body">
                            {userData.team &&
                            <div className="profile_item">
                                <span className="title">Department</span>
                                <span className='value'>
                                    {userData.team}
                                </span>
                            </div>
                            }
                            {userData.manager &&
                            <div className="profile_item">
                                <span className="title">Manager</span>
                                <span className='value'>
                                    {userData.manager}
                                </span>
                            </div>
                            }
                        </div>
                    }
                </div>
            }
            {loading && !userData && <DashboardProfileLoading />}
            {!loading && !userData && <DashboardProfileError />}
        </div>
    );
};