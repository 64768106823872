import React, { useEffect, useState } from "react";
import { Icon } from '@fluentui/react/lib/Icon';
import { getFileTypeIconProps, FileIconType } from '@fluentui/react-file-type-icons';
import { getFileType } from '../../utils';
import fetchData from '../../components/hooks/getData';

function LastDocuments() {
    const [documents, setDocuments] = useState([]);

    useEffect(() => {
        let modifiedData;
        fetchData('/Documents/GetRecentFiles', 'get', 'json')
            .then(data => {
                if(data && data.map) {
                    modifiedData = data.map(doc => {
                        return {
                            name: doc.name.replace(/\.[^/.]+$/, ""),
                            webUrl: doc.webUrl,
                            extension: doc.name.split('.').pop(),
                            type: getFileType(doc.name.split('.').pop())
                        }
                    });
                    setDocuments(modifiedData);
                }
            })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    

    return (
        <div className="wrapper">
            <div className="block_title">
                <h2>My last documents</h2>
            </div>
            <div className="requests_list">
                <div className="list">
                    {documents.map((doc, i) => (
                        doc.webUrl != null && (
                            <div className="item" key={i}>
                                <a className="wrapper" href={doc.webUrl} target="_blank" rel="noreferrer">
                                    <div className="icon">
                                        <Icon {...getFileTypeIconProps({extension: doc.extension, type: FileIconType.docset, size: 32, imageFileType: 'svg' }) } />
                                    </div>
                                    <div className="text">
                                        <span className="departement">{doc.type}</span>
                                        <span className="title">{doc.name}</span>
                                    </div>
                                </a>
                            </div>
                        )
                    ))}
                </div>
            </div>
        </div>
    );
} export default LastDocuments;