import React from "react";
import { NavLink } from "react-router-dom";
import WarningIcon from "../icons/warning";
import '../../styles/error.scss';

function Custom404(props) {

    return (
        <main className='main_error'>
            <div className='error'>
                <div className="wrapper">
                    <div className='error_icon'><WarningIcon /></div>
                    <div className="title_wrapper">
                        <span className='title'>This page could not be found</span>
                        <span className='subtitle'>The page you are looking for does not exist, has been removed,<br/>
                            name has changed or is temporarily unavailable.
                        </span>
                    </div>
                    <div className='error_btn'>
                        <NavLink to={'/'} exact="true">Go Back</NavLink>
                    </div>
                </div>
            </div>
        </main>
    );
} export default Custom404;