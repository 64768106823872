import LoggerService from "./LoggerService";

export default function CachedService({ key, service }) {
    const logger = new LoggerService('CachedService');

    const CACHE_KEY = key;
    const CACHE_DATE_PROPERTY_NAME = 'cdt';
    const CACHE_ENTITY_PROPERTY_NAME = `c${key}`;

    const get = async () => {
        // Obtaining the value from session storage
        const cacheValue = sessionStorage.getItem(CACHE_KEY);

        // Parsing the value retrieved
        const cacheData = cacheValue !== null && cacheValue !== undefined 
            ? JSON.parse(cacheValue)
            : null;

        // Retrieving the date value from cache
        const cacheDateTime = cacheData !== null && Object.keys(cacheData).some(k => k === CACHE_DATE_PROPERTY_NAME)
            ? cacheData[CACHE_DATE_PROPERTY_NAME]
            : null;

        // Setting if cache expired
        const hasCacheExpired = cacheDateTime === null || new Date(cacheDateTime).getTime() <= Date.now();

        // Return the cache value if cache didn't expire
        if(!hasCacheExpired) {
            logger.debug(`Returning cache data for ${key}...`);
            return cacheData[CACHE_ENTITY_PROPERTY_NAME];
        }

        
        logger.debug(`Refreshing cache data for ${key}...`);

        // Get tools from API and then cache values
        const newTools = await service.get();

        // Setting cache time
        const newCacheDateTime = new Date(Date.now()).setMinutes(new Date(Date.now()).getMinutes() + 5);

        // Building cache entity
        const cacheToolsEntity = {};
        cacheToolsEntity[CACHE_DATE_PROPERTY_NAME] = newCacheDateTime;
        cacheToolsEntity[CACHE_ENTITY_PROPERTY_NAME] = newTools;

        // Saving cache entity in cache
        const newCacheValue = JSON.stringify(cacheToolsEntity);        
        sessionStorage.setItem(CACHE_KEY, newCacheValue);
        
        return newTools;
    };

    return {
        get
    };
}