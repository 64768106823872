import React from 'react';
import { SkeletonGap, SkeletonLine } from '../../components/ui/skeleton';

export default function BestEmployerLoading() {
    return(
        <div className='stat forbes'>
            <div className='header_title'>
                <SkeletonLine width={"250px"} height={'35px'}/>
                <SkeletonGap width={"10px"}/>
                <SkeletonLine width={"200px"} height={'20px'}/>
            </div>
            <div className='image'>
                <SkeletonLine width={"190px"} height={'70px'}/>
            </div>
        </div>
    )
}