export default function TopStoriesSlider(slider, setActiveIndex) {
    let isDown = false;
    let startX, scrollLeft;
    let itemWidth = slider && slider.children && slider.children.length > 0 ? slider.children[0].offsetWidth : 0;

    slider.addEventListener('scroll', _ => {
        let page = parseInt((slider.scrollLeft / itemWidth).toFixed(0));
        setActiveIndex(page);
    });
    slider.addEventListener('mousedown', e => {
        isDown = true;
        slider.classList.add('active');
        startX = e.pageX - slider.offsetLeft;
        scrollLeft = slider.scrollLeft;
    });
    slider.addEventListener('mouseleave', _ => {
        isDown = false;
        slider.classList.remove('active');
    });
    slider.addEventListener('mouseup', _ => {
        isDown = false;
        slider.classList.remove('active');
    });
    slider.addEventListener('mousemove', e => {
        if (!isDown) return;
        e.preventDefault();
        const x = e.pageX - slider.offsetLeft;
        const SCROLL_SPEED = 1;
        const walk = (x - startX) * SCROLL_SPEED;
        slider.scrollLeft = scrollLeft - walk;
    });
}