import React from "react";
import ToolsAndResourcesItem from "./toolsListItem";
import ToolsListLoading from "./toolsListLoading";

export default function ToolsAndResourcesList(props) {
    const { listStyle, filteredData, sort, loading } = props;

    return(
        <div className="tools_list_wrapper">
            <div className={`tools_list ${listStyle}`}>
                {!loading && filteredData && sort &&
                    <>
                        {filteredData.map((tool, index) => (
                            <ToolsAndResourcesItem tool={tool} listStyle={listStyle} key={index}/>
                        ))}
                    </>
                }
                {loading && <ToolsListLoading />}
            </div>
        </div>
    )
}