import React from 'react';
import WarningIcon from "../../components/icons/warning";

export default function DashboardBenefitsError() {
    return (
        <div className="card_content error">
            <div className="content">
                <div className="icon"><WarningIcon /></div>
                <span className="error_text">Benefits list is temporarily unavailable.</span>
            </div>
        </div>
    )
}