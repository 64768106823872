import React, { useState, useRef } from "react";
import SearchIcon from '../../components/icons/search';
import ChevronDownIcon from '../../components/icons/chevronDown';
import useClickOutsideHook from '../../components/hooks/clickOutsideHook';

export default function RadissonRequestSearch(props){
    const { sort, setSort, forms, setFilteredData } = props;
    const [sortStyle, setSortStyle] = useState('');
    const [sortType, setSortType] = useState('');
    const sortRef = useRef(null);
    const sortMenuRef = useRef(null);

    const handleSort = () => {
        sortStyle === '' ? setSortStyle('open') : setSortStyle('');
    }

    const getSort = (type, name) => {
        setSort(type);
        setSortType(name);
    }

    const handleInputChange = (e) => {
        let value = e.target.value;
        let filtered = forms.filter(form => {
            const nameMatches = form.titleRequest.toLowerCase().includes(value.toLowerCase());
            return nameMatches;
        });
        setFilteredData(filtered);
    }

    useClickOutsideHook(sortMenuRef, sortRef, () => setSortStyle(''));

    return(
        <div className="forms_toolbar">
            <div className="search">
                <div className='input_group'>
                    <label className='fluent_label'>
                        <span className='title'>Enter request's title here</span>
                    </label>
                    <input placeholder='Search for request forms' className='fluent_input' id="search_input" 
                        onChange={(e) => handleInputChange(e)} autoComplete="false"/>
                    <div className='input_icon'>
                        <div className='icon'>
                            <SearchIcon/>
                        </div>
                    </div>
                </div>
            </div>
            <div className="filters">
                <div className="filter_sort">
                    <div className={`filter_wrapper ${sortStyle}`}>
                        <div className={`filter ${sortStyle}`} onClick={() => handleSort()} ref={sortRef}>
                            <span className='label'>Order by {sortType}</span>
                            <div className='drop_down'><ChevronDownIcon /></div>
                        </div>
                        <div className='filter_menu' ref={sortMenuRef}>
                            <div className={`filter_item ${sort === 'asc' && 'active'}`} onClick={() => getSort('asc', 'A-Z')}>A-Z</div>
                            <div className={`filter_item ${sort === 'desc' && 'active'}`} onClick={() => getSort('desc', 'Z-A')}>Z-A</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}