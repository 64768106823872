import React from "react";
import "../../styles/skeleton.scss";

export function Skeleton(props) {
    const { children, flex, width, justify, align } = props;
    return(
        <div className="skeleton" style={{width: width ? width : '100%' ,flexDirection: flex, alignItems: align ? align : 'flex-start', justifyContent: justify ? justify : 'flex-start'}}>
            {children}
        </div>
    )
};

export function SkeletonLine(props) {
    const { width, height, transparent } = props;
    return (
        <div className="skeleton_line" style={{width: `${width}`, height: `${height}`, background: transparent ? 'rgba(255,255,255,0.8)' : 'rgb(238, 238, 238)'}}></div>
    );
};

export function SkeletonGap(props) {
    const { width, height } = props;
    return (
        <div className="skeleton_gap" style={{width: `${width}`, height: `${height}`}}></div>
    );
};

export function SkeletonCircle(props) {
    const { width, transparent } = props;
    return (
        <div className="skeleton_circle" style={{width: `${width}`, background: transparent ? 'rgba(255,255,255,0.8)' : 'rgb(238, 238, 238)'}}></div>
    );
}