import React, { useState, useRef } from 'react';
import { useNavigate } from "react-router-dom";
import { TooltipHost, DirectionalHint } from '@fluentui/react';
import { useId } from '@fluentui/react-hooks';
import ScreenSizeService from '../../services/screenSizeService'
import useClickOutsideHook from '../hooks/clickOutsideHook';
import SearchIcon from '../icons/search';

function NavigationSearch(){
    const [searchStyles, setSearchStyles] = useState('');
    const [query, setQuery] = useState('');
    const SearchRef = useRef(null);
    const SearchBarRef = useRef(null);
    const mobile = new ScreenSizeService().isMobile();
    const navigate = useNavigate();

    const openSearchBar = () => {
        if(!mobile){
            searchStyles === '' ? setSearchStyles('active') : setSearchStyles('');
        }else{
            navigate(`/documents`);
        }
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        if(query && query.length >= 3){
            setSearchStyles('');
            navigate(`/documents?query=${query}`);
            setQuery('');
            document.getElementById('search_input').value = '';
        }
    };

    function handleInputChange(e){
        let inputValue = e.target.value;
        setQuery(inputValue);
    }

    useClickOutsideHook(SearchRef, SearchBarRef, () => setSearchStyles(''));

    const tooltipSearch = useId('tooltipSearch');

    return (
        <>
            <li className={`tool_search ${searchStyles}`} ref={SearchRef} onClick={() => openSearchBar()}>
                <SearchIcon />
            </li>
            <div className={`search_bar ${searchStyles}`} ref={SearchBarRef}>
                <div className='search_wraper'>
                    <form autoComplete="off" onSubmit={handleSubmit}>
                        <div className='input_group'>
                            <input placeholder="Search for files, people and sites" className='fluent_input' type="text" id="search_input"
                                onChange={(e) => handleInputChange(e)} value={query}
                            />
                            <div className='input_icon' onClick={(e) => handleSubmit(e)}>
                                <TooltipHost
                                    content='Search'
                                    id={tooltipSearch}
                                    calloutProps={{isBeakVisible: false, gapSpace: 10}}
                                    className='fluent_tooltip'
                                    directionalHint={DirectionalHint.bottomCenter}
                                >
                                    <div className='icon'>
                                        <SearchIcon/>
                                    </div>
                                </TooltipHost>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </>
    )
}; export default NavigationSearch;