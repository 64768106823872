import React from "react";
import ScreenSizeService from "../../services/screenSizeService";
import { Skeleton, SkeletonLine } from "../../components/ui/skeleton";

export default function ToolsListLoading() {
    const mobile = new ScreenSizeService().isMobile();
    return(
        Array(10).fill().map((_, i) => (
            <div className='tool list' key={i}>
                <SkeletonLine width="128px" height="60px"/>
                <SkeletonLine width="400px" height="35px"/>
                {!mobile &&
                    <Skeleton flex="column">
                        <SkeletonLine width="700px" height="20px"/>
                        <SkeletonLine width="650px" height="20px"/>
                    </Skeleton>
                }
            </div>
        ))
    )
}