import React from 'react';
import ScreenSizeService from '../../services/screenSizeService';
import fetchData from '../../components/hooks/getData';

export default function ToolsAndResourcesItem(props) {
    const { tool, listStyle } = props;
    const mobile = new ScreenSizeService().isMobile();

    const handleToolClick = (toolId) => {
        fetchData(`/ToolsAndResources/PostToolClicked?id=${toolId}`, 'post', 'json')
    }

    function validateEmail(email) {
        var re = /\S+@\S+\.\S+/;
        return re.test(email);
    }

    return(
        <div className={`tool ${listStyle === 'mosaic' && tool.isImage ? 'image' : 'icon'}`}
            onClick={() => handleToolClick(tool.idTool)}>
            {tool.iconType === ".png" &&
                <div className={`tool_icon png ${tool.isImage === false ? 'small' : 'big'}`}>
                    <a href={tool.url} target="_blank" rel="noreferrer" draggable={false} >
                        <img src={tool.icon} alt={tool.name} className="image" draggable={false}/>
                    </a>
                </div>
            }{tool.iconType === ".svg" &&
                <a href={tool.url} target="_blank" rel="noreferrer" draggable={false} className={`tool_icon svg ${tool.isImage === false ? 'small' : 'big'}`}>
                    <div dangerouslySetInnerHTML={{__html: tool.icon}}></div>
                </a>
            }
            <div className="tool_info">
                <a href={tool.url} target="_blank" rel="noreferrer" draggable={false} >
                    <span className="tool_title">{tool.name}</span>
                </a>
            </div>
            {!mobile &&
                <div className="tool_description">
                    <span className="desc">{tool.description}</span>
                    {tool.requestLink && tool.requestText &&
                        <a href={`${validateEmail(tool.requestLink) === true ? `mailto:${tool.requestLink}` : tool.requestLink}`} target="_blank" rel="noreferrer" className='request_link'>
                            {tool.requestText}
                        </a>
                    }
                </div>
            }
        </div>
    )
}