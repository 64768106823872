import React, { useRef, useState } from "react";
import SearchIcon from '../../components/icons/search';
import ChevronDownIcon from '../../components/icons/chevronDown';
import MosaicIcon from '../../components/icons/mosaic';
import ListIcon from '../../components/icons/list';
import useClickOutsideHook from '../../components/hooks/clickOutsideHook';

export default function ToolsAndResourcesSearch(props) {
    const { sort, setSort, listStyle, setListStyle, setFilteredData, tools } = props;
    const [sortStyle, setSortStyle] = useState('');
    const [sortType, setSortType] = useState('');
    const sortRef = useRef(null);
    const sortMenuRef = useRef(null);

    const handleSort = () => {
        sortStyle === '' ? setSortStyle('open') : setSortStyle('');
    }

    const getSort = (type, name) => {
        setSort(type);
        setSortType(name);
        if(type === 'asc') {
            tools.sort((a, b) => a.name.localeCompare(b.name));
        } else {
            tools.sort((a, b) => b.name.localeCompare(a.name));
        }
    }

    const handleInputChange = (e) => {
        let value = e.target.value;
        let filtered = tools.filter(tool => {
            const nameMatches = tool.name.toLowerCase().includes(value.toLowerCase());
            return nameMatches;
        });
        setFilteredData(filtered);
    }

    function changeMosaic() { setListStyle('mosaic') }
    function changeList() { setListStyle('list') }

    useClickOutsideHook(sortMenuRef, sortRef, () => setSortStyle(''));

    return(
        <div className="resources_toolbar">
            <div className="search">
                <div className='input_group'>
                    <label className='fluent_label'>
                        <span className='title'>Enter tool's name here</span>
                    </label>
                    <input placeholder='Search for tools' className='fluent_input' id="search_input" onChange={(e) => handleInputChange(e)} autoComplete="false"/>
                    <div className='input_icon'>
                        <div className='icon'>
                            <SearchIcon/>
                        </div>
                    </div>
                </div>
            </div>
            <div className="filters">
                <div className="filter_sort">
                    <div className={`filter_wrapper ${sortStyle}`}>
                        <div className={`filter ${sortStyle}`} onClick={() => handleSort()} ref={sortRef}>
                            <span className='label'>Order by {sortType}</span>
                            <div className='drop_down'><ChevronDownIcon /></div>
                        </div>
                        <div className='filter_menu' ref={sortMenuRef}>
                            <div className={`filter_item ${sort === 'asc' && 'active'}`} onClick={() => getSort('asc', 'A-Z')}>A-Z</div>
                            <div className={`filter_item ${sort === 'desc' && 'active'}`} onClick={() => getSort('desc', 'Z-A')}>Z-A</div>
                        </div>
                    </div>
                </div>
                <div className="styles_toolbar">
                    <div className='wrapper'>
                        <div className={`icon mosaic ${listStyle === 'mosaic' ? 'active' : ''}`} onClick={() => changeMosaic()}><MosaicIcon /></div>
                        <div className={`icon list ${listStyle === 'list' ? 'active' : ''}`} onClick={() => changeList()}><ListIcon /></div>
                    </div>
                </div>
            </div>
        </div>
    )
}