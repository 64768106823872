import React, { useEffect, useState, forwardRef } from "react";
import { TooltipHost, DirectionalHint } from '@fluentui/react';
import InfoIcon from '../../components/icons/info';
import QuickLinksListItem from "./quickLinksListItem";
import QuickLinksLoading from "./quickLinksLoading";
import QuickToolsService from "../quickToolsService";

const QuickLinksList = forwardRef(function QuickLinks(props, ref) {
    const [tools, setTools] = useState(null);
    const [loading, setLoading] = useState(true);
    const quickToolsService = new QuickToolsService();

    useEffect(() => {
        (async () => {
            const data = await quickToolsService.get();
            setTools(data);
            setLoading(false);
        })();
    }, []);
    
    const titleTooltipContent = {
        onRenderContent: () => (
          <p className="quickaccess_tooltip"><b>Quick access to most used RHG tools and resources.</b><br/>
          Hover your cursor over each link to get a description of it.</p>
        )
    }

    return(
        <div className="section links" ref={ref}>
            <div className="section_title">
                <h2>Quick Access</h2>
                <TooltipHost
                    tooltipProps={titleTooltipContent}
                    calloutProps={{isBeakVisible: false, gapSpace: 10}}
                    className='fluent_tooltip_medium'
                    directionalHint={DirectionalHint.bottomCenter}
                >
                    <div className="info_icon">
                        <InfoIcon/>
                    </div>
                </TooltipHost>
            </div>
            {tools && tools.length > 0 &&
                <div className="cards">
                    {tools.map((link, index) => {
                        return(
                            <QuickLinksListItem link={link} key={index}/>
                        );
                    })}
                </div>
            }{loading && <QuickLinksLoading />}
        </div>
    )
});

export default QuickLinksList;