import React from "react";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { NavLink } from "react-router-dom";
import '../../styles/layout.scss';

function PageLayout(props){
    const {windowTitle, title, breadcrumbTitle, image, filter, children, breadcrumSubItem, breadcrumSubLink} = props;
    return (
        <HelmetProvider>
            <Helmet>
                <title>Radisson Connects | {windowTitle}</title>
            </Helmet>
            <div className='page_layout'>
                <div className='banner' style={{backgroundImage: `url(${image})`}}>
                    <div className='filter_layer' style={{backgroundColor: `rgba(0,0,0,${filter})`}}></div>
                    <div className='content_layer'>
                        <div className='banner_title'>
                            <h1>{title}</h1>
                            <div className='breadcrum'>
                                <span>
                                    <NavLink to="/" exact="true">Home</NavLink>
                                </span>
                                <span>|</span>
                                {breadcrumSubItem && breadcrumSubLink &&
                                    <>
                                        <span>
                                            <NavLink to={breadcrumSubLink} exact="true">{breadcrumSubItem}</NavLink>
                                        </span>
                                        <span>|</span>
                                    </>
                                }
                                <span className='current'>{breadcrumbTitle ? breadcrumbTitle : title}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='main_content'>
                    {children}
                </div>
            </div>
        </HelmetProvider>
    )
}; export default PageLayout;