import React from 'react'
import { Icon } from '@fluentui/react/lib/Icon';
import { getFileTypeIconProps, FileIconType } from '@fluentui/react-file-type-icons';
import { getInitials, getExtension } from '../../utils';
import { Skeleton, SkeletonLine, SkeletonCircle } from '../../components/ui/skeleton';

const Suggestions = (props) => {
    const { results, style, users, listRef, handleItemClick, permissions, query, loading } = props;
    for (var i = 0; i < users.length; i++) {
        users[i].Tipo = 'user';
    }
    for (var j = 0; j < results.length; j++) {
        switch (results[j].tipoGraph) {
            case 0:
                results[j].tipo = 'File';
                break;
            case 1:
                results[j].tipo = 'Site';
                break;
            case 2:
                results[j].tipo = 'Application';
                break;
            case 3:
                results[j].tipo = 'File';
                break;
            default:
                break
        }
        if (results[j].webUrl) {
            results[j].extension = getExtension(results[j].webUrl);
        }
    }

    const noDuplicates = results.filter((value, index, self) =>
        index === self.findIndex((t) => (
            t.id === value.id
        ))
    )

    const result_types = ['Site', 'File'];

    return (
        <div className={`search_list ${style}`} ref={listRef}>
            <div className='search_wraper'>
                {permissions === 'corporate' && noDuplicates && noDuplicates.length > 0 ?
                    <div className='search_item'>
                        {noDuplicates.length >= 1 && result_types.map((type, index) => (
                            <div className='section' key={index}>
                                <ul className='section_results'>
                                    <span className='section_title'>{type}s</span>
                                    {noDuplicates.filter(function (t) { return t.tipo === type }).length > 0 ? 
                                        noDuplicates.filter(function (t) { return t.tipo === type }).slice(0,3).map((item, index) => {
                                        return (
                                            <li className='result_item' key={index}>
                                                <div className='link' onClick={() => handleItemClick(item.webUrl)}>
                                                    {type === 'File' ?
                                                        <>
                                                            {item.extension ?
                                                                <Icon {...getFileTypeIconProps({ extension: item.extension, type: FileIconType.docset, size: 32, imageFileType: 'svg' })} />
                                                                :
                                                                <Icon {...getFileTypeIconProps({ type: FileIconType.folder, size: 32, imageFileType: 'svg' })} />
                                                            }
                                                        </>
                                                        :
                                                        <div className='icon'>{getInitials(item.displayName)}</div>
                                                    }
                                                    <div className='title'>
                                                        {type === 'File' ?
                                                            <>
                                                                <span>{(item.tipo === 'Document' && item.displayName && item.extension !== undefined) ? 
                                                                    item.displayName.replace(/\.[^/.]+$/, "") 
                                                                    : item.displayName
                                                                }</span>
                                                                <br />
                                                                <p>{item.webUrl}</p>
                                                            </>
                                                            :
                                                            <span>{item.displayName}</span>
                                                        }
                                                    </div>
                                                </div>
                                            </li>
                                        )
                                    })
                                    :
                                        <div className='no_results'>
                                            <span>No {type.toLowerCase()}s found for your search.</span>
                                        </div>
                                    }
                                </ul>
                            </div>
                        ))}
                    </div>
                    :
                    <>
                        {permissions === 'corporate' && loading && query.length > 2 &&
                            <>
                                <div className='search_item'>
                                    <div className='section'>
                                        <ul className='section_results'>
                                            <span className='section_title'>Sites</span>
                                            {Array(2).fill().map((_, i) => (
                                                <li className='result_item' key={i}>
                                                    <div className='link'>
                                                        <SkeletonLine width="50px" height="50px" />
                                                        <div className='title'>
                                                            <Skeleton flex="column">
                                                                <SkeletonLine width="320px" height="20px" />
                                                                <SkeletonLine width="140px" height="20px" />
                                                            </Skeleton>
                                                        </div>
                                                    </div>
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                </div>
                                <div className='search_item'>
                                    <div className='section'>
                                        <ul className='section_results'>
                                            <span className='section_title'>Documents</span>
                                            {Array(2).fill().map((_, i) => (
                                                <li className='result_item' key={i}>
                                                    <div className='link'>
                                                        <SkeletonLine width="50px" height="50px" />
                                                        <div className='title'>
                                                            <Skeleton flex="column">
                                                                <SkeletonLine width="320px" height="20px" />
                                                                <SkeletonLine width="140px" height="20px" />
                                                            </Skeleton>
                                                        </div>
                                                    </div>
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                </div>
                            </>
                        }
                    </>
                }{permissions === 'corporate' && (results.length === 0 && users.length === 0) && query.length > 2 && !loading &&
                    <div className='no_result'>
                        <span>Nothing found for your search, try again.</span>
                    </div>
                }{users.length > 0 ?
                    <div className='search_item'>
                        <div className='section'>
                            <ul className='section_results'>
                                <span className='section_title'>People</span>
                                {users.slice(0,3).map((item, index) => {
                                    let fullname = `${item.firstname}${item.middlename ? ' ' + item.middlename + ' ' : ' '}${item.lastname}`;
                                    let searchName = `${item.firstname} ${item.lastname}`;
                                    return (
                                        <li className='result_item' key={index}>
                                            <div className='link' onClick={() => handleItemClick(searchName)}>
                                                <div className='no_image'>{getInitials(`${fullname}`)}</div>
                                                <div className='title'>
                                                    <span>{fullname}</span>
                                                </div>
                                            </div>
                                        </li>
                                    )
                                })}
                            </ul>
                        </div>
                    </div>
                    :
                    <>
                        {query.length > 2 && loading &&
                            <div className='search_item'>
                                <div className='section'>
                                    <ul className='section_results'>
                                        <span className='section_title'>People</span>
                                        {Array(2).fill().map((_, i) => (
                                            <li className='result_item' key={i}>
                                                <div className='link'>
                                                    <SkeletonCircle width="50px" />
                                                    <div className='title'>
                                                        <Skeleton flex="column">
                                                            <SkeletonLine width="320px" height="20px" />
                                                        </Skeleton>
                                                    </div>
                                                </div>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                        }
                    </>
                }{users.length === 0 && results.length > 0 && query.length > 2 && !loading &&
                    <div className='search_item'>
                        <div className='section'>
                            <ul className='section_results'>
                                <span className='section_title'>People</span>
                                <div className='no_results'>
                                    <span>No people found for your search.</span>
                                </div>
                            </ul>
                        </div>
                    </div>
                }
            </div>
        </div>
    )
}

export default Suggestions