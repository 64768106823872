function BookingLogo(){
    return(
        <svg width="53" height="52" viewBox="0 0 53 52" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_344_3060)">
                <path fillRule="evenodd" clipRule="evenodd" d="M53 9.98387C53 4.47353 48.5387 0 43.0462 0H9.95382C4.46133 0 0 4.47353 0 9.98387V42.0161C0 47.525 4.46133 52 9.95382 52H43.0462C48.5387 52 53 47.525 53 42.0161V9.98387Z" fill="#0C3B7C"/>
                <path fillRule="evenodd" clipRule="evenodd" d="M0.00195312 32.3049H26.4721V52H0.00195312V32.3049Z" fill="#0C3B7C"/>
                <path d="M24.0876 34.4194L19.8188 34.4152V29.2947C19.8188 28.2006 20.2419 27.6311 21.1751 27.5011H24.0876C26.1646 27.5011 27.5082 28.8149 27.5082 30.9412C27.5082 33.0676 26.1987 34.4175 24.0876 34.4196V34.4194ZM19.8188 20.6079V19.26C19.8188 18.0807 20.3161 17.5196 21.4068 17.4471H23.5924C25.4652 17.4471 26.5877 18.5711 26.5877 20.4542C26.5877 21.8874 25.8181 23.5614 23.6604 23.5614H19.819V20.6077L19.8188 20.6079ZM29.5383 25.718L28.7666 25.2829L29.4406 24.7048C30.225 24.0287 31.5388 22.508 31.5388 19.8848C31.5388 15.8669 28.4327 13.2756 23.6263 13.2756H18.1414V13.2734H17.5164C16.092 13.3268 14.9506 14.4891 14.9355 15.9266V38.7254H23.7346C29.077 38.7254 32.5252 35.8078 32.5252 31.2886C32.5252 28.8551 31.4111 26.7757 29.5383 25.718Z" fill="white"/>
                <path d="M34.8164 35.5759C34.8164 33.8343 36.2167 32.4253 37.9417 32.4253C39.6668 32.4253 41.0778 33.8343 41.0778 35.5759C41.0778 37.3176 39.6712 38.7266 37.9417 38.7266C36.2122 38.7266 34.8164 37.3155 34.8164 35.5759Z" fill="#00BAFC"/>
            </g>
            <defs>
                <clipPath id="clip0_344_3060">
                    <rect width="53" height="52" fill="white"/>
                </clipPath>
            </defs>
        </svg>
    )
} export default BookingLogo;