import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Header from './header';
import Footer from './footer';
import '../../styles/error.scss';

function Layout(props) {
    const { userData, departments, tools, tasks, firstUrl } = props;
    const [url, setUrl] = useState(firstUrl || sessionStorage.getItem('firstUrl'));
    const navigate = useNavigate();

    useEffect(() => {
        if(url) {
            navigate(url);
            setUrl(null);
            sessionStorage.removeItem('firstUrl');
        }
    }, [url]);

    return (
        <>
            <Header userData={userData} departments={departments} tools={tools} tasks={tasks}/>
            <main>
                {props.children}
            </main>
            <Footer />
        </>
    );
} export default Layout;