import React from "react";

export default function DnsCheck() {
    return (
        <div style={{display: 'flex', justifyContent: 'center', alignContent: 'center', alignItems: 'center', height: '100vh'}}>
            <h1 style={{fontFamily: 'Gotham-Light', fontStyle: 'normal', fontWeight: '325', fontSize: '40px', textTransform: 'uppercase', color: '#53565A'}}>
                Hi, This is just a Page to check the DNS
            </h1>
        </div>
    );
}