import React from 'react'
import { Skeleton, SkeletonLine } from '../../components/ui/skeleton';

export default function DirectorySuggestions(props){
    const {subFolders, regions, departments, style, noResults, listRef, handleItemClick} = props;

    return (
        <div className={`search_list ${style}`} ref={listRef} id="search_list">
            <div className='search_wraper'>
                {(subFolders.length > 0 || regions.length > 0 || departments.length > 0) ?
                    <div className='search_item'>
                        <div className='section'>
                            <ul className='section_results' id="results">
                                {subFolders.length > 0 && 
                                    <>
                                        <span className='section_title'>SubFolder</span>
                                        {subFolders.map((item, index) => {
                                            return(
                                                <li className='result_item' key={index}>
                                                    <div className='link' onClick={() => handleItemClick(item)}>
                                                        <div className='title'>
                                                            <span>{item}</span>
                                                        </div>
                                                    </div>
                                                </li>
                                            )
                                        })}
                                    </>
                                }
                                {regions.length > 0 && 
                                    <>
                                        <span className='section_title'>Region</span>
                                        {regions.map((item, index) => {
                                            return(
                                                <li className='result_item' key={index}>
                                                    <div className='link' onClick={() => handleItemClick(item)}>
                                                        <div className='title'>
                                                            <span>{item}</span>
                                                        </div>
                                                    </div>
                                                </li>
                                            )
                                        })}
                                    </>
                                }
                                {departments.length > 0 && 
                                    <>
                                        <span className='section_title'>Department</span>
                                        {departments.map((item, index) => {
                                            return(
                                                <li className='result_item' key={index}>
                                                    <div className='link' onClick={() => handleItemClick(item)}>
                                                        <div className='title'>
                                                            <span>{item}</span>
                                                        </div>
                                                    </div>
                                                </li>
                                            )
                                        })}
                                    </>
                                }
                            </ul>
                        </div>
                    </div>
                : !noResults &&
                    <div className='search_item'>
                        <div className='section'>
                            <ul className='section_results'>
                                <span className='section_title'><SkeletonLine width="200px" height="25px"/></span>
                                {Array(2).fill().map((_, i) => (
                                    <li className='result_item' key={i}>
                                        <div className='link'>
                                            <div className='title'>
                                                <Skeleton flex="column">
                                                    <SkeletonLine width="320px" height="20px"/>
                                                    <SkeletonLine width="140px" height="20px"/>
                                                </Skeleton>
                                            </div>
                                        </div>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                }{noResults &&
                    <div className='no_result'>
                        <span>Nothing found for your search, try again.</span>
                    </div>
                }
            </div>
        </div>
    )
};