import fetchData from '../components/hooks/getData';
import LoggerService from '../services/LoggerService';
import CachedService from '../services/CachedService';

function Service() {
    const logger = new LoggerService('ToolsAndResourcesService');
    const permissions = sessionStorage.getItem('permissions');

    const get = async () => {
        logger.debug('Retrieving toolsAndResources list...');
        if(permissions === 'corporate')
            var data = await fetchData(`/ToolsAndResources/GetToolsAndResources`, 'get', 'json');
        else
            var data = await fetchData(`ToolsAndResources/hotel/GetToolsAndResources`, 'get', 'json'); 
        
        if(data && data.length > 0){
            var toolsAndResources = data.map(l => {
                l.iconType === ".png" ? l.icon = `data:image/png;base64,${l.icon}` : l.icon = l.icon;
                return l;
              });
            return toolsAndResources;
        }
        
    };

    return {
        get
    };
}

export function ToolsAndResourcesService(params) {
    return new CachedService({
        key: 'tools',
        service: new Service(params)
    });
};

export function FrequentToolsService() {
    const logger = new LoggerService('FrequentToolsService');

    const get = async () => {
        logger.debug('Retrieving user most frequent tools list...');
        const data = await fetchData(`/ToolsAndResources/GetMostFrequentlyTools`, 'get', 'json');
        return data
    };

    return {
        get
    };
}