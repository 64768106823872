import React, { useState, useRef, useEffect, useMemo } from 'react';
import { useNavigate, useSearchParams } from "react-router-dom";
import { TooltipHost, DirectionalHint } from '@fluentui/react';
import { useId } from '@fluentui/react-hooks';
import PageLayout from '../components/layout/pageLayout';
import Suggestions from './components/suggestions';
import Links from '../links/links';
import Results from './components/results';
import LoadingResults from './components/loading';
import InfoIcon from '../components/icons/info';
import SearchIcon from '../components/icons/search';
import CloseIcon from '../components/icons/close';
import useClickOutsideHook from '../components/hooks/clickOutsideHook';
import '../styles/search.scss';

const BannerImg = '/assets/banners/search_banner.jpg';

const inputLabels = {
    corporate: 'Search here',
    hotel: 'Search here',
};

const inputPlaceholders = {
    corporate: 'Search for documents, people and sites',
    hotel: 'Search for people',
};

function SearchPage() {
    const [changing, setChanging] = useState(false);
    const [submited, setSubmited] = useState(false);
    const [query, setQuery] = useState('');
    const [suggestionsQuery, setSuggestionsQuery] = useState('');
    const [resultsQuery, setResultsQuery] = useState('');
    const [suggestionsLoading, setSuggestionsLoading] = useState(true);
    const [resultsLoading, setResultsLoading] = useState(true);
    const [suggestionsUsers, setSuggestionsUsers] = useState([]);
    const [suggestionsResults, setSuggestionsResults] = useState([]);
    const [submitResults, setSubmitResults] = useState([]);
    const [submitUsers, setSubmitUsers] = useState([]);
    const [graphTotal, setGraphTotal] = useState(0);
    const [areMoreResults, setAreMoreResults] = useState(false);
    const [suggestionsStyles, setSuggestionsStyles] = useState('');
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const permissions = sessionStorage.getItem('permissions');
    const user_token_api = sessionStorage.getItem('accessTokenAPI');
    const input_label = useMemo(() => inputLabels[permissions] || inputLabels.hotel, [permissions]);
    const input_placeholder = useMemo(() => inputPlaceholders[permissions] || inputPlaceholders.hotel, [permissions]);

    const queryParam = searchParams.get('query') ? searchParams.get('query').split('?')[0] : null;

    const searchInputRef = useRef(null);
    const searchListRef = useRef(null);

    const fetchHeaders = {
        method: 'get',
        headers: new Headers({
            'Authorization': 'Bearer ' + user_token_api
        })
    };

    useEffect(() => {
        setTimeout(() => setSuggestionsQuery(query), 600);
    }, [query]);

    useEffect(() => {
        async function fetchSuggestions() {
            if(permissions === 'corporate')
                var responseSuggestions = await fetch(`/Suggestions/GetSuggestions?queryString=${suggestionsQuery}`, fetchHeaders);
            else
            var responseSuggestions = await fetch(`/Suggestions/hotel/GetSuggestions?queryString=${suggestionsQuery}`, fetchHeaders);

            if(responseSuggestions.status == 401){
                sessionStorage.clear();
                document.cookie = 'adfs_token=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
                window.location.href = '/';
            }else{
                const dataSuggestions = await responseSuggestions.json();
                setSuggestionsUsers(dataSuggestions.listPeople);
    
                if (permissions === 'corporate') {
                    if(dataSuggestions.listDriveItems && dataSuggestions.listSites){
                        const suggestionsMerged = dataSuggestions.listDriveItems.concat(dataSuggestions.listSites);
                        setSuggestionsResults(suggestionsMerged);
                    } else {
                        setSuggestionsResults([]);
                    }
                }
            }

            setSuggestionsLoading(false);
        }
        if (suggestionsQuery.length >= 3 && suggestionsQuery === query) {
            setSuggestionsLoading(true);
            fetchSuggestions();
        }
        if (suggestionsQuery.length <= 2) {
            setSuggestionsUsers([]);
            setSuggestionsResults([]);
            setSuggestionsLoading(true);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [suggestionsQuery]);

    async function handleSearchSubmit(event, newQuery) {
        if(event){
            event.preventDefault();
        }
        if ((query !== '' && query.length >= 3) || (newQuery !== '' && newQuery.length >= 3)) {
            setResultsLoading(true);
            setResultsQuery(newQuery ? newQuery : query);
            setSubmited(true);
            setChanging(false);
            setSuggestionsStyles('');

            if(permissions === 'corporate')
                var responseUsers = await fetch(`/people/GetPeopleByName?name=${newQuery ? newQuery : query}`, fetchHeaders);
            else
                var responseUsers = await fetch(`/people/hotel/GetPeopleByName?name=${newQuery ? newQuery : query}`, fetchHeaders);

            if(responseUsers.status == 401){
                    sessionStorage.clear();
                    document.cookie = 'adfs_token=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
                    window.location.href = '/';
            }else{
                const dataUsers = await responseUsers.json();
                setSubmitUsers(dataUsers);
    
                if (permissions === 'corporate') {
                    const responseResults = await fetch(`/SearchGraph/GetSearchGraph?queryString=${newQuery ? newQuery : query}&from=0`, fetchHeaders);
                    if(responseResults.status === 200){
                        const dataResults = await responseResults.json();
                        setSubmitResults(dataResults.searchResult);
                        setGraphTotal(dataResults.total);
                        setAreMoreResults(dataResults.moreResultsAvailable);
                    }else if(responseResults.status == 401){
                        sessionStorage.clear();
                        document.cookie = 'adfs_token=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
                        window.location.href = '/';
                    }
                     else {
                        setSubmitResults([]);
                        setAreMoreResults(false);
                    }
            }
            }

            setResultsLoading(false);
            navigate(`/documents?query=${newQuery ? newQuery : query}`);
        }
    }

    useEffect(() => {
        if(queryParam){
            setQuery(queryParam);
            handleSearchSubmit(false, queryParam);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [queryParam]);

    const handleItemClick = (item) => {
        if (item.includes('https://') || item.includes('http://')) {
            window.open(item, '_blank');
        } else {
            setSuggestionsStyles('');
            setSubmited(true);
            setQuery(`${item}`);
            handleSearchSubmit(false, `${item}`);
            navigate(`/documents?query=${item}`);
        }
    }

    const checkQuery = () => {
        if (query !== '' && suggestionsStyles === '' && query.length >= 3) {
            setSuggestionsStyles('active');
        }
    }  

    const resetSearch = () => {
        document.getElementById('search_input').value = '';
        setQuery('');
        setSubmited(false);
        setSuggestionsStyles('');
        setSuggestionsResults([]);
        setSuggestionsUsers([]);
        setSubmitUsers([]);
        setSubmitResults([]);
        setResultsLoading(true);
        setSuggestionsLoading(true);
        navigate(`/documents`);
    }

    function handleInputChange(e) {
        let inputValue = e.target.value.trimStart();
        let searchQuery = searchParams.get('query') ? searchParams.get('query').split('?')[0] : null;
        setQuery(inputValue);
        if (inputValue && inputValue.length >= 3) {
            setSuggestionsStyles('active');
        } 
        if (!inputValue || inputValue.length <= 2) {
            setSuggestionsStyles('');
            setSuggestionsResults([]);
            setSuggestionsUsers([]);
        }
        if (searchQuery && searchQuery !== inputValue) {
            setChanging(true);
        }
    }

    useClickOutsideHook(searchInputRef, searchListRef, () => setSuggestionsStyles(''));
    useClickOutsideHook(searchListRef, searchInputRef, () => setSuggestionsStyles(''));

    const tooltipInfo = useId('tooltipInfo');
    const tooltipSearch = useId('tooltipSearch');

    return (
        <PageLayout windowTitle={permissions === 'corporate' ? "Documents" : 'People'} breadcrumbTitle={permissions === 'corporate' ? "Documents" : 'People'}
            title={permissions === 'corporate' ? "RHG Document Repository": 'RHG People'} image={BannerImg} filter="0.4">
            <div className='search_area' id="search_area">
                <div className='search_box'>
                    <form autoComplete="off" onSubmit={handleSearchSubmit}>
                        <div className='input_group'>
                            <label className='fluent_label'>
                                <span className='title'>{input_label}</span>
                                <TooltipHost
                                    content={ permissions === 'corporate' ?
                                        "Search field allows you to search for information across all RHG Sharepoint Sites and People Directory."
                                        : "Search field allows you to search for information across People Directory."
                                    }
                                    id={tooltipInfo}
                                    calloutProps={{ isBeakVisible: false, gapSpace: 10 }}
                                    className='fluent_tooltip'
                                    directionalHint={DirectionalHint.rightCenter}
                                >
                                    <div className='info' aria-describedby={tooltipInfo}><InfoIcon /></div>
                                </TooltipHost>
                            </label>
                            <input placeholder={input_placeholder} className='fluent_input' type="text" id="search_input" value={query}
                                onChange={(e) => handleInputChange(e)} ref={searchInputRef} 
                                onClick={() => checkQuery()}
                            />
                            <div className='input_icon' onClick={(submited && !changing) ? () => resetSearch() : handleSearchSubmit}>
                                <TooltipHost
                                    content={(submited && !changing) ? 'Clear search query' : 'Search'}
                                    id={tooltipSearch}
                                    calloutProps={{ isBeakVisible: false, gapSpace: 10 }}
                                    className='fluent_tooltip'
                                    directionalHint={DirectionalHint.bottomCenter}
                                >
                                    <div className='icon' aria-describedby={tooltipSearch}>
                                        {(submited && !changing) ? <CloseIcon /> : <SearchIcon />}
                                    </div>
                                </TooltipHost>
                            </div>
                        </div>
                        {suggestionsResults && suggestionsUsers &&
                            <Suggestions results={suggestionsResults} style={suggestionsStyles} permissions={permissions} loading={suggestionsLoading}
                                users={suggestionsUsers} listRef={searchListRef} handleItemClick={handleItemClick} query={query}
                            />
                        }
                    </form>
                </div>
                {submited ?
                    (!resultsLoading ?
                        <Results results={submitResults} query={resultsQuery} users={submitUsers} graphTotal={graphTotal} moreResults={areMoreResults} 
                            token={user_token_api} permissions={permissions}
                        />
                        :
                        <LoadingResults />
                    )
                    :
                    <Links permissions={permissions} token={ user_token_api } />
                }
            </div>
        </PageLayout>
    )
}; export default SearchPage;