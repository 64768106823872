import fetchData from '../components/hooks/getData';
import LoggerService from '../services/LoggerService';
import CachedService from '../services/CachedService';

function Service({ permissions }) {

    const logger = new LoggerService('HotelKitService');

    const get = async () => {
        logger.debug('Retrieving hotel kit links list...');
        if(permissions === 'corporate')
            var data = await fetchData(`hotelkit/list`, 'get', 'json');
        else
            var data = await fetchData(`hotelkit/hotel/list`, 'get', 'json');
        return data && data.map(d => {
            d.icon = d.iconType !== '.svg'
                ? `data:image/png;base64,${d.icon}` 
                : d.icon;
            d.link = d.link !== ''
                ? JSON.parse(d.link).Url
                : d.link;
            return d;
        });
    };

    return {
        get
    };
}

export default function HotelKitService(params) {
    return new CachedService({
        key: 'hotelkitlinks',
        service: new Service(params)
    });
};