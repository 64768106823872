import React from "react";
import { SkeletonLine, SkeletonGap } from "../../components/ui/skeleton";

export default function LoadingVideoGuides() {

    return(
        <div className="videos">
            {Array(1).fill().map((_, i) => {
                return (
                    <div className="video" key={i}>
                        <div className="video_description">
                            <div className="description_title"><SkeletonLine width='450px' height='35px' /></div>
                            <div className="description_text">
                                <SkeletonLine width='450px' height='20px' />
                                <SkeletonGap width='450px' height='5px' />
                                <SkeletonLine width='400px' height='20px' />
                            </div>
                        </div>
                        <SkeletonLine width='928px' height='400px' />
                    </div>
                )
            })}
        </div>
    )
}