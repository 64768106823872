import React from "react";
import { SkeletonLine } from "../../components/ui/skeleton";

export default function RadissonRequestLoading() {
    return (
        Array(3).fill().map((_, i) => (
            <div className="form_group" key={i}>
                <div className="group_title">
                    <span className="title"><SkeletonLine width="180px" height="30px"/></span>
                </div>
                <div className="group_forms">
                    {Array(10).fill().map((_, i) => {
                        return (
                            <div className="form_item" key={i}>
                                <div className="item_icon">
                                <SkeletonLine width="48px" height="48px"/>
                                </div>
                                <div className="item_content">
                                    <span className="title"><SkeletonLine width="300px" height="20px"/></span>
                                    <div className="content">
                                        <span className="label"><SkeletonLine width="130px" height="20px"/></span>
                                        <span className="label"><SkeletonLine width="130px" height="20px"/></span>
                                    </div>
                                </div>
                            </div>
                        )
                    })}        
                </div>
            </div>
        ))
    )
}