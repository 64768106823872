import React from "react";
import FileIcon from "../../components/icons/file";

export default function UsefulRequestsListItem(props) {
    const { request } = props;

    return(
        <div className="item">
            <a className="wrapper" href={request.url} target="_blank" rel="noreferrer">
                <div className="icon">
                    <FileIcon />
                </div>
                <div className="text">
                    <span className="departement">{request.department}</span>
                    <span className="title">{request.titleRequest}</span>
                </div>
            </a>
        </div>
    )
}