import React from "react";
import { Skeleton, SkeletonLine, SkeletonGap } from '../../components/ui/skeleton';
import { formatedDate } from '../../utils';
import { NavLink } from 'react-router-dom';
import CalendarIcon from '../../components/icons/calendar';
import LocationIcon from '../../components/icons/location';

function UserInfo(props) {
    const { info } = props;
    return (
        <>
            {Object.keys(info).length > 0 ?
                <div className='user_info'>
                    <div className='user_name'>
                        {info.isUserHotel ?
                            <h1>Hi <NavLink to="/dashboard" exact="true" className="link">{info && info.displayName !== null && info.displayName.split(' ')[info.displayName.split(' ').length - 1]}</NavLink></h1>
                         :
                            <h1>Hi <NavLink to="/dashboard" exact="true" className="link">{info && info.givenName}</NavLink></h1>}
                    </div>
                    <div className='extra_info'>
                        <h1 className='location'><LocationIcon /> {info && info.location ? info.location : "Unknown"}</h1>
                        <h1 className='date'><CalendarIcon /> {formatedDate(new Date(), true)}</h1>
                    </div>
                </div>
                : 
                <div className='user_info'>
                    <Skeleton>
                        <SkeletonLine width={'40%'} height={'20px'} transparent/>
                        <SkeletonGap width={'20%'} height={'20px'}/>
                        <SkeletonLine width={'19%'} height={'20px'} transparent/>
                        <SkeletonGap width={'5%'} height={'20px'}/>
                        <SkeletonLine width={'15%'} height={'20px'} transparent/>
                    </Skeleton>
                </div>
            }
        </>
    );
}export default UserInfo;