import { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';

export default function ScrollToTopOnPageChange() {
    const { pathname } = useLocation();
    const prevPathnameRef = useRef();
  
    useEffect(() => {
      if (pathname !== prevPathnameRef.current) {
        const handleScroll = () => {
            window.requestAnimationFrame(() => {
              window.scrollTo(0, 0);
            });
        };
        handleScroll();
        prevPathnameRef.current = pathname;
      }
    }, [pathname]);
  
    return null;
}