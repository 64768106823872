import fetchData from '../components/hooks/getData';
import LoggerService from '../services/LoggerService';

export default function HelpGuidesService() {
    const permissions = sessionStorage.getItem('permissions');
    const logger = new LoggerService('HelpGuidesService');

    const get = async () => {
        logger.debug('Retrieving help guides list...');
        if(permissions === 'corporate')
            var data = await fetchData(`helpGuides/list`, 'get', 'json');
        else
            var data = await fetchData(`helpGuides/hotel/list`, 'get', 'json');
        const videos = data && data.filter(d => {return d.iFrame !== ''});
        const guides = data && data.filter(d => {return d.iFrame === ''});
        videos.map(v => {
            v.files = guides.filter(g => {return g.videoId === v.id});
            return v;
        });
        return {videos: videos, guides: guides}
    };

    return {
        get
    };
}