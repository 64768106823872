import React, { useEffect, useState } from "react";
import { BenefitsService } from '../dashboardService';
import DashboardBenefitsLoading from './benefitsLoading';
import DashboardBenefitsError from './benefitsError';
import BenefitItem from "./benefit";


export default function DashboardBenefits() {
    const [benefits, setBenefits] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        (async () => {
            const benefitsService = new BenefitsService();
            const data = await benefitsService.get();
            if (data && !('status' in data)) {
                setBenefits(data);
            }
            setLoading(false);
        })();
    }, []);

    return (
        <div className="dashboard_card">
            {benefits && !loading &&
                <div className="card_content">
                    <div className="header">
                        <span className="title">Benefits</span>
                    </div>
                    <hr />
                    <div className="benefits">
                        {benefits && !loading && benefits.map((benefit, i) => {
                            return (
                                <BenefitItem benefit={benefit} key={i} />
                            )
                        })
                        }
                    </div>
                </div>
            }
            {loading && !benefits && <DashboardBenefitsLoading />}
            {!loading && !benefits && <DashboardBenefitsError />}
        </div>
    );
};