import fetchData from '../components/hooks/getData';
import LoggerService from '../services/LoggerService';

export default function ConsentService() {
    const logger = new LoggerService('ConsentFormService');
    const permissions = sessionStorage.getItem('permissions');

    
    const get = async () => {
        logger.debug('Retrieving consent form text...');
        if(permissions === 'corporate')
            var data = await fetchData(`/consent/texts/latest`, 'get', 'json');
        else
            var data = await fetchData(`/consent/hotel/texts/latest`, 'get', 'json');
        if (data) { return data } 
    };

    return {
        get
    };
};