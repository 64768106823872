import React, { useState, useEffect } from 'react';
import BestEmployerService from '../../bestemployer/bestEmployerService';
import BestEmployerLoading from './bestEmployerLoading';
import BestEmployerError from './bestEmployerError';

function BestEmployer(){
    const [forbesData, setForbesData] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        (async () => {
            const bestEmployerService = new BestEmployerService();
            const bestEmployerData = await bestEmployerService.get();
            setForbesData(bestEmployerData);
            setLoading(false);
        })()
    }, []);

    return(
        <>
            {forbesData && !loading &&
                <div className='stat forbes'>
                    <div className='header_title'>
                        <span className='title'>{forbesData.title}</span>
                        <span className='subtitle'>{forbesData.subtitle}</span>
                    </div>
                    <div className='image'>
                        <img src={ 'data:image/jpeg;base64,' + forbesData.imageBase64 } alt="forebes_logo"/>
                    </div>
                </div>
            }
            {loading && <BestEmployerLoading />}
            {!forbesData && !loading && <BestEmployerError />}
        </>
    )
} export default BestEmployer;