import React from 'react';
import ScreenSizeService from '../../services/screenSizeService';
import { Skeleton, SkeletonCircle, SkeletonGap, SkeletonLine } from "../../components/ui/skeleton";

export default function DashboardProfileLoading() {
    const mobile = new ScreenSizeService().isMobile();
    
    return(
        <div className="card_content">
            <div className="header">
                <Skeleton>
                    <SkeletonCircle width={mobile ? "150px" : "82px"}/>
                    <SkeletonGap width={'30px'} height={'20px'}/>
                    <Skeleton flex="column">
                        <SkeletonLine width={'200px'} height={'20px'}/>
                        <SkeletonLine width={'250px'} height={'20px'}/>
                    </Skeleton>
                </Skeleton>
            </div>
            <div className="body">
                <Skeleton flex="column">
                    <SkeletonLine width={'100px'} height={'20px'}/>
                    <SkeletonLine width={'280px'} height={'20px'}/>
                    <SkeletonLine width={'120px'} height={'20px'}/>
                    <SkeletonLine width={'300px'} height={'20px'}/>
                </Skeleton>
            </div>
        </div>
    )
}