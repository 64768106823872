import React, { useState, useRef } from 'react';
import { TooltipHost, DirectionalHint } from '@fluentui/react';
import { useId } from '@fluentui/react-hooks';
import SearchIcon from '../../components/icons/search';
import CloseIcon from '../../components/icons/close';
import DirectorySuggestions from './directorySearchSuggestions';
import useClickOutsideHook from '../../components/hooks/clickOutsideHook';

export default function DirectorySearch(props){
    const [query, setQuery] = useState('');
    const [submited, setSubmited] = useState(false);
    const [changing, setChanging] = useState(false);
    const [subFolders, setSubFolders] = useState([]);
    const [regions, setRegions] = useState([]);
    const [departments, setDepartments] = useState([]);
    const [noResults, setNoResults] = useState(false);
    const [suggestionsStyles, setSuggestionsStyles] = useState('');
    const { data, setFilteredData } = props;
    const input_label = 'Search Directory';
    const input_placeholder = 'Search for subfolders, regions or departments';

    const searchInputRef = useRef(null);
    const searchListRef = useRef(null);

    useClickOutsideHook(searchInputRef, searchListRef, () => setSuggestionsStyles(''));
    useClickOutsideHook(searchListRef, searchInputRef, () => setSuggestionsStyles(''));

    const tooltipSearch = useId('tooltipSearch');

    const subFoldersArr = data && data.map(item => item.subfolder).filter((value, index, self) =>
        index === self.findIndex((t) => (t === value))
    );
    const regionsArr = data && data.map(item => item.region).filter((value, index, self) =>
        index === self.findIndex((t) => (t === value))
    );
    const departmentsArr = data && data.map(item => item.department).filter((value, index, self) =>
        index === self.findIndex((t) => (t === value))
    );

    function handleInputChange(e){
        let inputValue = e.target.value;
        setQuery(inputValue);
        setChanging(true);
        if (inputValue && inputValue.length >= 1) {
            let filteredSubFolders = subFoldersArr.filter(item => item.toLowerCase().includes(inputValue.toLowerCase()));
            let filteredRegions = regionsArr.filter(item => item.toLowerCase().includes(inputValue.toLowerCase()));
            let filteredDepartments = departmentsArr.filter(item => item.toLowerCase().includes(inputValue.toLowerCase()));
            setSubFolders(filteredSubFolders);
            setRegions(filteredRegions);
            setDepartments(filteredDepartments);
            if(filteredSubFolders.length === 0 && filteredRegions.length === 0 && filteredDepartments.length === 0) {
                setNoResults(true);
            }
            setSuggestionsStyles('active');
            setFilteredData(data.filter(
                item => item.subfolder.toLowerCase().includes(query.toLowerCase()) || 
                item.region.toLowerCase().includes(query.toLowerCase()) || 
                item.department.toLowerCase().includes(query.toLowerCase()))
            )
            
            const list = document.getElementById('search_list');
            const searchText = inputValue;
            const regex = new RegExp(searchText, 'gi');

            if(suggestionsStyles === 'active' && list){
                const results = list.querySelectorAll('li');
                for (let i = 0; i < results.length; i++) {
                    let text = results[i].textContent;
                    text = text.replace(/(<mark class="highlight">|<\/mark>)/gim, '');
                    const newText = text.replace(regex, '<span class="highlight">$&</span>');
                    results[i].firstChild.firstChild.firstChild.innerHTML = newText;
                }
            }
        }
        if (!inputValue) {
            setDepartments([]);
            setRegions([]);
            setSubFolders([]);
            setNoResults(false);
            setSuggestionsStyles('');
            setDepartments(departmentsArr);
            setRegions(regionsArr);
            setSubFolders(subFoldersArr);
            setFilteredData(data);
            setChanging(false);
        }
        if (inputValue.length === 0) {
            setDepartments([]);
            setRegions([]);
            setSubFolders([]);
            setNoResults(false);
            setSuggestionsStyles('');
            setDepartments(departmentsArr);
            setRegions(regionsArr);
            setSubFolders(subFoldersArr);
            setFilteredData(data);
            setChanging(false);
        }
        if(query !== inputValue) {
            setNoResults(false);
        }
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        if(query !== '') {
            setSuggestionsStyles('');
            setSubmited(true);
            setFilteredData(data.filter(
                item => item.subfolder.toLowerCase().includes(query.toLowerCase()) || 
                item.region.toLowerCase().includes(query.toLowerCase()) || 
                item.department.toLowerCase().includes(query.toLowerCase()))
            )
        }
    };

    const handleItemClick = (item) => {
        setSuggestionsStyles('');
        setSubmited(true);
        setQuery(item);
        setFilteredData(data.filter(
            d => d.subfolder.toLowerCase().includes(item.toLowerCase()) || 
            d.region.toLowerCase().includes(item.toLowerCase()) || 
            d.department.toLowerCase().includes(item.toLowerCase()))
        )
    }

    const checkQuery = () => {
        if(query !== '' && suggestionsStyles === '') {
            setSuggestionsStyles('active');
        }
    }

    const resetSearch = () => {
        document.getElementById('search_input').value = '';
        setQuery('');
        setSubmited(false);
        setChanging(false);
        setSuggestionsStyles('');
        setFilteredData(data);
    }

    return (
        <div className='search_box'>
            <form autoComplete="off" onSubmit={handleSubmit}>
                <div className='input_group'>
                    <label className='fluent_label'>
                        <span className='title'>{input_label}</span>
                    </label>
                    <input placeholder={input_placeholder} className='fluent_input' type="text" id="search_input" value={query}
                        onChange={(e) => handleInputChange(e)} ref={searchInputRef} onClick={() => checkQuery()}
                    />
                    <div className='input_icon' onClick={(submited || changing) ? () => resetSearch() : handleSubmit}>
                        <TooltipHost
                            content={(submited || changing) ? 'Clear search query' : 'Search'}
                            id={tooltipSearch}
                            calloutProps={{isBeakVisible: false, gapSpace: 10}}
                            className='fluent_tooltip'
                            directionalHint={DirectionalHint.bottomCenter}
                        >
                            <div className='icon' aria-describedby={tooltipSearch}>
                                {(submited || changing) ? <CloseIcon /> : <SearchIcon/>}
                            </div>
                        </TooltipHost>
                    </div>
                </div>
                {(departments.length > 0 || regions.length > 0 || subFolders.length > 0) &&
                    <DirectorySuggestions subFolders={subFolders} regions={regions} departments={departments}
                        style={suggestionsStyles} noResults={noResults} listRef={searchListRef} handleItemClick={handleItemClick}
                    />
                }   
            </form>
        </div>
    );
};