import React, { useState, useEffect } from 'react';
import PageLayout from '../components/layout/pageLayout';
import InfoIcon from '../components/icons/info';
import DirectorySearch from './components/directorySearch';
import DirectoryList from './components/directoryList';
import DirectoryListLoading from './components/directoryListLoading';
import DirectoryListError from './components/directoryListError';
import DirectoryService from './directoryService';
import '../styles/directory.scss';

const BannerImg = '/assets/banners/sharepoint_banner.jpg';

function Directory() {
    const [directories, setDirectories] = useState(null);
    const [filteredData, setFilteredData] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        (async () => {
            const directoryService = new DirectoryService();
            const data = await directoryService.get();
            if(data && data.length > 0) {
                setLoading(false);
                setDirectories(data);
                setFilteredData(data);
            }
        })();
    }, []);


    return (
        <PageLayout windowTitle="SharePoint & Hotelkit Directory" title="SharePoint and hotelkit Directory" image={BannerImg} filter="0.2">
            <DirectorySearch data={directories} setFilteredData={setFilteredData}/>
            <div className="results_warpper">
                <div className="info_wrapper">
                    <div className="info_icon"><InfoIcon /></div>
                    <div className="info_title">
                        NOTE: The following folders have been removed: <b>Franchise Operations, Group Controlling, Fiori, EPIC, Hotel Openings and Transitions, 
                        New Web Governance, Radisson Blue Marketing, RadissonHotels.com Governance, rezPin and EAGLEComms.</b> Documents in these folders were 
                        reviewed and confirmed to be outdated/no longer in use by the document owners.
                        <b>You can get in touch with the document owners listed next to each folder's information button if you have any inquiries to a specific folder.</b>
                    </div>
                </div> 
                {directories && directories.length > 0 && !loading &&
                    <DirectoryList data={filteredData} />
                }
                {loading && <DirectoryListLoading />}
                {!directories && !loading && <DirectoryListError />}
            </div>
        </PageLayout>
    );
} export default Directory;