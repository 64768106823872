import React, { useState, useEffect } from 'react';
import { Pivot, PivotItem } from '@fluentui/react';
import { getInitials, getExtension } from '../../utils';
import { Icon } from '@fluentui/react/lib/Icon';
import { getFileTypeIconProps, FileIconType } from '@fluentui/react-file-type-icons';
import ScreenSizeService from '../../services/screenSizeService';
import PeopleLongCard from './peopleLongCard';
import PeopleMediumCard from './peopleMediumCard';
import PeopleShortCard from './peopleShortCard';
import Pagination from '../../components/ui/pagination';
import People from './people';
import LoadingResults from './loading';

function Results(props) {
    const { query, results, users, permissions, moreResults, graphTotal, token } = props;
    const [currentTab, setCurrentTab] = useState('');
    const [loading, setLoading] = useState(false);
    const [areMoreResults, setAreMoreResults] = useState(moreResults);
    const [currentPage, setCurrentPage] = useState(1);
    const [listSize, setListSize] = useState(70);
    const mobile = new ScreenSizeService().isMobile();
    const tablet = new ScreenSizeService().isTablet();
    const result_types = ['Site', 'File'];
    const PageSize = 7;

    const [totalPages, setTotalPages] = useState(results.length > 0 ? Math.ceil(results.length / PageSize) : 0);

    for (var i = 0; i < users.length; i++) {
        users[i].tipo = 'user';
    }
    for (var j = 0; j < results.length; j++) {
        switch (results[j].tipoGraph) {
            case 1:
                results[j].tipo = 'Site';
                break;
            case 3:
                results[j].tipo = 'File';
                break;
            default:
                break
        }
    }

    const fetchHeaders = {
        method: 'get',
        headers: new Headers({
            'Authorization': 'Bearer ' + token
        })
    };

    useEffect(() => {
        if (results.length === graphTotal) {
            setAreMoreResults(false);
        }
    }, [results, graphTotal]);

    const CurrentResultsData = (array) => {
        const firstPageIndex = (currentPage - 1) * PageSize;
        const lastPageIndex = firstPageIndex + PageSize;


        async function fetchMoreResults() {
            const responseResults = await fetch(`/SearchGraph/GetSearchGraph?queryString=${query}&from=${listSize}`, fetchHeaders);
            if(responseResults.status == 401){
                sessionStorage.clear();
                document.cookie = 'adfs_token=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
                window.location.href = '/';
            }else{
                const dataResults = await responseResults.json();
                results.push(...dataResults.searchResult);
                results.filter((item, index) => results.findIndex((i) => i.id === item.id) === index);
                setAreMoreResults(dataResults.moreResultsAvailable);
                setTotalPages(Math.ceil(results.length / PageSize));
                setLoading(false);
                if(dataResults.searchResult && dataResults.searchResult.length > 0){
                    setListSize(listSize + dataResults.searchResult.length);
                }
            }
        }

        if (currentPage > totalPages && permissions === 'corporate' && !array && areMoreResults && results.length < graphTotal) {
            setLoading(true);
            fetchMoreResults();
        }

        if (array) { return array.slice(firstPageIndex, lastPageIndex) }

        if (!array) { return results.slice(firstPageIndex, lastPageIndex) }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    };

    function handleTabChange(e) {
        let tab = e.props.headerText;
        setCurrentPage(1);
        setCurrentTab(tab);
    }

    useEffect(() => {
        var results = document.getElementById('all_results');
        var result_four = document.getElementById('result_3');
        var user_result = document.getElementById('user_result');
        var search_area = document.getElementById('search_area');

        if (user_result && currentTab === 'All files and sites') {
            results.insertBefore(user_result, result_four);
        }
        if (search_area) {
            search_area.scrollIntoView()
        }

    }, [currentPage, currentTab]);

    return (
        <div className='search_results' id='search_results'>
            <div className='title'>
                <h1>Search results for {query}</h1>
            </div>
            {results.length === 0 && users.length === 0 && permissions === 'corporate' && !loading &&
                <div className='no_results'>
                    <span>Nothing found for your search, try again.</span>
                </div>
            }{users.length === 0 && permissions === 'hotel' &&
                <div className='no_results'>
                    <span>No people found for your search, try again.</span>
                </div>
            }{(results.length > 0 || users.length > 0) && !loading ?
                <div className='results_wraper'>
                    <Pivot aria-label="search-results" onLinkClick={(e) => handleTabChange(e)} defaultSelectedKey={currentTab}>
                        {permissions === 'corporate' &&
                            <PivotItem headerText="All files and sites">
                                <div className='total_results'>
                                    <span>{currentPage > 1 && `Page ${currentPage} of `}{graphTotal} result{(graphTotal) > 1 ? 's' : ''}</span>
                                </div>
                                <div className='results' id="all_results">
                                    {CurrentResultsData().map((data, i) => {
                                        if (data) {
                                            let webExtension = data.tipo === 'File' ? getExtension(data.webUrl) : '';
                                            let titleExtension = data.tipo === 'File' ? getExtension(data.displayName) : '';
                                            let fileDate = data.lastModifiedDateTime ? new Date(data.lastModifiedDateTime) : new Date();
                                            let options = { month: 'short', day: 'numeric', year: 'numeric' };
                                            let fileFormated = new Intl.DateTimeFormat('en-US', options).format(fileDate);
                                            return (
                                                <div key={i} id={`result_${i}`} className='result_item_wrapper'>
                                                    {(data.tipo === 'File' || data.tipo === 'Site') ?
                                                        <div className='result_item'>
                                                            <div className='icon'>
                                                                {data.tipo === 'Site' ?
                                                                    <div className='site_icon'>{getInitials(data.displayName)}</div>
                                                                    :
                                                                    <div className='file_icon'>
                                                                        {(webExtension || titleExtension) ?
                                                                            <Icon {...getFileTypeIconProps({ extension: webExtension !== titleExtension ? titleExtension : webExtension, type: FileIconType.docset, size: 32, imageFileType: 'svg' })} />
                                                                            :
                                                                            <Icon {...getFileTypeIconProps({ type: FileIconType.folder, size: 32, imageFileType: 'svg' })} />
                                                                        }
                                                                    </div>
                                                                }
                                                            </div>
                                                            <div className='title'>
                                                                <a className='title_link' href={data.webUrl} target="_blank" rel="noreferrer">
                                                                    {(data.tipo === 'Document' && data.displayName && webExtension !== undefined) ?
                                                                        data.displayName.replace(/\.[^/.]+$/, "")
                                                                        : data.displayName
                                                                    }
                                                                </a>
                                                                {!mobile && !tablet ?
                                                                    <div className='description'>
                                                                        {data.tipo === 'Site' ?
                                                                            <>
                                                                                <a className='ubication' href={data.webUrl}>{data.webUrl}</a>
                                                                                <span className='text'>
                                                                                    {data.summary && data.summary}
                                                                                </span>
                                                                            </>
                                                                            :
                                                                            <>
                                                                                <a className='link' href={data.webUrl} target="_blank" rel="noreferrer"><b>{data.webUrl}</b></a>
                                                                                <span className='text'><b>{data.lastModifiedBy}</b> Last modified on {fileFormated}</span>
                                                                                <span className='text'><b>{data.displayName && data.displayName.replace(/\.[^/.]+$/, "")}</b>
                                                                                    &nbsp;{data.summary && data.summary}
                                                                                </span>
                                                                            </>
                                                                        }
                                                                    </div>
                                                                    :
                                                                    <div className='description'>
                                                                        {data.tipo === 'Site' ?
                                                                            <a className='ubication' href={data.webUrl}>{data.webUrl}</a>
                                                                            :
                                                                            <span className='author'>
                                                                                {fileFormated} · {data.lastModifiedBy && data.lastModifiedBy}
                                                                            </span>
                                                                        }
                                                                    </div>
                                                                }
                                                            </div>
                                                        </div>
                                                        :
                                                        <></>
                                                    }
                                                </div>
                                            )
                                        } else {
                                            return null
                                        }
                                    })}
                                </div>
                                <Pagination
                                    currentPage={currentPage}
                                    totalCount={results.length}
                                    pageSize={PageSize}
                                    moreResults={areMoreResults}
                                    onPageChange={page => { setCurrentPage(page) }}
                                />
                            </PivotItem>
                        }{permissions === 'corporate' && result_types.sort().reverse().map((type, index) => {
                            const filteredData = results.filter((data) => data.tipo === type);
                            return (
                                <PivotItem headerText={`${type}s`} key={index}>
                                    {filteredData.length > 0 ?
                                        <>
                                            <div className='total_results'>
                                                <span>
                                                    {currentPage > 1 && `Page ${currentPage} of `}{filteredData.length} result{(filteredData.length) > 1 ? 's' : ''}
                                                </span>
                                            </div>
                                            <div className='results'>
                                                {CurrentResultsData(filteredData).map((data, i) => {
                                                    let extension = data.tipo === 'File' ? getExtension(data.webUrl) : '';
                                                    let fileDate = data.lastModifiedDateTime !== null ? new Date(data.lastModifiedDateTime) : new Date();
                                                    let options = { month: 'short', day: 'numeric', year: 'numeric' };
                                                    let fileFormated = new Intl.DateTimeFormat('en-US', options).format(fileDate);
                                                    return (
                                                        <div key={i}>
                                                            {data.tipo === type &&
                                                                <div className='result_item' key={i}>
                                                                    <div className='icon'>
                                                                        {data.tipo === 'Site' &&
                                                                            <div className='site_icon'>{getInitials(data.displayName)}</div>
                                                                        }{data.tipo === 'File' &&
                                                                            <div className='file_icon'>
                                                                                {extension ?
                                                                                    <Icon {...getFileTypeIconProps({ extension: extension, type: FileIconType.docset, size: 32, imageFileType: 'svg' })} />
                                                                                    :
                                                                                    <Icon {...getFileTypeIconProps({ type: FileIconType.folder, size: 32, imageFileType: 'svg' })} />
                                                                                }
                                                                            </div>
                                                                        }
                                                                    </div>
                                                                    <div className='title'>
                                                                        <a className='title_link' href={data.webUrl} target="_blank" rel="noreferrer">
                                                                            {(data.tipo === 'File' && data.displayName && extension !== undefined) ?
                                                                                data.displayName.replace(/\.[^/.]+$/, "")
                                                                                : data.displayName
                                                                            }
                                                                        </a>
                                                                        {!mobile && !tablet ?
                                                                            <div className='description'>
                                                                                {data.tipo === 'Site' &&
                                                                                    <>
                                                                                        <a className='ubication' href={data.webUrl}>{data.webUrl}</a>
                                                                                        <span className='text'>
                                                                                            {data.summary && data.summary}
                                                                                        </span>
                                                                                    </>
                                                                                }{data.tipo === 'File' &&
                                                                                    <>
                                                                                        <a className='link' href={data.webUrl} target="_blank" rel="noreferrer"><b>{data.webUrl}</b></a>
                                                                                        <span className='text'><b>{data.lastModifiedBy}</b> Last modified on {fileFormated}</span>
                                                                                        <span className='text'><b>{data.displayName && data.displayName.replace(/\.[^/.]+$/, "")}</b>
                                                                                            &nbsp;{data.summary && data.summary}
                                                                                        </span>
                                                                                    </>
                                                                                }
                                                                            </div>
                                                                            :
                                                                            <div className='description'>
                                                                                {data.tipo === 'Site' &&
                                                                                    <a className='ubication' href={data.webUrl}>{data.webUrl}</a>
                                                                                }
                                                                                {data.tipo === 'File' &&
                                                                                    <span className='author'>
                                                                                        {fileFormated} · {data.lastModifiedBy && data.lastModifiedBy}
                                                                                    </span>
                                                                                }
                                                                            </div>
                                                                        }
                                                                    </div>
                                                                </div>
                                                            }
                                                        </div>
                                                    )
                                                })}
                                            </div>
                                            <Pagination
                                                currentPage={currentPage}
                                                totalCount={filteredData.length}
                                                pageSize={PageSize}
                                                moreResults={false}
                                                onPageChange={page => { setCurrentPage(page) }}
                                            />
                                        </>
                                        :
                                        <div className='no_results'>
                                            <span>No {type.toLowerCase()}s found for your search.</span>
                                        </div>
                                    }

                                </PivotItem>
                            )
                        })}
                        {users.length > 0 ?
                            <PivotItem headerText="People">
                                <People data={users} totalResults={users.length} query={query} />
                            </PivotItem>
                            :
                            <PivotItem headerText="People">
                                <div className='no_results'>
                                    <span>No people found for your search.</span>
                                </div>
                            </PivotItem>
                        }
                    </Pivot>
                </div>
                :
                loading ?
                    <div className='results_wraper'>
                        <LoadingResults />
                    </div>
                    :
                    null
            }
        </div>
    );
}

export default Results;
