import React from "react";
import ScreenSizeService from '../../services/screenSizeService';
import VideoFrame from "./videoFrame";
import VideoFileItem from "./videoFileItem";

export default function VideoItem(props) {
    const { video } = props;
    const mobile = new ScreenSizeService().isMobile();

    return (
        <div className="video">
            <div className="video_description">
                <div className="description_title">{video.title}</div>
                <div className="description_text">{video.description}</div>
                {!mobile &&
                    <div className="files_section">
                        {video.files.map((file, i) => {
                            return(
                                <VideoFileItem file={file} key={i}/>
                            )
                        })}
                    </div>
                }
            </div>
            <VideoFrame video={video}/>
            {mobile &&
                <div className="video_description">
                    <div className="files_section">
                        {video.files.map((file, i) => {
                            return(
                                <VideoFileItem file={file} key={i}/>
                            )
                        })}
                    </div>
                </div>
            }
        </div>
    )
}