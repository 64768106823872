import fetchData from '../components/hooks/getData';
import LoggerService from '../services/LoggerService';
import CachedService from '../services/CachedService';

function Service() {
    const logger = new LoggerService('UsefulRequestService');
    const permissions = sessionStorage.getItem('permissions');

    const get = async () => {
        logger.debug('Retrieving usefull request list...');
        if(permissions === 'corporate')
            var data = await fetchData(`/Request/GetUsefulRequest`, 'get', 'json');
        else
            var data = await fetchData(`/Request/hotel/GetUsefulRequest`, 'get', 'json');
        
        var requests = data && data.map(l => {
            l.url = '' !== l.url ? JSON.parse(l.url).Url : '';
            return l;
        });
        return requests;
    };

    return {
        get
    };
}

export default function UsefulRequestService(params) {
    return new CachedService({
        key: 'usefulRequests',
        service: new Service(params)
    });
};
