import React from 'react';
import { Skeleton, SkeletonCircle, SkeletonGap, SkeletonLine } from "../../components/ui/skeleton";

export default function PasswordLoading() {
    return(
        <div className="card_content">
            <div className="title"><SkeletonLine width={"130px"} height={'20px'}/></div>
            <div className="content">
                <div className="password_chart">
                    <div className="chart">
                        <Skeleton justify="center">
                            <SkeletonCircle width={'150px'}/>
                        </Skeleton>
                    </div>
                </div>
                <div className="password_info">
                    <Skeleton flex="column">
                        <SkeletonLine width={"180px"} height={'25px'}/>
                        <SkeletonLine width={"100px"} height={'15px'}/>
                        <SkeletonLine width={"180px"} height={'25px'}/>
                        <SkeletonLine width={"100px"} height={'15px'}/>
                        <SkeletonGap height={'1px'}/>
                        <SkeletonLine width={"180px"} height={'20px'}/>
                    </Skeleton>
                </div>
            </div>
        </div>
    )
}