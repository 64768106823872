import fetchData from '../components/hooks/getData';
import LoggerService from '../services/LoggerService';
import CachedService from '../services/CachedService';

function Service({ token, permissions }) {
    const logger = new LoggerService('Links');

    const get = async () => {
        logger.debug('Retrieving links list...');
        if(permissions === 'corporate')
            var data = await fetchData(`Link/GetLinks`, 'get', 'json', token);
        else
            var data = await fetchData(`Link/hotel/GetLinks`, 'get', 'json', token);
        const links = data && data.map(link => {
            link.icon = link.iconType !== ".svg" 
                ? `data:image/png;base64,${link.icon}` 
                : link.icon;
            link.linkGlobal = link.linkGlobal !== ''
                ? JSON.parse(link.linkGlobal).Url
                : link.linkGlobal;
            link.linkEMEA = link.linkEMEA !== ''
                ? JSON.parse(link.linkEMEA).Url
                : link.linkEMEA;
            link.linkAPAC = link.linkAPAC !== ''
                ? JSON.parse(link.linkAPAC).Url
                : link.linkAPAC;
            return link;
        });
        return links;
    };

    return {
        get
    };
}

export default function LinksService(params) {
    return new CachedService({
        key: 'departments',
        service: new Service(params)
    });
};