import React, {useState} from "react";

export default function LoadingPage() {
    const loadingTitle = "Loading content";
    const [loadingSubtitle, setLoadingSubtitle] = useState("Please wait.");

    setTimeout(() => {
        setLoadingSubtitle("Still loading, please be patient.");
    }, 10000);

    return (
        <div className="loading_page">
            <h1 className="loading_title">{loadingTitle}
                <div className="dots">
                    <div className="dot"></div>
                    <div className="dot"></div>
                    <div className="dot"></div>
                </div>
            </h1>
            <h3 className="loading_subtitle">{loadingSubtitle}</h3>
        </div>
    );
}