export default function ErrorService() {

    const pairs = window.location.search.substring(1).split('&').map(p => p.split('='));
    const messageKey = 'm';
    const stackTraceKey = 's';

    const getQueryStringValue = (key) => {
        try {
            return pairs.filter(p => p[0] === key).pop()[1];
        }
        catch {
            return null;
        }
    };

    const get = () => {
        return {
            message: getQueryStringValue(messageKey),
            stack: getQueryStringValue(stackTraceKey)
        };
    };

    return { get };
}