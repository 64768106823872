import { React, useEffect } from "react";
import ChevronRightIcon from "../../components/icons/chevronRight";

export default function TopStoriesControls(props) {
    const { stories, activeIndex, setActiveIndex, carouselRef, activeArrows} = props;

    const updateIndex = (newIndex) => {
        if(carouselRef && carouselRef.current){
            let itemWidth = carouselRef.current.children[0].offsetWidth;
            carouselRef.current.scrollTo({left: (itemWidth * newIndex), top: 0, behavior: 'smooth'});
            setActiveIndex(newIndex);
        }
    }

    useEffect(() => {
        const interval = setInterval(() => {
            if (activeIndex + 1 === stories.length)
                updateIndex(0);
            else
                updateIndex(activeIndex + 1)
        }, 7000);

        return () => clearInterval(interval);
    }, [activeIndex]);

    return(
        <>
            {activeArrows &&
                <div className="direction_arrows">
                    <div className={`arrow left ${activeIndex === 0 && 'disabled'}`} 
                        onClick={activeIndex === 0 ? null : () => updateIndex(activeIndex - 1)}>
                        <ChevronRightIcon />
                    </div>
                    <div className={`arrow right ${(activeIndex + 1) === stories.length && 'disabled'}`} 
                        onClick={(activeIndex + 1) === stories.length ? null : () => updateIndex(activeIndex + 1)}>
                        <ChevronRightIcon />
                    </div>
                </div>
            }
            <div className="controls">
                {Array(stories.length).fill().map((_, i) => (
                    <div className={`control ${activeIndex === i ? 'active' : ''}`} key={i} onClick={() => updateIndex(i)}></div>
                ))}
            </div>
        </>
    )
}