import React from "react";
import FormIcon from "../../components/icons/form";
import fetchData from "../../components/hooks/getData";

export default function RecentFormsListItem(props) {
    const { form } = props;

    const handleFormClick = (formId) => {
        fetchData(`/Request/PostRequestClicked?id=${formId}`, 'post', 'json')
    }

    return(
        <div className='form'>
            <a className="wrapper" href={form.url ? form.url : '/'} target="_blank" rel="noreferrer" onClick={() => handleFormClick(form.idRequest)}>
                <div className="icon">
                    <FormIcon />
                </div>
                <div className="text">
                    <span className="departement">{form.department}</span>
                    <span className="title">{form.titleRequest}</span>
                </div>
            </a>
        </div>
    )
}