import React from "react";
import DirectoryListItem from './directoryListItem';

function Results(props) {
    const { data } = props;

    return (
        <div className="results">
            <div className="header">
                <div className="subfolder">
                    <span className="title">SubFolders</span>
                </div>
                <div className="region">
                    <span className="title">Region</span>
                </div>
                <div className="department">
                    <span className="title">Department</span>
                </div>
                <div className="link">
                    <span className="title">New SharePoint Link</span>
                </div>
                <div className="hotel">
                    <span className="title">hotelkit Link</span>
                </div>
            </div>
            <div className="body">
                {data.length > 0 && data.map((directory, index) => {
                    return (
                        <DirectoryListItem directory={directory} key={index}/>
                    )
                })}
            </div>
        </div>
    )
} export default Results;