import React, { useState, useRef } from 'react';
import { useMsal } from "@azure/msal-react";
import { NavLink } from "react-router-dom";
import { Skeleton, SkeletonLine } from '../../components/ui/skeleton';
import useClickOutsideHook from '../hooks/clickOutsideHook';

const avatar = '../../assets/img/avatar.png';

function Profile(props){
    const [profileStyles, setProfileStyles] = useState('');
    const { instance } = useMsal();
    const { data } = props;

    const profileRef = useRef(null);
    const profileMenuRef = useRef(null);

    const openProfileMenu = () => {
        profileStyles === '' ? setProfileStyles('active') : setProfileStyles('');
    }

    function handleLogout() {
        const domain = window.location;
        const COOKIE_NAME = 'adfs_token'

        sessionStorage.clear();
        document.cookie = COOKIE_NAME +'=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
        
        instance.logoutRedirect({
            postLogoutRedirectUri: domain,
        });
    }

    useClickOutsideHook(profileRef, profileMenuRef, () => setProfileStyles(''));

    return (
        <>
            {data ?
                <li className='tool_profile' ref={profileRef}>
                    <div className={`profile_block ${profileStyles}`} onClick={() => openProfileMenu()}>
                        <img src={data.image ? `data:image;base64,${data.image}` : avatar} alt={data ? data.displayName : ''} />
                    </div>
                </li>
                :
                <li className='tool_profile' ref={profileRef}>
                    <Skeleton>
                        <SkeletonLine width={'100%'} height={'100%'}/>
                    </Skeleton>
                </li>
            }
            {data &&
                <div className={`profile_menu ${profileStyles}`} ref={profileMenuRef}>
                    <div className='menu_wraper'>
                        <div className='menu'>
                            <div className='menu_header'>
                                <span>Radisson Hotel Group</span>
                                <div className='logout' onClick={() => handleLogout()}>Sign Out</div>
                            </div>
                            {data &&
                                <div className='menu_content'>
                                    <div className='profile_avatar'>
                                        <img src={data.image ? `data:image;base64,${data.image}` : avatar} alt={data ? data.displayName : ''}/>
                                    </div>
                                    <div className='user_info'>
                                        <span className='name'>{data.displayName}</span>
                                        <span className='email'>{data.mail}</span>
                                        <NavLink to="/dashboard" exact="true" onClick={() => openProfileMenu()}>
                                            <span className='profile'>View My Dashboard</span>
                                        </NavLink>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            }
        </>
    )

} export default Profile;