import React from "react";
import { Icon } from '@fluentui/react/lib/Icon';
import { getFileTypeIconProps, FileIconType } from '@fluentui/react-file-type-icons';

export default function TopGuideItem(props) {
    const { guide } = props;

    return (
        <div className='guide'>
            <a className="wrapper" href={guide.url} target="_blank" rel="noreferrer">
                <div className="icon">
                    <Icon {...getFileTypeIconProps({extension: guide.extension, type: FileIconType.docset, size: 32, imageFileType: 'svg' }) } />
                </div>
                <div className="text">
                    <span className="type">{guide.type}</span>
                    <span className="title">{guide.title}</span>
                </div>
            </a>
        </div>
    )
}