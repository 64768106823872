import React from "react";
import ChevronRightIcon from '../../components/icons/chevronRight';

export default function TopStoriesItem(props) {
    const { story } = props;
    let storyLink = story.linkGlobal ? story.linkGlobal : (story.linkAPAC ? story.linkAPAC : story.linkEMEA);

    return(
        <li className="slide">
            <div className="image">
                <img src={story.imageBase64} alt={story.departmentName} draggable="false"/>
            </div>
            <div className="content">
                <h2 className="title_content">{story.departmentName}</h2>
                <span className="description">{story.description}</span>
                <a className="see_more" href={storyLink} target="_blank" rel="noreferrer">Check out for more <ChevronRightIcon /></a>
            </div>
        </li>
    )
}