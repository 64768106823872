import React from "react";
import { SkeletonLine } from "../../components/ui/skeleton";

export default function LoadingTopGuides() {

    return (
        <div className='guides_documents'>
            {Array(3).fill().map((_, i) => {
                return (
                    <div className='guide' key={i}>
                        <div className="wrapper">
                            <div className="icon">
                                <SkeletonLine width='60px' height='60px' />
                            </div>
                            <div className="text">
                                <SkeletonLine width='80px' height='25px' />
                                <SkeletonLine width='180px' height='20px' />
                            </div>
                        </div>
                    </div>
                )
            })}
        </div>
    )
}