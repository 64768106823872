import React, { useState, useEffect } from 'react';
import PageLayout from '../components/layout/pageLayout';
import HelpGuidesService from './helpGuidesService';
import TopGuides from './components/topGuides';
import VideoGuides from './components/videoGuides';
import '../styles/help.scss';

const BannerImg = '/assets/banners/sharepoint_banner.jpg';

export default function HelpGuidesPage() { 
    const [guides, setGuides] = useState(null);
    const [videos, setVideos] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const helpGuidesService = new HelpGuidesService();
        (async () => {
            const data = await helpGuidesService.get();
            setGuides(data.guides);
            setVideos(data.videos);
            setLoading(false);
        })();
    }, []);

    return (
        <PageLayout windowTitle="Help Guides" title="Help Guides" image={BannerImg} filter="0.2">
            <TopGuides guides={guides} loading={loading}/>
            <VideoGuides videos={videos} loading={loading}/>
        </PageLayout>
    );
};