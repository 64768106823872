import React, { useState, useEffect } from 'react';
import { formatedDate } from '../../utils';
import BookingLogo from '../../components/icons/BookingLogo';
import ArrowDownIcon from '../../components/icons/arrowDown';
import BookingService from '../../booking/bookingService';
import BookingError from './bookingError';
import BookingLoading from './bookingLoading';

function Booking(){
    const [booking, setBooking] = useState(null);
    const [loading, setLoading] = useState(true);
    const [stateResult, setStateResult] = useState(null);

    useEffect(() => {
        (async () => {
            const bookingService = new BookingService();
            const bookingData = await bookingService.get();
            setBooking(bookingData);
            setLoading(false);
            setStateResult(bookingData.differencePrevious >= 0 ? 'up' : 'down');
            if(!bookingData || bookingData.error){
                setBooking(null);
                setLoading(false);
            }
        })()
    }, []);

    return(
        <>
            {booking && !loading &&
                <div className='stat booking'>
                    <div className="title">
                        <span className='title'>RHG Booking.com Score</span>
                    </div>
                    <div className='logo'>
                        <BookingLogo />
                        <div className='stat_info'>
                            <span className='points'>{booking.currentScore}</span>
                            <span className='date'>
                                {booking.calculatedDate && booking.calculatedDate !== '0' ?
                                    formatedDate(booking.calculatedDate, true) : formatedDate(new Date(), true)
                                }
                            </span>
                        </div>
                    </div>
                    <div className={`stat_results ${stateResult}`}>
                        <span className='new_points'>{booking.differencePrevious}</span>
                        <div className='new_info'>
                            <ArrowDownIcon />
                            <span>vs LY</span>
                        </div>
                    </div>
                </div>
            }
            {loading && <BookingLoading />}
            {!loading && !booking && <BookingError />}
        </>
    )
} export default Booking;