import fetchData from '../components/hooks/getData';
import LoggerService from '../services/LoggerService';
import CachedService from '../services/CachedService';

function Service() {
    const logger = new LoggerService('BestEmployer');
    const permissions = sessionStorage.getItem('permissions');

    const get = async () => {
        logger.debug('Retrieving best employer data...');
        if(permissions === 'corporate')
            var bestEmployer = await fetchData(`/BestEmployer/GetBestEmployer`, 'get', 'json');
        else
            var bestEmployer = await fetchData(`BestEmployer/Hotel/GetBestEmployer`, 'get', 'json');
        return bestEmployer;
    };

    return {
        get
    };
}

export default function BestEmployerService(params) {
    return new CachedService({
        key: 'bestemployer',
        service: new Service(params)
    });
};