import React from "react";
import { SkeletonLine } from "../../components/ui/skeleton";

export default function FrequentToolsLoading() {
    return(
        Array(3).fill().map((_, i) => (
            <div className='tool' key={i}>
                <div className="wrapper">
                    <div className="icon">
                        <SkeletonLine width="61px" height="61px"/>
                    </div>
                    <div className="text">
                        <span className="title"><SkeletonLine width="180px" height="20px"/></span>
                    </div>
                </div>
            </div>
        ))
    )
}