import React from 'react';
import WarningIcon from "../../components/icons/warning";

export default function PasswordError() {
    return(
        <div className="card_content error">
            <div className="title">
                <div className="title_name">Password</div>
            </div>
            <div className="content">
                <div className="icon"><WarningIcon /></div>
                <span className="error_text">There is no Password information available for the current user.</span>
            </div>
        </div>
    )
}