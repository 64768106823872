import React, { useState, useEffect } from 'react';
import { BookingSASService } from '../dashboardService';
import SASBookingPopupLoading from './SASBookingPopupLoading';


const Popup = ({ onClose }) => {
    const [isChecked, setChecked] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [country, setCountry] = useState(null);
    const [loading, setLoading] = useState(true);
    const [link, setLink] = useState(null);

    useEffect(() => {
        (async () => {
            const bookingSASService = new BookingSASService();
            const dataCountry = await bookingSASService.getCountry();
            
            if (dataCountry && !('status' in dataCountry)) {
                setCountry(dataCountry.country);

                const dataLink = await bookingSASService.getLink(dataCountry.country);
                if (dataLink && !('status' in dataLink)) {
                    setLink(dataLink.link);
                }
            }
            setLoading(false);
        })();
    }, []);

    const handleCheckboxChange = () => {
        setChecked(!isChecked);
    };

    const ShowPassword = () => {
        setShowPassword(!showPassword);
    };

    return (
        <div className="popup-overlay">
            {country && !loading &&
                <div className="popup">
                    <div className="popup_title">
                        <p className="title"> Rezidor employee discount on SAS flights</p>
                    </div>
                    <div className="popup_body" hidden={showPassword}>
                        <p className="body">As a Radisson employee, you can experience our SAS destinations at a reduced price. The offer gives you a discount on the net price in all service classes. The net price corresponds to the public price, minus deductions for taxes and fuel surcharge.
                            Eligible persons: All Radisson employees.
                            <br /><br /><br />
                            A maximum of 9 persons of your own choice per booking. This includes the employee who must be accompanying for the whole trip. All travellers using the SAS Staff Discount offer are required to be members of SAS EuroBonus, and the EuroBonus number has to be inserted when making the booking.
                            <br /><br /><br />
                            The fare rules for these tickets are the same as for the public fares. Please see our commercial websites for more information.
                            <br /><br />
                            For additional questions please contact SAS Sales and Service:
                            <br /><br />
                            Sweden: +46 770 727 727<br />
                            Norway: +47 21 89 6400<br />
                            Denmark: +45 70 10 20 00<br />
                            For technical support, please contact D360@sas.se<br /><br />
                            Any misuse, e.g. sharing this link to other than Radisson employees, is a breach of SAS Code of Conduct and will result in the employee losing the right to benefit from this offer. These fares are to be used for recreational purposes only, and may not benefit third party financially.
                        </p>
                        <label>
                            <input
                                type="checkbox"
                                checked={isChecked}
                                onChange={handleCheckboxChange}
                            />
                            <a href="https://rezportal.rezidor.com/library.php?item=6928853" target="_blank">I accept the <span>Terms and Conditions</span>.</a>
                        </label>
                        <div className="popup-buttons">
                            <button onClick={() => ShowPassword()} disabled={!isChecked}>
                                Submit
                            </button>
                            <button onClick={() => onClose(false)}>Close</button>
                        </div>
                    </div>
                    <div className="popup_body" hidden={!showPassword}>
                        <p className="body">
                            All travellers using the SAS Staff Discount offer are required to be members of SAS EuroBonus, and the EuroBonus number must be inserted when making the booking.
                            <br /><br /><br />
                            You will need to use this below password when on the SAS link:
                            <span>radissonstaff1218</span>
                            <br /><br /><br />
                            Copy the password and go to the link:<br />
                            <a href={link} target="_blank"><span>{link}</span></a>

                        </p>
                        <div className="popup-buttons">
                            <button onClick={() => onClose(false)}>Close</button>
                        </div>
                    </div>
                </div>
            }
            {loading && !country && <SASBookingPopupLoading />}
        </div>
    );
};

export default Popup;
