import React, { useEffect, useState } from "react";
import { formatedDate } from '../../utils';
import FormIcon from "../../components/icons/form";
import fetchData from "../../components/hooks/getData";

export default function RadissonRequestListItem(props){
    const [isInternal, setIsInternal] = useState(false);
    const { form } = props;

    const handleFormClick = (formId) => {
        fetchData(`/Request/PostRequestClicked?id=${formId}`, 'post', 'json')
    }

    useEffect(() => {
        if(form && form.url){
            setIsInternal(form.url.includes('radissonconnects.com') ? true : false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return(
        <a className="form_item" href={form.url ? form.url : '/'} target={`${isInternal ? '' : '_blank'}`} rel="noreferrer"
            onClick={() => handleFormClick(form.idRequest)}>
            <div className="item_icon">
                <FormIcon/>
            </div>
            <div className="item_content">
                <span className="title">{form.titleRequest}</span>
                <div className="content">
                    <span className="label">Version {form.version ? form.version : '0.0.1'}</span>
                    <span className="label">Updated {formatedDate(form.lastUpdate)}</span>
                </div>
            </div>
        </a>
    )
}