import React, { useEffect, useState } from "react";
import { FrequentToolsService } from '../toolsAndResourcesService';
import FrequentToolItem from "./frequentToolItem";
import FrequentToolsLoading from "./frequentToolsLoading";

export default function FrequentTools() {
    const [frequentTools, setFrequentTools] = useState(null);
    const [loading, setLoading] = useState(true);
    const frequentToolsService = new FrequentToolsService();

    useEffect(() => {
        (async () => {
            const data = await frequentToolsService.get();
            setFrequentTools(data);
            setLoading(false);
        })();
    }, []);

    const isLoading = loading && !frequentTools;
    const isError = !loading && !frequentTools;

    return(
        <div className='frequently_used_area'>
            <div className='frequents'>
                <div className='area_title'>
                    <span className='title'>Frequently Used Tools</span>
                    <span className='sub_title'>Quick access to your most used tools and external resources.</span>
                </div>
                <div className='frequent_tools'>
                    {!isLoading && frequentTools.map((tool, index) => {
                        return (
                            <FrequentToolItem tool={tool} key={index}/>
                        )
                    })}
                    {isLoading && <FrequentToolsLoading />}
                    {isError &&
                        <div className='no_tools'>
                            <span className='title'>You have not used any tool yet</span>
                            <span className='subtitle'>You will see your most frequently used tools here when you start using them.</span>
                        </div>
                    }
                </div>
            </div>
        </div>
    )
}