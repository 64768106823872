import React, { useEffect, useState } from "react";
import ToolsAndResourcesList from "./toolsList";
import ToolsAndResourcesSearch from "./toolSearch";
import { ToolsAndResourcesService } from "../toolsAndResourcesService";

export default function ToolsAndResources() {
    const [listStyle, setListStyle] = useState('list');
    const [sort, setSort] = useState('asc');
    const [tools, setTools] = useState([]);
    const [loading, setLoading] = useState(true);
    const [filteredData, setFilteredData] = useState(tools);

    const getTools = async () => {
        const toolsService = new ToolsAndResourcesService();
        const tools = await toolsService.get();
        if(tools && tools.length > 0) {
            setLoading(false);
            setTools(tools);
            setFilteredData(tools);
        }
    };

    useEffect(() => {
        getTools();
    }, []);

    return(
        <div className="resources_area">
            <div className="area_title">
                <span className="title">All tools and resources at a hand</span>
            </div>
            <ToolsAndResourcesSearch sort={sort} setSort={setSort} listStyle={listStyle} setListStyle={setListStyle} 
                setFilteredData={setFilteredData} tools={tools}/>
            <ToolsAndResourcesList listStyle={listStyle} sort={sort} filteredData={filteredData} loading={loading}/>
        </div>
    )
}