import React, { useState, useEffect } from "react";
import { dynamicSort } from '../../utils';
import { RadissonRequestService } from '../radissonRequestService';
import RadissonRequestSearch from './radissonRequestSearch';
import RadissonRequestListItem from './radissonRequestListItem';
import RadissonRequestLoading from './radissonRequestLoading';

function RequestForms(){
    const [forms, setForms] = useState();
    const [filteredData, setFilteredData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [sort, setSort] = useState('asc');

    useEffect(() => {
        const radissonRequestService = new RadissonRequestService();
        (async () => {
            const data = await radissonRequestService.get();
            setForms(data);
            setFilteredData(data);
            setLoading(false);
        })();
    }, []);

    const departements = filteredData.map(a => a.department);

    const finalDepartements = departements.filter((value, index, self) =>
        index === self.findIndex((t) => (
            t === value
        ))
    )

    return (
        <div className="forms_area">
            <div className="area_title">
                <span className="title">Don't miss a request form</span>
            </div>
            <RadissonRequestSearch forms={forms} setFilteredData={setFilteredData} sort={sort} setSort={setSort}/>
            <div className="forms_list">
                {!loading && finalDepartements && finalDepartements.sort().map((departement, index) => {
                    return (
                        <div className="form_group" key={index}>
                            <div className="group_title">
                                <span className="title">{departement}</span>
                            </div>
                            <div className="group_forms">
                                {filteredData.sort(dynamicSort(sort === 'asc' ? 'titleRequest' : '-titleRequest')).map((form, index) => (
                                    form.department === departement && <RadissonRequestListItem form={form} key={index}/>
                                ))}        
                            </div>
                        </div>
                    )
                })}
                {loading && <RadissonRequestLoading />}
            </div>
        </div>
    )
} export default RequestForms;