import React from 'react';
import { NavLink } from "react-router-dom";
import HelpIcon from '../icons/help';
import ChevronRightIcon from '../icons/chevronRight';

function HelpMenu(){
    const permissions = sessionStorage.getItem('permissions');

    return (
        <>
            <li className='tool_help'>
                <HelpIcon />
                <div className={`help_menu ${permissions === 'hotel' && 'short'}`}>
                    <div className='menu'>
                        <NavLink to="/help-guides" exact="true" className="menu_item">
                            <span>Help Guides</span><ChevronRightIcon />
                        </NavLink>
                        {permissions === 'corporate' &&
                            <NavLink to="/directory" exact="true" className="menu_item">
                                <span>SharePoint & hotelkit Directory</span><ChevronRightIcon />
                            </NavLink>
                        }
                    </div>
                </div>
            </li>
        </>
    )

}; export default HelpMenu;