import React from "react";
import { SkeletonLine } from "../../components/ui/skeleton";

export default function QuickLinksLoading() {
    return(
        <div className="cards">
            {Array(14).fill().map((_, i) => (
                <div className={`link image`} key={i}>
                    <SkeletonLine width="160px" height="40px"/>
                </div>
            ))}
        </div>
    )
}