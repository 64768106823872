import 'bootstrap/dist/css/bootstrap.css';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { initializeFileTypeIcons } from '@fluentui/react-file-type-icons';
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { msalConfig } from "./auth/authConfig";
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import App from './App';
import './styles/index.scss';

const msalInstance = new PublicClientApplication(msalConfig);
const rootElement = document.getElementById('root');
const root = createRoot(rootElement);

root.render(
    <MsalProvider instance={msalInstance}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </MsalProvider>);

serviceWorkerRegistration.unregister();
initializeFileTypeIcons();