import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import ChevronRightIcon from "../../components/icons/chevronRight";
import UsefulRequestService from "../usefulRequestService";
import UsefulRequestsListItem from './usefulRequestsListItem';

function UsefullRequests() {
    const sessionQuickLinks = JSON.parse(sessionStorage.getItem('usefulRequests'));
    const [requests, setRequests] = useState(null);
    const usefulRequestService = new UsefulRequestService();


    useEffect(() => {
        if(sessionQuickLinks && sessionQuickLinks.length > 0) {
            setRequests(sessionQuickLinks);
        }else{
            (async () => {
                const data = await usefulRequestService.get();
                setRequests(data);
            })();
        }
    }, []);

    return (
        <div className="wrapper">
            <div className="block_title">
                <h2>Useful Requests</h2>
            </div>
            <div className="requests_list">
                <div className="list">
                    { requests && requests.filter && requests.filter((link) => {return link.showUsefullRequest === true}).map((request, i) => (
                        <UsefulRequestsListItem request={request} key={i}/>
                    ))}
                </div>
                <div className="see_more">
                    <NavLink to="/radisson-request" exact="true">See all Radisson requests <ChevronRightIcon /></NavLink>
                </div>
            </div>
        </div>
    );
} export default UsefullRequests;