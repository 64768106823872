import fetchData from '../components/hooks/getData';
import LoggerService from '../services/LoggerService';
import CachedService from '../services/CachedService';

function Service() {
    const logger = new LoggerService('Booking');
    const permissions = sessionStorage.getItem('permissions');

    const get = async () => {
        logger.debug('Retrieving booking data...');
        if(permissions === 'corporate')
            var booking = await fetchData(`/Booking/GetBooking`, 'get', 'json');
        else
            var booking = await fetchData(`Booking/Hotel/GetBooking`, 'get', 'json');
        return booking;
    };

    return {
        get
    };
}

export default function BookingService(params) {
    return new CachedService({
        key: 'booking',
        service: new Service(params)
    });
};