import React, { useEffect, useRef, useState } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import ScreenSizeService from '../services/screenSizeService';
import SearchHome from './components/search';
import UserInfo from './components/userInfo';
import Booking from '../booking/components/booking';
import BestEmployer from '../bestemployer/components/bestEmployer';
import TopStories from '../topStories/components/topStories';
import QuickLinksList from '../toolsAndResources/components/quickLinksList';
import UsefullRequests from '../radissonRequest/components/usefulRequestsList';
import LastDocuments from './components/lastDocuments';
import '../styles/home.scss';

export default function HomePage(props) {
    const { userData, permissions } = props;
    const [height, setHeight] = useState(null);
    const mobile = new ScreenSizeService().isMobile();

    const linksRef = useRef(null);

    useEffect(() => {
        if (linksRef.current && !mobile) {
            const handleWindowResize = () => {
                setHeight(`${linksRef.current.clientHeight}px`);
            };
            setHeight(`${linksRef.current.clientHeight}px`);
            window.addEventListener('resize', handleWindowResize);
            return () => {
                window.removeEventListener('resize', handleWindowResize);
            };
        }
        if(mobile){
            setHeight('auto');
        }
    }, [linksRef, mobile]);

    return (
        <HelmetProvider>
            <Helmet>
                <title>Radisson Connects | Home</title>
            </Helmet>
            <div className='home_warpper'>
                <div className='banner'>
                    <div className='content_layer'>
                        <div className='text_block'>
                           <UserInfo info={userData} /> 
                        </div>
                    </div>
                </div>
                <div className='banner_search'>
                    <SearchHome />
                    <div className='home_stats'>
                        <Booking />
                        <BestEmployer />
                    </div>
                </div>
                <div className='home_content'>
                    <div className='stories'>
                        <TopStories style={{height: height}}/>
                        <QuickLinksList ref={linksRef}/>
                    </div>
                    <div className={`requests ${permissions === 'hotel' ? 'last_item' : ''}`}>
                        <UsefullRequests />
                    </div>
                    {permissions === 'corporate' &&
                        <div className='documents'>
                            <LastDocuments />
                        </div>
                    }
                </div>
            </div>
        </HelmetProvider>
    );
};