import fetchData from '../components/hooks/getData';
import LoggerService from '../services/LoggerService';
import CachedService from '../services/CachedService';

function Service() {
    const logger = new LoggerService('RadissonRequestService');
    const permission = sessionStorage.getItem('permissions');

    const get = async () => {
        logger.debug('Retrieving radisson request list...');
        if(permission === 'corporate')
            var data = await fetchData(`/Request/GetRequest`, 'get', 'json');       
        else
            var data = await fetchData(`/Request/hotel/GetRequest`, 'get', 'json');
        var requests = data && data.map(l => {
            l.url = '' !== l.url ? JSON.parse(l.url).Url : '';
            return l;
        });
        return requests;
    };

    return {
        get
    };
}

export function RadissonRequestService(params) {
    return new CachedService({
        key: 'requests',
        service: new Service(params)
    });
};

export function RecentRequestService() {
    const logger = new LoggerService('RecentRequestService');
    const permission = sessionStorage.getItem('permissions');

    const get = async () => {
        logger.debug('Retrieving recent request list...');
        if(permission === 'corporate')
            var data = await fetchData(`/Request/GetMostFrequentlyRequest`, 'get', 'json');
        else
            var data = await fetchData(`/Request/hotel/GetMostFrequentlyRequest`, 'get', 'json');
        var requests = data && data.map(l => {
            l.url = '' !== l.url ? JSON.parse(l.url).Url : '';
            return l;
        });
        return requests;
    };

    return {
        get
    };
}
