import React from "react";
import { Skeleton, SkeletonGap, SkeletonLine } from "../../components/ui/skeleton";

function LoadingResults(){
    return (
        <div className='search_results'>
            <Skeleton flex="column">
                <SkeletonLine width="350px" height="25px" />
                <SkeletonGap width="10px" height="10px" />
                <Skeleton>
                    <SkeletonLine width="150px" height="20px" />
                    <SkeletonLine width="150px" height="20px" />
                    <SkeletonLine width="150px" height="20px" />
                </Skeleton>
                <SkeletonGap width="10px" height="10px" />
                <Skeleton flex="column">
                    {Array(5).fill().map((_, i) => (
                        <div key={i} style={{borderBottom: '1px solid rgb(215, 215, 215)', width: '100%', margin: '10px 0px'}}>
                            <Skeleton style={{alignItems: 'flex-start'}}>
                                <SkeletonLine width="80px" height="70px" />
                                <Skeleton flex="column">
                                    <SkeletonLine width="200px" height="20px" />
                                    <SkeletonGap width="10px" height="5px"/>
                                    <SkeletonLine width="450px" height="20px" />
                                    <SkeletonGap width="10px" height="5px"/>
                                    <Skeleton>
                                        <SkeletonLine width="450px" height="20px" />
                                        <SkeletonLine width="450px" height="20px" />
                                    </Skeleton>
                                </Skeleton>
                            </Skeleton>
                            <SkeletonGap width="10px" height="20px" />
                        </div>
                    ))}
                </Skeleton>
            </Skeleton>
        </div>
    );
} export default LoadingResults;