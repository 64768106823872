import React from "react";
import Popup from './SASBookingPopup'
import { TooltipHost, DirectionalHint } from '@fluentui/react';
import { useState } from 'react';
import '../../styles/Popup.scss';

export default function Benefit(props) {
    const { benefit } = props;

    const tooltipContent = {
        onRenderContent: () => (
            <p className="benefit_info_tooltip">
                {benefit.description}
            </p>
        )
    }

    const [isPopupVisible, setPopupVisibility] = useState(false);

    const handleTextClick = () => {
        if (!benefit.isBookingSAS) {
            window.open(benefit.url, '_blank');
        } else {
            setPopupVisibility(true);
        }
    }

    const handleClosePopup = (accepted) => {
        setPopupVisibility(false);
    }

    return (
        <div className="benefit_item">
            <TooltipHost
                tooltipProps={tooltipContent}
                calloutProps={{ isBeakVisible: false, gapSpace: 10 }}
                className='fluent_tooltip_medium'
                directionalHint={DirectionalHint.bottomCenter}
            >
                <a rel="noreferrer" draggable={false}>
                    <span className="benefit_title" onClick={handleTextClick}>{benefit.name}</span>
                </a>
            </TooltipHost>
                {isPopupVisible && <Popup onClose={handleClosePopup} />}
        </div>
    )
}