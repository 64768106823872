import React from "react";
import { SkeletonLine } from "../../components/ui/skeleton";

export default function DirectoryListLoading() {
    return(
        <div className="results">
            <div className="header">
                <div className="subfolder">
                    <span className="title"><SkeletonLine width="150px" height="20px" transparent/></span>
                </div>
                <div className="region">
                    <span className="title"><SkeletonLine width="100px" height="20px" transparent/></span>
                </div>
                <div className="department">
                    <span className="title"><SkeletonLine width="120px" height="20px" transparent/></span>
                </div>
                <div className="link">
                    <span className="title"><SkeletonLine width="150px" height="20px" transparent/></span>
                </div>
                <div className="hotel">
                    <span className="title"><SkeletonLine width="130px" height="20px" transparent/></span>
                </div>
            </div>
            <div className="body">
                {Array(10).fill().map((_, i) => {
                    return (
                        <div className="table_row" key={i}>
                            <div className="subfolder">
                                <span className="title"><SkeletonLine width="150px" height="20px"/></span>
                            </div>
                            <div className="region">
                                <span className="title"><SkeletonLine width="100px" height="20px"/></span>
                            </div>
                            <div className="department">
                                <span className="title"><SkeletonLine width="120px" height="20px"/></span>
                            </div>
                            <div className="link">
                                <span className="title"><SkeletonLine width="150px" height="20px"/></span>
                            </div>
                            <div className="hotel">
                                <span className="title"><SkeletonLine width="130px" height="20px"/></span>
                            </div>
                        </div>
                    )
                })}
            </div>
        </div>
    )
}