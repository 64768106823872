import React from "react";
import WarningIcon from "../../components/icons/warning";

export default function DirectoryListError() {
    return(
        <div className="results">
            <div className="header">
                <div className="subfolder">
                    <span className="title">SubFolders</span>
                </div>
                <div className="region">
                    <span className="title">Region</span>
                </div>
                <div className="department">
                    <span className="title">Department</span>
                </div>
                <div className="link">
                    <span className="title">New SharePoint Link</span>
                </div>
                <div className="hotel">
                    <span className="title">Hotelkit Link</span>
                </div>
            </div>
            <div className="body">
                <div className="no_results">
                    <div className="no_results_icon"><WarningIcon /></div>
                    <span className="no_results_text">Nothing found for your search, try again.</span>
                </div>
            </div>
        </div>
    )
}