import React, { useState, useRef } from 'react';
import { NavLink } from "react-router-dom";
import ScreenSizeService from '../../services/screenSizeService';
import useClickOutsideHook from '../hooks/clickOutsideHook';
import { Logo, ShortLogo } from './logo';
import Profile from './profile';
import NavigationSearch from './search';
import NavMenu from './navMenu';
import HelpMenu from './helpMenu';
import '../../styles/header.scss';

function Header(props) {
    const mdScreen = new ScreenSizeService().isMdScreen();
    const [menuStyles, setMenuStyles] = useState('');
    const navMenuRef = useRef(null);
    const navMenuButtonRef = useRef(null);
    const { userData, departments, tools, /*tasks*/ } = props;

    const openMenu = () => {
        menuStyles === 'active' ? setMenuStyles('') : setMenuStyles('active');
    };

    useClickOutsideHook(navMenuButtonRef, navMenuRef, () => setMenuStyles(''));

    return (
        <header>
            <div className='wrapper'>
                <div className='open_menu'>
                    <div className={`menu ${menuStyles}`} onClick={() => openMenu()} ref={navMenuButtonRef} id="openMenu">
                        <div className="line"></div>
                        <div className="line"></div>
                        <div className="line"></div>
                    </div>
                </div>
                <div className='logo'>
                    <NavLink to="/" exact="true">
                        {mdScreen ? <ShortLogo /> : <Logo />}
                    </NavLink>
                </div>
                <NavMenu style={menuStyles} setStyles={setMenuStyles} departments={departments} tools={tools}/>
                <div className='tools'>
                    <ul>
                        <HelpMenu />
                        {/*<Notifications data={tasks}/>*/}
                        <NavigationSearch/>
                        <Profile data={userData}/>
                    </ul>
                </div>
            </div>
        </header>
    );
};

export default Header;