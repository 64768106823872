import fetchData from '../components/hooks/getData';
import LoggerService from '../services/LoggerService';
import CachedService from '../services/CachedService';

function Service() {
    const logger = new LoggerService('QuickToolsService');

    const get = async () => {
        logger.debug('Retrieving quick tools list...');
        const data = await fetchData(`/ToolsAndResources/quick`, 'get', 'json');
        if(data) {
            var tools = data.map(t => {
                const isSvg = t.iconType === '.svg';
                t.icon = isSvg ? t.icon : 'data:image/png;base64,' + t.icon;
                return t;
            });
            return tools;
        } else {
            return null;
        }
    };

    return {
        get
    };
}

export default function QuickToolsService(params) {
    return new CachedService({
        key: 'quickTools',
        service: new Service(params)
    });
};