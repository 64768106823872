import React from 'react';
import WarningIcon from "../../components/icons/warning";

export default function BookingError() {
    return(
        <div className='stat booking'>
            <div className='error'>
                <div className="icon"><WarningIcon /></div>
                <span className="error_text">No data could be retrieved from Booking</span>
            </div>
        </div>
    )
}