import fetchData from '../components/hooks/getData';
import LoggerService from '../services/LoggerService';
import CachedService from '../services/CachedService';

function Service() {

    const logger = new LoggerService('TopStoriesService');
    const permissions = sessionStorage.getItem('permissions');
    const get = async () => {

        // Links retrieved come with two properties for each link. The value we need is the Url
        // field, that's the reason to make the following process.

        logger.debug('Retrieving Top Stories list...');
        if(permissions === 'corporate')
            var data = await fetchData(`/TopStories/GetTopStories`, 'get', 'json');
        else
            var data = await fetchData(`TopStories/hotel/GetTopStories`, 'get', 'json');
        if(data){
            var topStories = data && data.map((l, i) => {
                l.linkAPAC = '' !== l.linkAPAC ? JSON.parse(l.linkAPAC).Url : '';
                l.linkEMEA = '' !== l.linkEMEA ? JSON.parse(l.linkEMEA).Url : '';
                l.linkGlobal = '' !== l.linkGlobal ? JSON.parse(l.linkGlobal).Url : '';
                l.imageBase64 = '' !== l.imageBase64 ? 'data:image/png;base64,'+l.imageBase64 : '';
                return l;
            });
            return topStories;
        } else {
            return null;
        }
    };

    return {
        get
    };
}

export default function TopStoriesService(params) {
    return new CachedService({
        key: 'topstories',
        service: new Service(params)
    });
};