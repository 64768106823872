import React from "react";
import { TooltipHost, DirectionalHint } from '@fluentui/react';

export default function QuickLinksListItem(props) {
    const { link } = props;

    return(
        <TooltipHost
            content={link.description}
            calloutProps={{isBeakVisible: false, gapSpace: 10}}
            className='fluent_tooltip_medium'
            directionalHint={DirectionalHint.bottomCenter}
        >
            <a className={`link ${link.isImage ? 'image' : 'icon'}`} 
                href={link.url} target="_blank" rel="noreferrer" draggable={false}>
                {link.iconType !== ".svg" &&
                    <div className="link_icon">
                        <img src={link.icon} alt={link.name} className="image" draggable={false}/>
                    </div>
                }{link.iconType === ".svg" &&
                    <div className="link_icon" dangerouslySetInnerHTML={{__html: link.icon}}></div>
                }
                <div className="link_info">
                    <span className="link_title">{link.name}</span>
                </div>
            </a>
        </TooltipHost>
    )
}