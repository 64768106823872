import React from "react";
import VideoItem from "./videoItem";
import LoadingVideoGuides from "./loadingVideoGuides";

function VideoGuides(props) {
    const { videos, loading } = props;

    return (
        <div className="videos_area">
            <div className="area_title">
                <span className="title">Help Resources</span>
            </div>
            <div className="videos">
                {videos && !loading ? videos.map((video, i) => {
                    return (
                        <VideoItem video={video} key={i} />
                    )
                })
                : <LoadingVideoGuides /> }
            </div>
        </div>
    );
} export default VideoGuides;