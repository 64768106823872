import React, { useEffect, useState } from 'react';
import { Skeleton, SkeletonLine } from '../components/ui/skeleton';
import HotelKitService from '../hotelkit/hotelKitService';
import LinksService from './linksService';

function Links(props) {
    const [hotelKitLinks, setHotelKitList] = useState([]);
    const [hotelKitLoading, setHotelKitLoading] = useState(true);
    const [links, setLinks] = useState([]);
    const [loading, setLoading] = useState(true);
    const { permissions } = props;

    useEffect(() => {
        const hotelKitService = new HotelKitService(props);
        const linksService = new LinksService(props);

        Promise.all([
            // HotelKit links
            (async () => {
                const hotelkitLinks = await hotelKitService.get();
                setHotelKitList(hotelkitLinks);
                setHotelKitLoading(false);
            })(),
            // Department links
            (async () => {
                const links = await linksService.get();
                setLinks(links);
                setLoading(false);
            })()
        ]);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className='quick_search'>
            <div className='title'>
                <h1>Quick Links</h1>
            </div>
            <div className='cards_wrapper'>
                {permissions === 'corporate' &&
                    <div className='cards_section'>
                        <div className='title'>
                            {loading ? <SkeletonLine width='350px' height='25px' /> : <span>Departments in SharePoint</span>}
                        </div>
                        <div className={`cards ${loading ? 'loading' : ''}`}>
                            {!loading && links && links.length > 0 ? links.map((item, i) => {
                                return (
                                    <div className='card_item' key={i}>
                                        <div className='wrapper'>
                                            <div className='card_title'>
                                                {item.iconType === ".png" &&
                                                    <div className='icon png'>
                                                        <img src={item.icon} alt={item.name} className="image" draggable={false}/>
                                                    </div>
                                                }{item.iconType === ".svg" &&
                                                    <div className="icon svg" dangerouslySetInnerHTML={{__html: item.icon}}></div>
                                                }
                                                <div className='title'>
                                                    <span>{item.name}</span>
                                                </div>
                                            </div>
                                            <div className='links'>
                                                {!item.linkGlobal ?
                                                    <>
                                                        {item.linkAPAC && <a href={item.linkAPAC} target="_blank" rel="noreferrer">APAC</a>}
                                                        {item.linkAPAC && item.linkEMEA && <span>|</span>}
                                                        {item.linkEMEA && <a href={item.linkEMEA} target="_blank" rel="noreferrer">EMEA</a>}
                                                    </>
                                                    :
                                                    <a href={item.linkGlobal} target="_blank" rel="noreferrer">Global</a>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                            :
                            Array(9).fill().map((_, i) => (
                                <div className='card_item' key={i}>
                                    <div className='wrapper'>
                                        <div className='card_title'>
                                            <Skeleton>
                                                <SkeletonLine width='65px' height='50px' />
                                                <Skeleton flex="column">
                                                    <SkeletonLine width='200px' height='20px' />
                                                    <SkeletonLine width='100px' height='20px' />
                                                </Skeleton>
                                            </Skeleton>
                                        </div>
                                        <div className='links'>
                                            <SkeletonLine width='100px' height='20px' />
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                }
                <div className='cards_section'>
                    <div className='title'>
                        {loading ? <SkeletonLine width='200px' height='25px' /> : <span>hotelkit</span>}
                    </div>
                    <div className={`cards ${hotelKitLoading ? 'loading' : ''} ${permissions}`}>
                        { !hotelKitLoading && hotelKitLinks && <HotelKitList links={ hotelKitLinks }/> }
                        { hotelKitLoading && <HotelKitListItemSkeleton /> }
                    </div>
                </div>
            </div>
        </div>
    );
}

function HotelKitList({ links }) {
    return links.map((l, index) => <HotelKitListItem key={ index } item={ l } />);
}

function HotelKitListItem({ item }) {
    
    const { link, iconType } = item;

    return (<div className='card_item image'>
        <a href={ link } target="_blank" rel="noreferrer">
            { iconType === '.svg' && <SvgIcon item={ item } /> }
            { iconType !== '.svg' && <PngImage item={ item } /> }
        </a>
    </div>);
}

function HotelKitListItemSkeleton() {
    return (
        <>
            <div className='card_item image'>
                <div className='wrapper'>
                    <SkeletonLine width='200px' height='80px' />
                </div>
            </div>
            <div className='card_item image'>
                <div className='wrapper'>
                    <SkeletonLine width='200px' height='80px' />
                </div>
            </div>
        </>
    );
}

function PngImage({ item }) {
    return (<div className='icon png'>
        <img src={item.icon} alt={item.name} className="image" draggable={false}/>
    </div>);
}

function SvgIcon({ item }) {
    return (<div className='icon png'>
        <div className="icon svg" dangerouslySetInnerHTML={{__html: item.icon}}></div>
    </div>);
}

export default Links;