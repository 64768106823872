import React from "react";
import { Icon } from '@fluentui/react/lib/Icon';
import { getFileTypeIconProps, FileIconType } from '@fluentui/react-file-type-icons';
import { formatedDate } from '../../utils';

export default function VideoFileItem(props) {
    const { file } = props;

    return(
        <a className="file" href={file.url} target="_blank" rel="noreferrer">
            <div className="icon">
                <Icon {...getFileTypeIconProps({extension: file.extension, type: FileIconType.docset, size: 32, imageFileType: 'svg' }) } />
            </div>
            <div className="file_info">
                <div className="file_title">{file.title}</div>
                <div className="short_info">
                    <span className="version">Version {file.version}</span>
                    <span className="updated">Updated {formatedDate(file.lastUpdated)}</span>
                </div>
            </div>
        </a>
    )
}