import React, { useState } from "react";
import WarningIcon from "../../components/icons/warning";
import ChevronDownIcon from "../../components/icons/chevronDown";
import ErrorService from "../../services/ErrorService";
import '../../styles/error.scss';

function ErrorPage(props) {
    const errorManager = new ErrorService();
    const error = props.erorr ? props.error : errorManager.get();
    const [isOpen, setIsOpen] = useState(false);

    let location = window.location.origin;
    const cookies = document.cookie.split(";");

    const handleClick = () => {
        sessionStorage.clear();
        for (let i = 0; i < cookies.length; i++) {
            const cookie = cookies[i];
            const eqPos = cookie.indexOf("=");
            const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
            document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
        }
        window.open(location, '_self');
    }

    const handleOpen = () => setIsOpen(!isOpen);

    return (
        <main className='main_error'>
            <div className='error'>
                <div className="wrapper">
                    <div className='error_icon'><WarningIcon /></div>
                    <div className="title_wrapper">
                        <span className='title'>Error</span>
                        <span className='subtitle'>{ error.message }</span>
                        <section className="acordion_section">
                            <div className={`acordion_item ${isOpen && 'open'}`} onClick={handleOpen}>
                                <span className="title">
                                    See all details <ChevronDownIcon />
                                </span>
                                <div className="details">
                                    <p>
                                        { error.stack }
                                    </p>
                                </div>
                            </div>
                        </section>
                    </div>
                    <div className='error_btn'>
                        <span onClick={() => handleClick()}>Try Again</span>
                    </div>
                </div>
            </div>
        </main>
    );
} export default ErrorPage;