export const getInitials = (string) => {
    var noSpecialChars = string.replace(/[&\/\\#,+()$~%.'":*?<>{}_]/g,'').trim(); //eslint-disable-line
    var names = noSpecialChars.split(' '),
        initials = names[0].substring(0, 1).toUpperCase();
    
    if (names.length > 1) {
        initials += names[names.length - 1].substring(0, 1).toUpperCase();
    }
    return initials;
};

export const getExtension = (url) => {
    let trimedUrl = url.split(/[#?]/)[0].split('.').pop().trim();
    if (!trimedUrl.includes('/') && trimedUrl.length < 6 && trimedUrl === trimedUrl.toLowerCase()) {
        return url.split(/[#?]/)[0].split('.').pop().trim();
    } else {
        return undefined
    }
};

export function dynamicSort(property) {
    var sortOrder = 1;
    if(property[0] === "-") {
        sortOrder = -1;
        property = property.substr(1);
    }
    return function (a,b) {
        var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
        return result * sortOrder;
    }
};

export function formatedDate(date, monthName){
    const dateObj = new Date(date);
    const formatoptions = monthName ? {day: '2-digit', month: 'short', year: 'numeric'} : {day: '2-digit', month: '2-digit', year: 'numeric'};
    return(
        new Intl.DateTimeFormat('en-UK', formatoptions).format(dateObj)
    )
};

export function getFileType(ext) {
    switch (ext) {
        case 'pdf':
            return 'PDF';
        case 'doc':
        case 'docx':
            return 'Word';
        case 'dotx':
            return 'Word Template';
        case 'xls':
        case 'xlsx':
            return 'Excel';
        case 'xltx':
            return 'Excel Template';
        case 'ppt':
        case 'pptx':
            return 'PPT';
        case 'potx':
            return 'PPT Template';
        case 'ppsx':
            return 'PPT Slideshow';
        case 'one':
            return 'One Note';
        case 'onetoc':
            return 'One Note Table';
        case 'csv':
            return 'CSV';
        case 'mpp':
            return 'Project';
        case 'mpt':
            return 'Project Template';
        case 'vsdx':
            return 'Visio';
        case 'vssx':
            return 'Visio Stencil';
        case 'vstx':
            return 'Visio Template';
        case 'pub':
            return 'Publisher';
        case 'xsn':
            return 'InfoPath';
        default:
            return 'File';
    }
}