import React from 'react';
import PageLayout from '../components/layout/pageLayout';
import RecentForms from './components/recentFormsList';
import RequestForms from './components/radissonRequestList';
//import Tasks from "./components/tasks";
import '../styles/request.scss';

const BannerImg = '/assets/banners/request_banner.jpg';

function RadissonRequestPage() {
    
    return (
        <PageLayout windowTitle="My Radisson Request" title="My Radisson Request" image={BannerImg} filter="0.35">
            <RecentForms />
            {/*<Tasks />*/}
            <RequestForms />
        </PageLayout>
    );
} export default RadissonRequestPage;