import React, { useState } from "react";
import { useNavigate } from 'react-router-dom';
import fetchData from "../../components/hooks/getData";
import ConsentFormError from "./formError";

export default function ConsentFormText(props) {
    const [consent, setConsent] = useState(false);
    const [sending, setSending] = useState(false);
    const [error, setError] = useState(false);
    const [btnText, setBtnText] = useState('Send');
    const { data } = props;
    const navigate = useNavigate();
    const permission = sessionStorage.getItem('permissions');

    function sendForm(e){
        e.preventDefault();
        setSending(true);
        if (permission === 'corporate') {
            fetchData(`/consent/sign?version=${data.version}`, 'post', 'json').then((res) => {
                console.log(res);
                setSending(false);
                setBtnText('Sent');
                setTimeout(() => {
                    navigate('/');
                }, 1000);
            })
                .catch(err => {
                    console.error('>>> ERROR');
                    console.log(err);
                    setSending(false);
                    setError(true);
                });
        } else {
            fetchData(`/consent/hotel/sign?version=${data.version}`, 'post', 'json').then((res) => {
                console.log(res);
                setSending(false);
                setBtnText('Sent');
                setTimeout(() => {
                    navigate('/');
                }, 1000);
            })
                .catch(err => {
                    console.error('>>> ERROR');
                    console.log(err);
                    setSending(false);
                    setError(true);
                });
        }
    }

    return(
        <div className="consent_wrapper">
            <section className="consent_text">
                <p className="text" dangerouslySetInnerHTML={{__html: data.richText}}></p>
                <div className="consent_form">
                    <div className="checkbox_wrapper">
                        <input 
                            type='checkbox' 
                            name="consent" checked={consent} 
                            onChange={(e) => setConsent(e.target.checked)}
                        />
                        <span className="checkmark"></span>
                        <div className="label">I consent.</div>
                    </div>
                    <div className="send_button">
                        <button className={`btn ${consent && 'active'}`} onClick={consent ? (e)=> sendForm(e) : null}>
                            {sending && 
                                <div className="loader">
                                    Sending
                                    <div className="dots">
                                        <div className="dot"></div>
                                        <div className="dot"></div>
                                        <div className="dot"></div>
                                    </div>
                                </div>}
                            {!sending && btnText}
                        </button>
                        {error && <ConsentFormError />}
                    </div>
                </div>
            </section>
        </div>
    )
}