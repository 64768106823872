import React from "react";
import WarningIcon from "../../components/icons/warning";

export default function ConsentFormError() {
    return(
        <div className="consent_error">
            <div className="icon">
                <WarningIcon />
            </div>
            <div className="text">
                Someting went wrong signing the document. Please try again later.
            </div>
        </div>
    )
}