import React from "react";
import fetchData from "../../components/hooks/getData";
import BriefcaseIcon from "../../components/icons/briefcase";

export default function FrequentToolItem(props) {
    const { tool } = props;

    const handleToolClick = (toolId) => {
        fetchData(`/ToolsAndResources/PostToolClicked?id=${toolId}`, 'post', 'json')
    }

    return(
        <div className='tool'>
            <a className="wrapper" href={tool.url} target="_blank" rel="noreferrer" onClick={() => handleToolClick(tool.idTool)}>
                <div className="icon">
                    <BriefcaseIcon />
                </div>
                <div className="text">
                    <span className="title">{tool.name.toLowerCase()}</span>
                </div>
            </a>
        </div>
    )
}