import React from "react";
import { SkeletonLine } from "../../components/ui/skeleton";

export default function RecentFormsLoading(){
    return(
        Array(3).fill().map((_, i) => {
            return (
                <div className='form' key={i}>
                    <div className="wrapper">
                        <div className="icon">
                            <SkeletonLine width="61px" height="61px"/>
                        </div>
                        <div className="text">
                            <span className="title"><SkeletonLine width="80px" height="20px"/></span>
                            <span className="title"><SkeletonLine width="180px" height="20px"/></span>
                        </div>
                    </div>
                </div>
            )
        })
    )
}