import CookiesService from "./CookiesService";

export default function AdfsTokenService() {

    const service = new CookiesService();
    const jwtTokenKey = 'adfs_token';

    const get = () => {
        return service.get(jwtTokenKey);
    };

    return { get };
}