import React from 'react';
import { Route, Routes } from 'react-router-dom';
import ScreenSizeService from './services/screenSizeService';
import HomePage from "./home/homePage";
import SearchPage from "./search/searchPage";
import DashboardPage from "./dashboard/dashboardPage";
import RadissonRequestPage from "./radissonRequest/radissonRequestPage";
import HelpGuidesPage from "./helpGuides/helpGuidesPage";
import ToolsAndResourcesPage from "./toolsAndResources/toolsAndResourcesPage";
import Directory from "./directory/directoryPage";
import ConsentFormPage from "./consentForm/consentFormPage";
import Custom404 from './components/layout/404';
import ErrorPage from './components/layout/errorPage';

function AppRoutes(props) {
  const { permissions, userData } = props;
  const mobile = new ScreenSizeService().isMobile();
  const routes = [
    {
      index: true,
      path: "/",
      element: <HomePage userData={userData} permissions={permissions} />
    },
    {
      path: '/documents',
      element: <SearchPage />
    },
    {
      path: '/tools-and-resources',
      element: <ToolsAndResourcesPage />
    },
    {
      path: '/radisson-request',
      element: <RadissonRequestPage />
    },
    {
      path: '/dashboard',
      element: <DashboardPage />
    },
    {
      path: '/help-guides',
      element: <HelpGuidesPage />
    },
    permissions === 'corporate' && !mobile && {
      path: '/directory',
      element: <Directory />
    },
    {
      path: '/radisson-request/it-consent-form',
      element: <ConsentFormPage />
    },
    {
      path: '/error-login',
      element: <ErrorPage />
    },
    {
      path: '*',
      element: <Custom404 />
    }
  ];

  return (
    <Routes>
      {routes.map((route, index) => {
        const { element, ...rest } = route;
        return <Route key={index} {...rest} element={element} />;
      })}
    </Routes>
  );
} export default AppRoutes;
