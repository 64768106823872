export default function CookiesService() {

    const get = (name) => {
        try {
            var cookie = document.cookie.split(';').filter(c => c.trim().startsWith(name)).pop();
            var value = undefined !== cookie ? cookie.split('=').pop() : null;

            return value;
        }
        catch(err) {
            return null;
        }
    }

    return { get };
}