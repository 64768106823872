import fetchData from '../components/hooks/getData';
import LoggerService from '../services/LoggerService';
import CachedService from '../services/CachedService';

function Service() {
    const logger = new LoggerService('MenuToolsAndResourcesService');
    const permissions = sessionStorage.getItem('permissions');

    const get = async () => {
        logger.debug('Retrieving menuToolsAndResources list...');
        if(permissions === 'corporate')
            var data = await fetchData(`/ToolsAndResources/GetToolsAndResourcesNI`, 'get', 'json');
        else
            var data = await fetchData(`ToolsAndResources/hotel/GetToolsAndResourcesNI`, 'get', 'json');

        return data;
        
    };

    return {
        get
    };
}

export default function MenuToolsAndResourcesService(params) {
    return new CachedService({
        key: 'menuTools',
        service: new Service(params)
    });
};