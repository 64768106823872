import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import SearchIcon from '../../components/icons/search';

function SearchHome() {
    const [searchQuery, setSearchQuery] = useState('');
    const permissions = 'corporate'
    const input_label = 'How can we help you?';
    const input_placeholder = permissions === 'corporate' ? 'Search for files, people and sites' : 'Search people here';
    const navigate = useNavigate();

    const handleSubmit = (event) => {
        event.preventDefault();
        if(searchQuery && searchQuery.length >= 3) {
            navigate(`/documents?query=${searchQuery}`);
            setSearchQuery('');
        }
    };

    return (
        <div className='search_box'>
            <form autoComplete="off" onSubmit={(e) => handleSubmit(e)}>
                <div className='input_group'>
                    <label className='fluent_label'>
                        <span className='title'>{input_label}</span>
                    </label>
                    <input placeholder={input_placeholder} className='fluent_input' type="search" id="search_input" value={searchQuery} onChange={(e) => setSearchQuery(e.target.value)} />
                    <div className='input_icon' onClick={(e) => handleSubmit(e)}>
                        <div className='icon'>
                            <SearchIcon/>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    );
} export default SearchHome;