import React, { useEffect, useState } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { NavLink } from "react-router-dom";
import { useMsal } from "@azure/msal-react";
import DashboardProfile from './profile/profile';
import DashboardBenefits from './benefits/benefits';
import Password from './password/password';
import WarningIcon from '../components/icons/warning';
import '../styles/dashboard.scss';

export default function DashboardPage() {
    const [hasWorkdayId, setHasWorkdayId] = useState(false);
    const permissions = sessionStorage.getItem('permissions');
    const { instance } = useMsal();

    const wrapperStyle = 'content_wrapper hotel'

    
    function handleLogout() {
        const domain = window.location;
        const COOKIE_NAME = 'adfs_token'
    
        sessionStorage.clear();
        document.cookie = COOKIE_NAME +'=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
        
        instance.logoutRedirect({
            postLogoutRedirectUri: domain,
        });
    }
    
    const WarningCard = () => {
        return(
            <div className='dashboard_card'>
                <div className='warning_icon'>
                    <WarningIcon />
                </div>
                <span>
                    Dont forget to <strong onClick={() => handleLogout()} className='logout_link'>log out</strong> of your account to prevent unwanted access to your information.
                </span>
            </div>
        )
    }

    return (
        <HelmetProvider>
            <Helmet>
                <title>Radisson Connects | Dashboard</title>
            </Helmet>
            <div className='dashboard_warpper'>
                <div className='banner'>
                    <div className='filter_layer'></div>
                    <div className='content_layer'>
                        <div className='search_title'>
                            <div className='breadcrum'>
                                <span>
                                    <NavLink to="/" exact="true">Home</NavLink>
                                </span>
                                <span>|</span>
                                <span className='current'>My Dashboard</span>
                            </div>
                            <h1>My Dashboard</h1>
                        </div>
                    </div>
                </div>
                <div className={wrapperStyle}>
                            <WarningCard />
                            <DashboardProfile />
                            <Password />
                            <DashboardBenefits />
                </div>
            </div>      
        </HelmetProvider>
    );
};