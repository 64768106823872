export default function LoggerService(category) {

    const logger = (severity, category, msg) => {
        console.log(`[${severity}][${category}] ${msg}`);
    }

    const that = {
        debug: (msg) => {
            logger('DBUG', category, msg);
        },

        info: (msg) => {
            logger('INFO', category, msg);
        },
        
        trace: (msg) => {
            logger('TRCE', category, msg);
        },
        
        warn: (msg) => {
            logger('WARN', category, msg);
        },
    };

    return that;
}