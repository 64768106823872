import fetchData from '../components/hooks/getData';
import LoggerService from '../services/LoggerService';
export function ProfileService() {
    const logger = new LoggerService('ProfileService');
    const permissions = sessionStorage.getItem('permissions');

    const get = async () => {
        logger.debug('Retrieving workday profile info...');
        if (permissions === 'corporate')
            var data = await fetchData(`/Dashboard/GetUserDashboard`, 'get', 'json');
        else
            var data = await fetchData(`/Dashboard/hotel/GetUserDashboard`, 'get', 'json');

        const user = data;
        return user;
    };

    return {
        get
    };
};

export function PasswordService() {
    const logger = new LoggerService('PasswordService');
    const permissions = sessionStorage.getItem('permissions');

    const get = async () => {
        logger.debug('Retrieving workday password info...');
        if (permissions === 'corporate')
            var data = await fetchData(`/Dashboard/GetPassword`, 'get', 'json');
        else
            var data = await fetchData(`/Dashboard/hotel/GetPassword`, 'get', 'json');
        if (permissions === 'corporate')
            var config = await fetchData(`/config/list`, 'get', 'json');
        else
            var config = await fetchData(`config/hotel/list`, 'get', 'json');
        const resetLink = config.filter(i => i.key.toLowerCase() === 'reset password link').pop();

        const password = {
            resetLink: resetLink.value ? resetLink.value : '',
            enableResetLink: resetLink ? true : false,
            ...data
        }

        return password;
    };

    return {
        get
    };
};

export function BenefitsService() {
    const logger = new LoggerService('BenefitsService');
    const permissions = sessionStorage.getItem('permissions');

    const get = async () => {
        logger.debug('Retrieving benefits info...');
        if (permissions === 'corporate')
            var data = await fetchData(`/Dashboard/GetBenefits`, 'get', 'json');
        else
            var data = await fetchData(`/Dashboard/hotel/GetBenefits`, 'get', 'json');

        return data;
    };

    return {
        get
    };
};

export function BookingSASService() {
    const logger = new LoggerService('BookingSASService');
    const permissions = sessionStorage.getItem('permissions');

    const getCountry = async () => {
        logger.debug('Retrieving country info...');
        if (permissions === 'corporate') {
            var data = await fetchData(`/Dashboard/GetUserCountry`, 'get', 'json');
        } else {
            var data = await fetchData(`/Dashboard/hotel/GetUserCountry`, 'get', 'json');
        }

        return data;
    };

    const getLink = async (country) => {
        logger.debug('Retrieving BookingSAS link...');
        if (permissions === 'corporate') {
            var data = await fetchData(`/Dashboard/GetBookingSASLink?country=${country}`, 'get', 'json');
        } else {
            var data = await fetchData(`/Dashboard/hotel/GetBookingSASLink?country=${country}`, 'get', 'json');
        }

        return data;
    };

    return {
        getCountry, getLink
    };
};