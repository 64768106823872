import React from 'react';
import { usePagination, DOTS } from '../hooks/usePagination';
import ScreenSizeService from '../../services/screenSizeService';
import ArrowRightIcon from '../icons/arrowRight';
import ArrowLeftIcon from '../icons/arrowLeft';
import '../../styles/pagination.scss';

const Pagination = props => {
    const { onPageChange, totalCount, siblingCount = 1, currentPage, pageSize, moreResults } = props;
    const paginationRange = usePagination({currentPage, totalCount, siblingCount, pageSize });
    
    const mobile = new ScreenSizeService().isMobile();
    const tablet = new ScreenSizeService().isTablet();

    // dont render the componente if there is only one page //
    if (currentPage === 0 || paginationRange.length < 2) {
        return null;
    }

    const onNext = () => {
        onPageChange(currentPage + 1);
    };

    const onPrevious = () => {
        onPageChange(currentPage - 1);
    };

    let lastPage = paginationRange[paginationRange.length - 1];

    return (
        <div className={`pagination_warper`} >
            <button className={`button previus_button ${currentPage === 1 ? 'disabled' : ''}`}
                onClick={currentPage === 1 ? null : onPrevious}
            >
                <ArrowLeftIcon /> {(!mobile && !tablet) ? 'Previous' : ''}
            </button>
            <div className='pagination_numbers'>
                {paginationRange.map((pageNumber, index) => {
                    if (pageNumber === DOTS) {
                        return <button key={index} className="button dots">&#8230;</button>;
                    }
                    return (
                        <button key={index} className={`button number ${pageNumber === currentPage ? 'current' : ''}`} onClick={() => onPageChange(pageNumber)} >
                            {pageNumber}
                        </button>
                    );
                })}
            </div>
            <button className={`button next_button ${(currentPage === lastPage && moreResults === false) ? 'disabled' : ''}`}
                onClick={(currentPage === lastPage && moreResults === false) ? null : onNext}
            >
                {(!mobile && !tablet) ? 'Next' : ''} <ArrowRightIcon />
            </button>
        </div>
    );
};

export default Pagination;
