import { useEffect, useState } from "react"


export default function ScreenSizeService() {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleWindowResize = () => {
            setWindowWidth(window.innerWidth);
        };
    
        window.addEventListener('resize', handleWindowResize);
    
        return () => {
          window.removeEventListener('resize', handleWindowResize);
        };
    }, []);

    const isMdScreen = () => {
        return windowWidth < 1199 && windowWidth > 769;
    };

    const isMobile = () => {
        return windowWidth < 480;
    };

    const isTablet = () => {
        return windowWidth < 768 && windowWidth > 480;
    };

    return {
        isMdScreen, isMobile, isTablet
    };
}