import React, { useState, useRef, useEffect } from "react";
import TopStoriesService from '../topStoriesService';
import TopStoriesItem from "./topStoriesItem";
import TopStoriesLoading from "./topStoriesLoading";
import TopStoriesSlider from "./topStoriesSlider";
import TopStoriesControls from "./topStoriesControls";

function TopStories(props){
    const [activeIndex, setActiveIndex] = useState(0);
    const [stories, setStories] = useState(null);
    const [loading, setLoading] = useState(true);
    const carouselHeader = useRef(null);
    const carouselRef = useRef(null);
    const topStoriesService = new TopStoriesService();

    useEffect(() => {
        (async () => {
            const storiesLinks = await topStoriesService.get();
            setStories(storiesLinks);
            setLoading(false);
        })();
    }, []);

    useEffect(() => {
        if(carouselRef && carouselRef.current){
            TopStoriesSlider(carouselRef.current, setActiveIndex)
        }
    });

    return(
        <div className="section stories" style={props.style}>
            {stories && stories.length > 0 && !loading &&
                <div className="carousel">
                    <div className="main_title" ref={carouselHeader}>
                       <div className="title"><span className="storie_title">{stories[activeIndex].departmentName}</span></div>
                    </div>
                    <TopStoriesControls stories={stories} activeIndex={activeIndex} setActiveIndex={setActiveIndex} 
                        carouselRef={carouselRef} activeArrows={true}/>
                    <div className="slides">
                        <ul className="inner" id="carousel" ref={carouselRef}>
                            {stories.map((story, i) => {
                                return(
                                    <TopStoriesItem story={story} key={i} />
                                )
                            })}
                        </ul>
                    </div>
                </div>
            }
            {loading && <TopStoriesLoading />}
        </div>
    )
} export default TopStories;