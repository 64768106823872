import React from "react";
import ScreenSizeService from '../../services/screenSizeService';
import '../../styles/footer.scss';

const FooterBannerMobile = '/assets/banners/footer_banner_mobile.png';
const FooterBanner = '/assets/banners/footer_banner.png';

function Footer() {
    const mobile = new ScreenSizeService().isMobile();
    const tablet = new ScreenSizeService().isTablet();

    return(
        <footer>
            <div className="wrapper">
                <div className="footer">
                    <img src={mobile || tablet ? FooterBannerMobile : FooterBanner} alt="Footer Banner RGH"/>
                </div>
                <div className="copyright">
                    &copy;{new Date().getFullYear()} RHG. All Rights Reserved.
                </div>
            </div>
        </footer>
    );
};

export default Footer;