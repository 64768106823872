import React, { useState, useEffect } from 'react';
import InactivityPopup from './../layout/InactivityPopup'


function InactivityDetector() {
    const [timer, setTimer] = useState(0);
    const [showPopup, setShowPopup] = useState(false);
    const [listenerActive, setListenerActive] = useState(true);
    const [timerPaused, setTimerPaused] = useState(false);
    const [lastInactiveTime, setLastInactiveTime] = useState(null);
    const InactivityTime = 900; //Seconds

    useEffect(() => {
        const interval = setInterval(() => {
            if (!timerPaused) {
                setTimer((prevTimer) => prevTimer + 1);
                console.log(timer);
            }
        }, 1000);

        return () => {
            clearInterval(interval);
        };
    }, []);

    const resetTimer = () => {
        setTimer(0);
        setShowPopup(false);
        var body = document.querySelector('body');
        body.style.overflow = 'auto';
    };

    const handleActivity = () => {
        resetTimer();
        setTimerPaused(false);
    };

    const handleVisibilityChange = () => {
        if (document.visibilityState === 'hidden') {
            setLastInactiveTime(new Date());
            setTimerPaused(true);
        } else {
            const now = new Date();
            if (lastInactiveTime !== null) {
                const timeDiff = now - lastInactiveTime;
                const timeDiffInSeconds = timeDiff / 1000;
                if (timeDiffInSeconds + timer >= InactivityTime) {
                    setShowPopup(true);
                    setListenerActive(false);
                    var body = document.querySelector('body');
                    body.style.overflow = 'hidden';
                    setTimerPaused(true);
                } else {
                    setTimer(timeDiffInSeconds + timer);
                    setTimerPaused(false);
                }
            }
        }
    };

    useEffect(() => {
        if (listenerActive) {
            window.addEventListener('mousemove', handleActivity);
            window.addEventListener('click', handleActivity);
            window.addEventListener('scroll', handleActivity);
            document.addEventListener('visibilitychange', handleVisibilityChange);
        } else {
            window.removeEventListener('mousemove', handleActivity);
            window.removeEventListener('click', handleActivity);
            window.removeEventListener('scroll', handleActivity);
            document.addEventListener('visibilitychange', handleVisibilityChange);
        }

        return () => {
            window.removeEventListener('mousemove', handleActivity);
            window.removeEventListener('click', handleActivity);
            window.removeEventListener('scroll', handleActivity);
            document.addEventListener('visibilitychange', handleVisibilityChange);
        };
    }, [listenerActive]);

    useEffect(() => {
        if (timer >= InactivityTime && !showPopup) {
            setShowPopup(true);
            setListenerActive(false);
            var body = document.querySelector('body');
            body.style.overflow = 'hidden';
            setTimerPaused(true);
        }
    }, [timer]);

    const handleClosePopup = (isRefreshSessionClicked) => {
        setShowPopup(false);
        setListenerActive(true);
        setTimer(0);
        setTimerPaused(false);
    };

    return (
        <>
            {showPopup && <InactivityPopup onClose={handleClosePopup} />}
        </>
    );
}

export default InactivityDetector;
