import React from "react";
import { TooltipHost, DirectionalHint } from '@fluentui/react';
import { useId } from '@fluentui/react-hooks';
import InfoIcon from '../../components/icons/info';

export default function DirectoryListItem(props) {
    const { directory } = props;
    const infoToolTip = useId('infoToolTip');
    
    return (
        <div className="table_row">
            <div className="subfolder">
                <span className="title">{directory.subfolder}</span>
                <TooltipHost
                    content={
                        <div className="tooltip_content">
                            <span className="title">Document Owners:</span>
                            {directory.owners.map((owner, index) => {
                                return (
                                    <div className="owner" key={index}>
                                        <span className="name">{owner.email}</span>
                                    </div>
                                )
                            })}
                        </div>
                    }
                    id={infoToolTip}
                    calloutProps={{isBeakVisible: false, gapSpace: 10}}
                    className='fluent_tooltip low_padding'
                    directionalHint={DirectionalHint.rightCenter}
                >
                    <div className="info_icon">
                        <InfoIcon />
                    </div>
                </TooltipHost>
            </div>
            <div className="region">
                <span className="title">{directory.region}</span>
            </div>
            <div className="department">
                <span className="title">{directory.department}</span>
            </div>
            {directory.newSharepointLink ?
                <div className="link">
                    <a href={directory.newSharepointLink} target="_blank" rel="noreferrer">SharePoint Link</a>
                </div>
                :
                <div className="link">
                    <span className="title">NA</span>
                </div>
            }
            {directory.hotelKitLink ?
                <div className="hotel">
                    <a href={directory.hotelKitLink} target="_blank" rel="noreferrer">Hotelkit Link</a>
                </div>
                :
                <div className="hotel">
                    <span className="title">NA</span>
                </div>
            }
        </div>
    )

}