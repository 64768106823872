import React, { useEffect, useState } from "react";
import { RecentRequestService } from "../radissonRequestService";
import RecentFormsListItem from "./recentFormsListItem";
import RecentFormsLoading from "./recentFormsLoading";

function RecentForms() {
    const [recents, setRecents] = useState(null);
    const [loading, setLoading] = useState(true);
    
    useEffect(() => {
        const recentRequestService = new RecentRequestService();
        (async () => {
            const data = await recentRequestService.get();
            setRecents(data);
            setLoading(false);
        })();
    }, []);

    return (
        <div className='frecently_used_forms'>
            <div className='forms'>
                <div className='area_title'>
                    <span className='title'>Recently Used Forms</span>
                    <span className='sub_title'>Quick access to your last used requests.</span>
                </div>
                <div className='frequent_forms'>
                    {!loading && recents && recents.length > 0 && recents.map((form, index) => {
                        return (
                            <RecentFormsListItem form={form} key={index}/>
                        )
                    })}
                    {!loading && (!recents || recents.length === 0) && 
                        <div className='no_forms'>
                            <span className='title'>You have not used any form yet</span>
                            <span className='subtitle'>You will see your most frequently used forms here when you start using them.</span>
                        </div>
                    }{loading && <RecentFormsLoading />}
                </div>
            </div>
        </div>
    )
} export default RecentForms;