import React from "react";
import XLSX from "xlsx-js-style";

function Export(props) {
    const {filteredData, query} = props;

    const exportResults = filteredData && filteredData.map((item) => {
        return {
            "Employee ID": item.rezidorEmployeeId,
            "Name": `${item.middlename !== null ? item.middlename + ' ' + item.lastname : item.lastname}, ${item.firstname}`,
            "Email": item.email ? item.email : '',
            "Title": item.jobTitle ? item.jobTitle : '',
            "Department": item.department ? item.department : '',
            "Sub_Department": item.subDepartment ? item.subDepartment : '',
            "Role": item.role ? item.role : '',
            "Manager / Sponsor": item.manager_Sponsor ? item.manager_Sponsor : '',
            "Unit": item.unit ? item.unit : '',
            "Short Name": item.unitShortname ? item.unitShortname : '',
            "City": item.unitCity ? item.unitCity : '',
            "Country": item.unitCountry ? item.unitCountry : '',
        }
    });

    let objectMaxLength = []; 
    for (let i = 0; i < exportResults.length; i++) {
        let values = Object.values(exportResults[i]);
        let keys = Object.keys(exportResults[i]);
        for (let j = 0; j < values.length; j++) {
            if(values[j].length > keys[j].length){
                objectMaxLength.push(values[j].length + 5);
            }else{
                objectMaxLength.push(keys[j].length + 5);
            }
        }
    }

    const queryStyle = {
        alignment: {horizontal: "left", vertical: "center"}, 
        font: {bold: true, sz: 16, color: { rgb: "FFFFFF" }}, 
        fill: {
            patternType: "solid", 
            fgColor: { rgb: "C00D0D" }, 
            bgColor: { rgb: "C00D0D" }
        }
    };

    const headerStyle = {
        alignment: {horizontal: "left", vertical: "center"}, 
        font: {bold: true, sz: 14, color: { rgb: "FFFFFF" }}, 
        fill: {
            patternType: "solid", 
            fgColor: { rgb: "53565A" }, 
            bgColor: { rgb: "53565A" } 
        }
    };

    const rowBg = {
        patternType: "solid",
        fgColor: { rgb: "F4F4F4" },
        bgColor: { rgb: "F4F4F4" }
    }

    function jsonToExcel (){
        const wb = XLSX.utils.book_new();
        const merge = [{ s: { r: 0, c: 0 }, e: { r: 0, c: 11 }}];
        const widths = [
            {wch: objectMaxLength[0]}, {wch: objectMaxLength[1]}, {wch: objectMaxLength[2]}, {wch: objectMaxLength[3]}, {wch: objectMaxLength[4]}, 
            {wch: objectMaxLength[5]}, {wch: objectMaxLength[6]}, {wch: objectMaxLength[7]}, {wch: objectMaxLength[8]}, {wch: objectMaxLength[9]}, {wch: objectMaxLength[10]}
        ];
        const heights = [{hpx: 35}, {hpx: 25}];
        for (let i = 0; i < exportResults.length; i++) {
            heights.push({hpx: 22});
        }
        const rows = [
            [{v:`Query: ${query}`, t: "s", s: queryStyle}],
            [
                {v: "Employee ID", t: "s", s: headerStyle},
                {v: "Name", t: "s", s: headerStyle},
                {v: "Email", t: "s", s: headerStyle},
                {v: "Title", t: "s", s: headerStyle},
                {v: "Department", t: "s", s: headerStyle},
                {v: "Sub_Department", t: "s", s: headerStyle},
                {v: "Role", t: "s", s: headerStyle},
                {v: "Manager / Sponsor", t: "s", s: headerStyle},
                {v: "Unit", t: "s", s: headerStyle},
                {v: "Short Name", t: "s", s: headerStyle},
                {v: "City", t: "s", s: headerStyle},
                {v: "Country", t: "s", s: headerStyle},
            ],
            ...exportResults.map((item, i) => {
                return [
                    {v: item["Employee ID"], t: "s", s: {alignment: {horizontal: "left", vertical: "center"}, fill: i % 2 === 0 ? rowBg : null}},
                    {v: item["Name"], t: "s", s: {alignment: {horizontal: "left", vertical: "center"}, fill: i % 2 === 0 ? rowBg : null}},
                    {v: item["Email"], t: "s", s: {alignment: {horizontal: "left", vertical: "center"}, fill: i % 2 === 0 ? rowBg : null}},
                    {v: item["Title"], t: "s", s: {alignment: {horizontal: "left", vertical: "center"}, fill: i % 2 === 0 ? rowBg : null}},
                    {v: item["Department"], t: "s", s: {alignment: {horizontal: "left", vertical: "center"}, fill: i % 2 === 0 ? rowBg : null}},
                    {v: item["Sub_Department"], t: "s", s: {alignment: {horizontal: "left", vertical: "center"}, fill: i % 2 === 0 ? rowBg : null}},
                    {v: item["Role"], t: "s", s: {alignment: {horizontal: "left", vertical: "center"}, fill: i % 2 === 0 ? rowBg : null}},
                    {v: item["Manager / Sponsor"], t: "s", s: {alignment: {horizontal: "left", vertical: "center"}, fill: i % 2 === 0 ? rowBg : null}},
                    {v: item["Unit"], t: "s", s: {alignment: {horizontal: "left", vertical: "center"}, fill: i % 2 === 0 ? rowBg : null}},
                    {v: item["Short Name"], t: "s", s: {alignment: {horizontal: "left", vertical: "center"}, fill: i % 2 === 0 ? rowBg : null}},
                    {v: item["City"], t: "s", s: {alignment: {horizontal: "left", vertical: "center"}, fill: i % 2 === 0 ? rowBg : null}},
                    {v: item["Country"], t: "s", s: {alignment: {horizontal: "left", vertical: "center"}, fill: i % 2 === 0 ? rowBg : null}},
                ]
            })
        ]
        const ws = XLSX.utils.aoa_to_sheet(rows);
        ws["!merges"] = merge;
        ws['!cols'] = widths;
        ws['!rows'] = heights;
        XLSX.utils.book_append_sheet(wb, ws, "users");
        XLSX.writeFile(wb, "users.xlsx");
    }

    return (
        <div className="export_wrapper">
            <div className="title" onClick={() => jsonToExcel()}>
                <span>Download Results</span>
            </div>
        </div>
    );
} export default Export;