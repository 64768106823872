import React, {useEffect, useState} from 'react';
import ChevronDownIcon from '../../components/icons/chevronDown';
import SearchIcon from '../../components/icons/search';
import FilterIcon from '../../components/icons/filter';
import ScreenSizeService from '../../services/screenSizeService';

function Filters(props) {
    const [clickedIndex, setClickedIndex] = useState({});
    const [mobileFilters, setMobileFilters] = useState('');
    const [departmentFilters, setDepartmentFilters] = useState([]);
    const [subDepartmentFilters, setSubDepartmentFilters] = useState([]);
    const [jobTitleFilters, setJobTitleFilters] = useState([]);
    const [roleFilters, setRoleFilters] = useState([]);
    const [unitFilters, setUnitFilters] = useState([]);
    const [globalFilters, setGlobalFilters] = useState([]);
    const {results, filteredData, setFilterdData, setUserFilters, setPage} = props;
    const mobile = new ScreenSizeService().isMobile();
    const tablet = new ScreenSizeService().isTablet();
    var departments = [];
    var subDepartments = [];
    var jobTitles = [];
    var roles = [];
    var units = [];

    for(var i = 0; i < results.length; i++){
        if(results[i].department !== null && results[i].department !== ''){
            departments.indexOf(results[i].department) === -1 && departments.push(results[i].department);
        }
        if(results[i].jobTitle !== null && results[i].jobTitle !== ''){
            jobTitles.indexOf(results[i].jobTitle) === -1 && jobTitles.push(results[i].jobTitle);
        }
        if(results[i].role !== null && results[i].role !== ''){
            roles.indexOf(results[i].role) === -1 && roles.push(results[i].role);
        }
        if(results[i].unitName !== null && results[i].unitName !== ''){
            units.indexOf(results[i].unitName) === -1 && units.push(results[i].unitName);
        }
        if(results[i].subDepartment !== null && results[i].subDepartment !== ''){
            subDepartments.indexOf(results[i].subDepartment) === -1 && subDepartments.push(results[i].subDepartment);
        }
    }

    const optionFilters = [
        
        {label: 'Business Unit Name', options: units, id: 0},
        {label: 'Department', options: departments, id: 1},
        {label: 'Sub-department', options: subDepartments, id: 2},
        {label: 'Job Title', options: jobTitles, id: 3},
        {label: 'Role', options: roles, id: 4},
    ]

    const handleClick = (index) => () => {
        clickedIndex === (state => ({
            ...state,
            [index]: !state[index]
        })) ? setClickedIndex({}) : setClickedIndex(state => ({
            ...state,
            [index]: !state[index]
        }))
        let filterInputs = document.getElementsByClassName('filter_wrapper');
        for(var i = 0; i < filterInputs.length; i++){
            if(i !== index){
                filterInputs[i].classList.remove('open');
            }
        }
    };

    const handleInputChange = (e, index) => {
        e.preventDefault();
        var filter, ul, li, a, i, txtValue;
        filter = e.target.value.toUpperCase();
        ul = document.getElementById(index);
        li = ul.getElementsByClassName("item");
        for (i = 0; i < li.length; i++) {
            a = li[i].getElementsByTagName("span")[0];
            txtValue = a.textContent || a.innerText;
            if (txtValue.toUpperCase().indexOf(filter) > -1) {
                li[i].style.display = "";
            } else {
                li[i].style.display = "none";
            }
        }
    }

    const handleFilters = (e, id) => {
        if(id === 0){
            if(!unitFilters.includes(e.target.name)){
                setUnitFilters(state => [...state, e.target.name]);
            } else {
                setUnitFilters(state => state.filter(item => item !== e.target.name));
            }
        } else if(id === 1 ){
            if(!departmentFilters.includes(e.target.name)){
                setDepartmentFilters(state => [...state, e.target.name]);
            } else {
                setDepartmentFilters(state => state.filter(item => item !== e.target.name));
            }
        } else if(id === 2 ){
            if(!subDepartmentFilters.includes(e.target.name)){
                setSubDepartmentFilters(state => [...state, e.target.name]);
            } else {
                setSubDepartmentFilters(state => state.filter(item => item !== e.target.name));
            }
        } else if(id === 3){
            if(!jobTitleFilters.includes(e.target.name)){
                setJobTitleFilters(state => [...state, e.target.name]);
            } else {
                setJobTitleFilters(state => state.filter(item => item !== e.target.name));
            }
        } else if(id === 4){
            if(!roleFilters.includes(e.target.name)){
                setRoleFilters(state => [...state, e.target.name]);
            } else {
                setRoleFilters(state => state.filter(item => item !== e.target.name));
            }
        }
        if(!globalFilters.includes(e.target.name)){
            setGlobalFilters(state => [...state, e.target.name]);
        } else {
            setGlobalFilters(state => state.filter(item => item !== e.target.name));
        }
    }

    const clearAllType = (type) => {
        setPage(1);
        if(type === 0){
            setGlobalFilters(globalFilters.filter(item => !unitFilters.includes(item)));
            setUnitFilters([])
        } else if(type === 1){
            setGlobalFilters(globalFilters.filter(item => !departmentFilters.includes(item)));
            setDepartmentFilters([]);
        } else if(type === 2){
            setGlobalFilters(globalFilters.filter(item => !subDepartmentFilters.includes(item)));
            setSubDepartmentFilters([]);
        } else if(type === 3){
            setGlobalFilters(globalFilters.filter(item => !jobTitleFilters.includes(item)));
            setJobTitleFilters([])
        } else if(type === 4){
            setGlobalFilters(globalFilters.filter(item => !roleFilters.includes(item)));
            setRoleFilters([])
        }
    }

    const clearAllFilters = () => {
        setPage(1);
        setDepartmentFilters([]);
        setSubDepartmentFilters([]);
        setJobTitleFilters([]);
        setRoleFilters([]);
        setUnitFilters([]);
        setGlobalFilters([]);
        setFilterdData(results)
        setUserFilters(false);
        setMobileFilters('');
    }

    const applyFilters = () => {
        setPage(1);
        let filteredResults = results;

        if(departmentFilters.length > 0){
            filteredResults = filteredResults.filter(result => (departmentFilters.includes(result.department))).map(result => result)
        }
        if(jobTitleFilters.length > 0){
            filteredResults = filteredResults.filter(result => (jobTitleFilters.includes(result.jobTitle))).map(result => result)
        }
        if(roleFilters.length > 0){
            filteredResults = filteredResults.filter(result => (roleFilters.includes(result.role))).map(result => result)
        }
        if(unitFilters.length > 0){
            filteredResults = filteredResults.filter(result => (unitFilters.includes(result.unitName))).map(result => result)
        }
        if(subDepartmentFilters.length > 0){
            filteredResults = filteredResults.filter(result => (subDepartmentFilters.includes(result.subDepartment))).map(result => result)
        }
        setFilterdData(filteredResults);
        setMobileFilters('');
    }
    
    function handleOutsideClick(e) {
        const index0 = document.getElementById("0");
        const index1 = document.getElementById("1");
        const index2 = document.getElementById("2");
        const index3 = document.getElementById("3");
        const index4 = document.getElementById("4");
        const mobileFilters = document.getElementById("menu_mobile_filters");
        let target = e.target;     
        do {
          if(target === index0 || target === index1 || target === index2 || target === index3 || target === index4 || target === mobileFilters) {
            return;
          }
          target = target.parentNode;
        } while (target);
        setClickedIndex({});
        setMobileFilters('');
    }

    const openMobileFilters = () => {
        mobileFilters === 'open' ? setMobileFilters('') : setMobileFilters('open');
    }

    useEffect(() => {
        document.addEventListener("mousedown", (e) => {
            handleOutsideClick(e);
            return (document.removeEventListener("mousedown", handleOutsideClick));
        });
    }, []);

    useEffect(() => {
        if(globalFilters.length > 0){
            setUserFilters(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [globalFilters]);
    
    return(
        <>
            <div className='filters_toolbar'>
                <div className='wrapper'>
                    {(mobile || tablet) &&
                        <div className={`mobile_filters_button ${mobileFilters}`}>
                            <button onClick={() => openMobileFilters()}>Filter <FilterIcon /></button>
                        </div>
                    }
                    <div className={`filters ${mobileFilters}`} id="menu_mobile_filters">
                        {optionFilters.map((filter, index) => {
                            let selectedItem = 
                                globalFilters.filter(item => filter.options.includes(item)).length > 0 ? 
                                    globalFilters.filter(item => filter.options.includes(item)).map(f => f.replace(',','').trim()).join(', ') : filter.label;
                            return(
                                <div className={clickedIndex[index] ? 'filter_wrapper open' : 'filter_wrapper'} id={index} key={index}>
                                    <div className='filter' onClick={handleClick(index)}>
                                        <span className='label'>{selectedItem}</span>
                                        <div className='drop_down'><ChevronDownIcon /></div>
                                    </div>
                                    <div className='filter_menu'>
                                        <div className='search_input'>
                                            <input type='text' placeholder='Search' onKeyUp={(e) => handleInputChange(e, index)}/>
                                            <div className='icon'><SearchIcon /></div>
                                        </div>
                                        <div className='options_wrapper'>
                                            <span className='option' onClick={() => clearAllType(filter.id)}>Clear All</span>
                                        </div>
                                        <div className='list_wrapper' id={index}>
                                            {filter.options.map((option, index) => {
                                                return(
                                                    <div className='item' key={index}>
                                                        <div className='wrapper'>
                                                            <span className='title'>{option.replace(',','').trim()}</span>
                                                            <input 
                                                                type='checkbox' 
                                                                name={option} checked={globalFilters.some(f => f === option) ? true : false} 
                                                                onChange={(e) => handleFilters(e, filter.id)}
                                                            />
                                                            <span className="checkmark"></span>
                                                        </div>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                        {(mobile || tablet) &&
                            <div className='mobile_buttons'>
                                <div className='buttons_wrapper'>
                                <button 
                                    className={`btn_apply ${(globalFilters.length > 0 || results.length !== globalFilters.length) ? 'active' : ''}`} 
                                    onClick={(globalFilters.length > 0 || results.length !== globalFilters.length) ? () => applyFilters() : null}
                                >
                                    Apply
                                </button>
                                    <span className={`btn_clear ${globalFilters.length > 0 ? 'active' : ''}`} onClick={globalFilters.length > 0 ? () => clearAllFilters() : null}>
                                        Clear all
                                    </span>
                                </div>
                            </div>
                        }
                    </div>
                    <div className='buttons_wrapper'>
                        {(!mobile && !tablet) &&
                            <button 
                                className={`btn_apply ${(globalFilters.length > 0 || results.length !== filteredData.length) ? 'active' : ''}`} 
                                onClick={(globalFilters.length > 0 || results.length !== filteredData.length) ? () => applyFilters() : null}
                            >
                                Apply
                            </button>
                        }
                        <span className={`btn_clear ${globalFilters.length > 0 ? 'active' : ''}`} onClick={globalFilters.length > 0 ? () => clearAllFilters() : null}>
                            Clear all filters
                        </span>
                    </div>
                </div>
            </div>
            {(mobile || tablet) && globalFilters.length > 0 &&
                <div className='mobile_filters_description'>
                    <div className='wrapper'>
                        <span className='title'>Filters applied:</span>
                        <span className='description'>
                            {departmentFilters.length > 0 ?
                                departmentFilters.map(f => f).join(', ') + '; '
                                : 'Multiple Departments; '
                            }
                            {jobTitleFilters.length > 0 ?
                                jobTitleFilters.map(f => f).join(', ') + '; '
                                : 'Multiple Job Titles; '
                            }
                            {roleFilters.length > 0 ?
                                roleFilters.map(f => f).join(', ') + '; '
                                : 'Multiple Roles; '
                            }
                            {unitFilters.length > 0 ?
                                unitFilters.map(f => f).join(', ') + '; '
                                : 'Multiple Units; '
                            }
                        </span>
                    </div>
                </div>
            }
        </>
    );
}; export default Filters;